import {getEnvironment} from '@/environment';

const environment = getEnvironment();

appModule.baseUrl = '';
appModule.baseTemplateUrl = 'partials/';
appModule.baseApiUrl = environment.apiUrl;
appModule.customer = environment.tenant;

appModule.hubs = {
    types: {
        event: 'eventHub'
    },
    messages: {}
};

appModule.getTemplateUrl = function(urlPart) {
    return appModule.baseTemplateUrl + urlPart + '.html?v=' + ft.randomNumber;
};

appModule.config([
    '$compileProvider',
    '$stateProvider',
    '$sceDelegateProvider',
    '$translateProvider',
    'ftConfigurationProvider',
    function($compileProvider, $stateProvider, $sceDelegateProvider, $translateProvider, ftConfigurationProvider) {
        if (!ft.DEBUG) {
            $compileProvider.debugInfoEnabled(false);
            $compileProvider.commentDirectivesEnabled(false);
            $compileProvider.cssClassDirectivesEnabled(true);
        }

        // default settable stuff
        ftConfigurationProvider.setBaseApiUrl(appModule.baseApiUrl);
        ftConfigurationProvider.setTokenUrl('account/token');
        ftConfigurationProvider.setRefreshTokenUrl('account/token');
        ftConfigurationProvider.setCurrentUserUrl('account/validate');

        ftConfigurationProvider.setSplashViewUrl(appModule.getTemplateUrl('app/splash'));
        ftConfigurationProvider.setLoginViewUrl(appModule.getTemplateUrl('login/login'));

        // everything else that comes in handy
        ftConfigurationProvider.configure({
            appTitle: 'Kindplanner',
            passwordExpression: /(?=.*?[\W_]{1,}).{8,}/gi,
            connection: {
                checkOnload: false,
                checkUrl: appModule.baseApiUrl + 'configuration'
            },
            notifications: {
                //	url: appModule.baseNotificationsUrl + 'events/signalr',
                //	hubs: appModule.hubs.types
            }
        });

        $sceDelegateProvider.resourceUrlWhitelist([
            // Allow same origin resource loads.
            'self',
            // Allow loading from our assets domain.  Notice the difference between * and **.
            'http://*.local/**',
            'http://ft.components.local/**',
            'http://ft.layout.local/**',
            'https://*.local/**',
            'http://*.42windmills.com/**',
            'https://*.42windmills.com/**',
            'https://*.kindplanner.nl/**',
            'https://*.azurewebsites.net/**',
            'https://www.google.com/**',
            'https://localhost:6001/**'
        ]);

        $translateProvider
            .useStaticFilesLoader({
                prefix: '/translations/local-',
                suffix: '.json'
            })
            .useSanitizeValueStrategy(null)
            .preferredLanguage('nl');

        const initializeApp = {
            name: 'app.initialize',
            url: '/initialize',
            views: {
                content: {
                    controller: 'kpAppInitializeController',
                    templateUrl: appModule.getTemplateUrl('app/initialize')
                }
            }
        };

        const externalLogin = {
            name: 'oauthLogin',
            url: '/oauth/login?api&token&group',
            controller: 'kpExternalLoginController'
        };

        const home = {
            name: 'app.home',
            url: '/home',
            views: {
                content: {
                    controller: 'kpHomeController',
                    templateUrl: appModule.getTemplateUrl('home/main')
                }
            }
        };

        const groupList = {
            name: 'app.groups',
            url: '/group/list?action',
            params: { previous: null },
            pageStyle: 'small-top-bar',
            layout: layoutSettings.layout.fullWidth,
            views: {
                content: {
                    controller: 'kpGroupListController',
                    templateUrl: appModule.getTemplateUrl('group/list')
                }
            }
        };

        const groupMedia = {
            name: 'app.groupmedia',
            url: '/group/media/:albumId?postId',
            pageStyle: 'small-top-bar group-app',
            layout: layoutSettings.layout.fullWidth,
            views: {
                content: {
                    controller: 'kpGroupMediaController',
                    templateUrl: appModule.getTemplateUrl('group/media')
                }
            }
        };

        const activities = {
            name: 'app.activities',
            url: '/activities',
            pageStyle: 'small-top-bar group-app',
            views: {
                content: {
                    controller: 'kpActivitiesController',
                    templateUrl: appModule.getTemplateUrl('activity/list')
                }
            }
        };

        const childDetail = {
            name: 'app.child',
            url: '/child/:id',
            pageStyle: 'small-top-bar group-app',
            layout: layoutSettings.layout.fullWidth,
            views: {
                content: {
                    controller: 'kpChildDetailController',
                    templateUrl: appModule.getTemplateUrl('child/detail')
                }
            }
        };

        const presenceOverview = {
            name: 'app.presence',
            url: '/presence?date',
            pageStyle: 'small-top-bar group-app',
            layout: layoutSettings.layout.fullWidth,
            reloadOnSearch: false,
            views: {
                content: {
                    controller: 'kpPresenceOverviewController',
                    templateUrl: appModule.getTemplateUrl('presence/overview')
                }
            }
        };

        const diaryOverview = {
            name: 'app.diary',
            url: '/diary?date',
            pageStyle: 'small-top-bar group-app',
            layout: layoutSettings.layout.fullWidth,
            reloadOnSearch: false,
            views: {
                content: {
                    controller: 'kpDiaryOverviewController',
                    templateUrl: appModule.getTemplateUrl('diary/overview')
                }
            }
        };

        const calendarOverview = {
            name: 'app.calendar',
            url: '/calendar?date',
            pageStyle: 'small-top-bar group-app',
            layout: layoutSettings.layout.fullWidth,
            reloadOnSearch: false,
            views: {
                content: {
                    controller: 'kpCalendarOverviewController',
                    templateUrl: appModule.getTemplateUrl('calendar/overview')
                }
            }
        };

        const requestOverview = {
            name: 'app.requests',
            url: '/requests',
            pageStyle: 'small-top-bar group-app',
            layout: layoutSettings.layout.fullWidth,
            reloadOnSearch: false,
            views: {
                content: {
                    controller: 'kpRequestsController',
                    templateUrl: appModule.getTemplateUrl('requests/overview')
                }
            }
        };

        const emergencyList = {
            name: 'app.emergency',
            url: '/emergency-list',
            pageStyle: 'small-top-bar group-app',
            layout: layoutSettings.layout.fullWidth,
            reloadOnSearch: false,
            views: {
                content: {
                    controller: 'kpEmergencyListController',
                    templateUrl: appModule.getTemplateUrl('emergency/list')
                }
            }
        };

        const travelPlanner = {
            name: 'app.planner',
            url: '/travel-planner?date',
            pageStyle: 'small-top-bar group-app',
            layout: layoutSettings.layout.fullWidth,
            reloadOnSearch: false,
            views: {
                content: {
                    controller: 'kpTravelPlannerController',
                    templateUrl: appModule.getTemplateUrl('travel/planner')
                }
            }
        };

        const activityPlanner = {
            name: 'app.activity',
            url: '/activity-planner?date',
            pageStyle: 'small-top-bar group-app',
            layout: layoutSettings.layout.fullWidth,
            reloadOnSearch: false,
            views: {
                content: {
                    controller: 'kpActivityPlannerController',
                    templateUrl: appModule.getTemplateUrl('activity/planner')
                }
            }
        };

        const locationManager = {
            name: 'app.locationmanager',
            url: '/locationmanager',
            pageStyle: 'small-top-bar group-app',
            layout: layoutSettings.layout.fullWidth,
            reloadOnSearch: false,
            views: {
                'content': {
                    templateUrl: appModule.getTemplateUrl('locationmanager/main')
                }
            }
        };

        const stories = {
            name: 'app.stories',
            url: '/stories',
            pageStyle: 'small-top-bar group-app',
            layout: layoutSettings.layout.fullWidth,
            reloadOnSearch: false,
            views: {
                content: {
                    controller: 'StoriesListController',
                    templateUrl: appModule.getTemplateUrl('stories/list')
                }
            }
        };

        const storyDetails = {
            name: 'app.story-details',
            url: '/story/:id',
            pageStyle: 'small-top-bar group-app',
            layout: layoutSettings.layout.fullWidth,
            reloadOnSearch: false,
            views: {
                content: {
                    controller: 'StoryDetailsController',
                    templateUrl: appModule.getTemplateUrl('stories/details')
                }
            }
        };

        $stateProvider.state(initializeApp);
        $stateProvider.state(externalLogin);
        $stateProvider.state(home);
        $stateProvider.state(groupList);
        $stateProvider.state(groupMedia);
        $stateProvider.state(activities);
        $stateProvider.state(childDetail);
        $stateProvider.state(presenceOverview);
        $stateProvider.state(calendarOverview);
        $stateProvider.state(requestOverview);
        $stateProvider.state(diaryOverview);
        $stateProvider.state(emergencyList);
        $stateProvider.state(travelPlanner);
        $stateProvider.state(activityPlanner);
        $stateProvider.state(locationManager);
        $stateProvider.state(stories);
        $stateProvider.state(storyDetails);
    }
]);

appModule.run();

window.appEvents = app.events;
window.appSettings = app.settings;
window.appTypes = app.types;
window.appHubTypes = appModule.hubs.types;
window.appHubMessages = appModule.hubs.messages;
