export class Form {
    static formNullCheck(form, skipProperties) {
        if (!skipProperties || !Array.isArray(skipProperties)) {
            skipProperties = [];
        }

        for (let prop in form) {
            if (!form.hasOwnProperty(prop)) {
                continue;
            }

            if ((form[prop] == null || form[prop] === '' || form[prop].length === 0) && !(skipProperties.indexOf(prop) > -1)) {
                return false;
            }
        }

        return true;
    }
}

export class Color {
    static hexToRgba(hex, transparency = 1) {
        hex = hex.replace(/[^0-9A-F]/gi, '');

        let bigint = parseInt(hex, 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;

        return 'rgb(' + r + ',' + g + ',' + b + ', ' + transparency + ')';
    }

    static contrast(rgb) {
        let R, G, B, C, L;
        rgb = rgb
            .substring(rgb.indexOf('(') + 1, rgb.length - 1)
            .replace(/ /g, '')
            .split(',');

        R = parseInt(rgb[0]);
        G = parseInt(rgb[1]);
        B = parseInt(rgb[2]);

        C = [R / 255, G / 255, B / 255];
        for (let i = 0; i < C.length; ++i) {
            if (C[i] <= 0.03928) {
                C[i] = C[i] / 12.92;
            } else {
                C[i] = Math.pow((C[i] + 0.055) / 1.055, 2.4);
            }
        }

        L = 0.2126 * C[0] + 0.7152 * C[1] + 0.0722 * C[2];
        return L > 0.25 ? 'black' : 'white';
    }
}
