import swal from 'bootstrap-sweetalert';
import { appModule } from '@/definitions';
import { IScope, IController } from 'angular';
import Dropzone, { DropzoneFile } from 'dropzone';
import { IFortyTwoDropzoneRootScopeService } from '@/directives/dropzone';
import { AppDataService } from '@/services/app-data-service';
import { ChildCare } from '@/data-models';
import { IModalInstanceService } from 'angular-ui-bootstrap';

type WriteLocation = ChildCare.Definitions.GroupApp.WriteLocation;
type WriteLocationCareType = ChildCare.Definitions.GroupApp.WriteLocationCareType;

interface Scope extends IScope {
    validation: { [field: string]: string };
    isLoading: boolean;
    locations: WriteLocation[];
    caretypes: WriteLocationCareType[];
    albumName: string;
    albumDescription: string;
    dropzoneConfig: {
        options: Dropzone.DropzoneOptions;
        eventHandlers: any;
    };

    uploadMedia: () => void;
    cancel: () => void;
    canUpload: () => boolean;

    isAllSelected: () => boolean;
    toggleAll: () => void;

    isLocationSelected: (location: WriteLocation) => boolean;
    toggleLocation: (location: WriteLocation) => void;

    isCareTypeSelected: (careType: WriteLocationCareType) => boolean;
    toggleCareType: (careType: WriteLocationCareType) => void;
}

class LocationManagerAlbumsModalController implements IController {
    public static readonly $inject = ['$rootScope', '$scope', '$uibModalInstance', 'locations', 'caretypes', 'AppDataService', '$translate', '$localStorage'];

    private readonly $rootScope: IFortyTwoDropzoneRootScopeService;
    private readonly $scope: Scope;
    private readonly $uibModalInstance: IModalInstanceService;
    private readonly dataService: AppDataService;
    private readonly $translate: any;
    private readonly $localStorage: any;

    private readonly selectedLocations = new Array<WriteLocation>();
    private readonly selectedCareTypes = new Array<WriteLocationCareType>();
    private uploadSucceeded: boolean;

    constructor($rootScope: IFortyTwoDropzoneRootScopeService, $scope: Scope, $uibModalInstance: IModalInstanceService, locations: ChildCare.Definitions.GroupApp.WriteLocation[], caretypes: ChildCare.Definitions.GroupApp.WriteLocationCareType[], dataService: AppDataService, $translate, $localStorage) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.dataService = dataService;
        this.$translate = $translate;
        this.$localStorage = $localStorage;

        this.$scope.locations = locations;
        this.$scope.caretypes = caretypes;

        this.selectedLocations.push(...locations);
        this.selectedCareTypes.push(...caretypes);

        this.queuecomplete = this.queuecomplete.bind(this);
        this.error = this.error.bind(this);

        this.$scope.cancel = this.cancel.bind(this);
        this.$scope.canUpload = this.canUpload.bind(this);

        this.$scope.isAllSelected = this.isAllSelected.bind(this);
        this.$scope.toggleAll = this.toggleAll.bind(this);
        this.$scope.isLocationSelected = this.isLocationSelected.bind(this);
        this.$scope.toggleLocation = this.toggleLocation.bind(this);
        this.$scope.isCareTypeSelected = this.isCareTypeSelected.bind(this);
        this.$scope.toggleCareType = this.toggleCareType.bind(this);

        this.$scope.uploadMedia = this.uploadMedia.bind(this);

        this.$scope.validation = {};

        this.$scope.dropzoneConfig = this.createDropzoneConfig();
    }

    private createDropzoneConfig(): Scope['dropzoneConfig'] {
        return {
            options: {
                url: (files: ReadonlyArray<DropzoneFile>) => {
                    const mimeType = files[0].type;
                    if (mimeType.startsWith('video')) {
                        return this.$rootScope.configuration.services.media + '/videos';
                    } else {
                        return this.$rootScope.configuration.services.media + '/images';
                    }
                },
                parallelUploads: 4,
                timeout: 1000 * 60 * 20,
                acceptedFiles: 'image/*,video/mp4,video/quicktime,video/x-msvideo,video/x-ms-wmv',
                addRemoveLinks: true,
                autoProcessQueue: false,
                headers: {
                    'Authorization': `Bearer ${this.$localStorage.refreshToken}`
                }
            },
            eventHandlers: {
                queuecomplete: this.queuecomplete,
                success: () => {
                    if (this.$rootScope.dropzone.getQueuedFiles().length > 0) {
                        this.$rootScope.dropzone.processQueue();
                    }
                    this.uploadSucceeded = this.$rootScope.dropzone.getQueuedFiles().length === 0;
                },
                error: this.error,
                addedfile: () => this.$scope.$apply(),
                addedfiles: () => this.$scope.$apply(),
                removedfile: () => this.$scope.$apply()
            }
        };
    }

    private isAllSelected(): boolean {
        return this.$scope.locations.every(location => this.selectedLocations.includes(location));
    }

    private toggleAll(): void {
        const nextState = !this.isAllSelected();

        this.selectedLocations.length = 0;
        if (nextState) {
            this.selectedLocations.push(...this.$scope.locations);
        }
    }

    private isLocationSelected(location: WriteLocation): boolean {
        return this.selectedLocations.includes(location);
    }

    private toggleLocation(location: WriteLocation): void {
        const index = this.selectedLocations.indexOf(location);
        if (index >= 0) {
            this.selectedLocations.splice(index, 1);
        } else {
            this.selectedLocations.push(location);
        }
    }

    private isCareTypeSelected(careType: WriteLocationCareType): boolean {
        return this.selectedCareTypes.includes(careType);
    }

    private toggleCareType(careType: WriteLocationCareType): void {
        const index = this.selectedCareTypes.indexOf(careType);
        if (index >= 0) {
            this.selectedCareTypes.splice(index, 1);
        } else {
            this.selectedCareTypes.push(careType);
        }
    }

    private queuecomplete(): void {
        if (this.uploadSucceeded) {
            this.$uibModalInstance.dismiss();

            swal({
                title: 'Opgeslagen!',
                text: 'Alle media is succesvol opgeslagen!',
                timer: 2000,
                type: 'success'
            });
        }
    }

    private error(): void {
        this.$uibModalInstance.dismiss();

        swal({
            title: this.$translate.instant('ERRORS.SAVE-FAILED.TITLE'),
            text: this.$translate.instant('MEDIA.ERROR.UPLOAD-FAILED'),
            timer: 2500,
            type: 'error'
        });
    }

    private showNoAudiences(): void {
        swal({
            title: this.$translate.instant('ERRORS.SAVE-FAILED.TITLE'),
            text: this.$translate.instant('MEDIA.ERROR.NO-AUDIENCES'),
            timer: 2500,
            type: 'error'
        });
    }

    private canUpload(): boolean {
        if (this.$scope.isLoading) {
            return false;
        }

        return true;
    }

    private validate(): boolean {
        this.$scope.validation = {};

        if (this.$rootScope.dropzone.files.length === 0) {
            this.$scope.validation['filesSelection'] = 'MEDIA.ERROR.NO-FILES-SELECTED';
        }

        if (this.selectedCareTypes.length === 0) {
            this.$scope.validation['careTypesSelection'] = 'MEDIA.ERROR.NO-CARETYPES-SELECTED';
        }

        if (this.selectedLocations.length === 0) {
            this.$scope.validation['locationsSelection'] = 'MEDIA.ERROR.NO-LOCATIONS-SELECTED';
        }

        if (this.$scope.albumName == null || this.$scope.albumName.trim() === '') {
            this.$scope.validation['albumName'] = 'MEDIA.ERROR.ALBUM-NAME-EMPTY';
        }

        return Object.keys(this.$scope.validation).length === 0;
    }

    private async uploadMedia(): Promise<void> {
        if (!this.validate() || !this.canUpload()) {
            return;
        }

        this.$scope.isLoading = true;

        const response = await this.dataService.getAudiences(this.selectedLocations, this.selectedCareTypes);
        if (response.audiences.length === 0) {
            this.showNoAudiences();
            this.$scope.isLoading = false;
            return;
        }

        const result = await this.dataService.addAlbumAsync(this.$scope.albumName, this.$scope.albumDescription);

        (this.$rootScope.dropzone as any).options.params = {
            audiences: response.audiences.map(x => x.id),
            albumId: result.id
        };

        this.$rootScope.dropzone.processQueue();
    }

    private cancel() {
        this.$uibModalInstance.dismiss();
    }
}

appModule.controller('kpLocationManagerAlbumsModalController', LocationManagerAlbumsModalController);
