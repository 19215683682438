import { Form } from '@/HelperClasses';

appModule.controller('kpActionMoveModalController',
    ['$scope', '$uibModalInstance', 'AppDataService', 'childId', 'LoadingBar', '$timeout', 'ftSettingService',
        function ($scope, $uibModalInstance, DataService, childId, LoadingBar, $timeout, ftSettingService) {
            var initialized = false;
            $scope.isLoading = false;
            $scope.groups = [];
            $scope.availableTimes = [];
            $scope.availableTimesErrorMsg = '';

        $scope.moment = moment;

        $scope.firstForm = {
            date: null,
            groupId: null,
            valid: null
        };

        $scope.secondForm = {
            times: null,
            valid: null
        };

        $scope.$watch('isLoading', function(newVal, oldVal) {
            LoadingBar.show = newVal;
        });

        var initialize = function() {
            if (initialized) {
                return;
            }

            $scope.isLoading = true;
            DataService.getGroupsAsync()
                .then(function(res) {
                    $scope.groups = res.groups;
                    $scope.firstForm.groupId = ftSettingService.get(appSettings.group.selected);
                })
                .finally(function() {
                    $scope.firstForm.date = moment().toDate();
                    $scope.isLoading = false;
                });
        };

        $scope.close = function() {
            $uibModalInstance.dismiss();
        };

        $scope.previous = function() {
            $scope.firstForm.valid = null;
            $scope.secondForm.valid = null;
        };

        $scope.currentGroup = function() {
            return $scope.groups.find(x => x.id == $scope.firstForm.groupId);
        };

        $scope.save = function() {
            if ($scope.firstForm.valid) {
                $scope.secondForm.valid = Form.formNullCheck($scope.secondForm, ['valid']);
            }

            $scope.firstForm.valid = Form.formNullCheck($scope.firstForm, ['valid']);

            if ($scope.firstForm.valid && !$scope.secondForm.valid) {
                DataService.getPossibleTransferGroups(childId, moment($scope.firstForm.date).format('YYYY-MM-DD')).then(
                    function(result) {
                        $scope.availableTimes = result.times;
                        if (result.errorMsg) {
                            $scope.availableTimesErrorMsg = result.errorMsg;
                        }
                        else {
                            $scope.availableTimesErrorMsg = '';
                        }
                    }
                );
            } else if ($scope.firstForm.valid && $scope.secondForm.valid) {
                $scope.isLoading = true;
                DataService.requestTransferGroup(
                    childId,
                    moment($scope.firstForm.date).format('YYYY-MM-DD'),
                    $scope.firstForm.groupId,
                    $scope.secondForm.times
                )
                    .then(function(result) {
                        $uibModalInstance.dismiss();
                    })
                    .finally(function() {
                        $scope.isLoading = false;
                    });
            }
        };

        $timeout(initialize());
    }
]);
