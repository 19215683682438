window.frameworkModule = ft.framework.module;
window.frameworkEvents = frameworkModule.events;
window.frameworkSettings = frameworkModule.settings;
window.frameworkSortingDirections = frameworkSettings.sorting.directions;
window.frameworkLevels = frameworkModule.levels;
window.frameworkNavigationType = frameworkModule.navigation.type;

window.componentsModule = ft.components.module;
window.componentsModels = componentsModule.models;
window.componentsEvents = componentsModule.events;

window.layoutModule = ft.layout.module;
window.layoutModels = layoutModule.models;
window.layoutEvents = layoutModule.events;
window.layoutSettings = layoutModule.settings;
window.layoutBladeSizes = layoutSettings.blade.size;

window.app.settings = {
    group: {
        selected: 'group-selected',
        dashboard: 'group-dashboard'
    },
    conversations: {
        fetchRange: 10
    },
    mediaAlbums: {
        fetchRange: 6
    }
};

window.app.types = {
    media: {
        video: 'video',
        image: 'image'
    },
    conversation: {
        group: 'conversation-group',
        parent: 'conversation-parent',
        list: 'conversation-list',
        new: 'conversation-new'
    }
};

window.app.navigation = {
    back: 'navigation-back'
};
