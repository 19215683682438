import { appModule, app, ft } from '@/definitions';
import { AppDataService } from '@/services/app-data-service';
import { IScope, ITimeoutService, IQService, IIntervalService, IPromise } from 'angular';
import ng from 'angular';
import moment from 'moment';
import { ChildCare } from '@/data-models';

interface ReadablePostsResponseItem extends ChildCare.Definitions.GroupApp.PostsResponseItem {
    read?: boolean;
}

interface Scope extends IScope {
    sendMessage: (event: { message: string }, message: string) => void;
    message: string;
    backToListView: () => void;
    showMoreMessages: () => void;
    filters: { internalOnly: boolean };
    getReplyedAs: (comment: ChildCare.Definitions.GroupApp.PostComment) => ChildCare.Definitions.GroupApp.PostComment;
    openNewConversationModal: () => void;
    answerComment: (comment: ChildCare.Definitions.GroupApp.PostComment) => void;
    replyComment: ChildCare.Definitions.GroupApp.PostComment;
    messageListView: boolean;
    loadPost: (selectedConversation: ChildCare.Definitions.GroupApp.PostResponse | ChildCare.Definitions.GroupApp.PostsResponseItem) => void;
    sendNotification: boolean;
    audiences: ChildCare.Definitions.GroupApp.WriteAudienceGroup[];
    selectedConversation: ChildCare.Definitions.GroupApp.PostResponse | ChildCare.Definitions.GroupApp.PostsResponseItem;
    messageLimit: number;
    fetchingMessages: boolean;
    isLoading: boolean;
    conversations: ChildCare.Definitions.GroupApp.PostsResponseItem[];
}

function isPostResponse(item: ChildCare.Definitions.GroupApp.PostResponse | ChildCare.Definitions.GroupApp.PostsResponseItem): item is ChildCare.Definitions.GroupApp.PostResponse {
    return (item as ChildCare.Definitions.GroupApp.PostResponse).comments != null;
}

appModule.controller('kpMessagesController',
    ['$rootScope', '$scope',  '$timeout', '$q', 'AppDataService', '$uibModal', '$stateParams', '$interval', 'LoadingBar',
        function ($rootScope, $scope: Scope, $timeout: ITimeoutService, $q: IQService, DataService: AppDataService, $uibModal, $stateParams, $interval: IIntervalService, LoadingBar) {
            var initialized = false;
            var lastFetchDate = null;
            var childId = $stateParams.id;
            var additionalReminderCount = 0;
            var allConversations = [];

            $scope.fetchingMessages = false;
            $scope.isLoading = false;
            $scope.conversations = [];
            $scope.messageLimit = app.settings.conversations.fetchRange;
            $scope.messageListView = true;
            $scope.selectedConversation = null;
            $scope.replyComment = null;
            $scope.audiences = null;

            $scope.getReplyedAs = (comment: ChildCare.Definitions.GroupApp.PostComment) => {
                if (isPostResponse($scope.selectedConversation)) {
                    return $scope.selectedConversation.comments.find(x => x.commentId == comment.replyToCommentId);
                }
            };

            $scope.filters = {
                internalOnly: false
            };

            $scope.message = '';

            $scope.$watch('isLoading', function (newVal, oldVal) {
                LoadingBar.show = newVal;
            });

            var initialize = function () {
                if (initialized)
                    {return;}

                $rootScope.$on('newPostMade', function (event, sendNotification) {
                    $scope.sendNotification = sendNotification;
                    refreshConversations();
                });

                var newPostInterval = $interval(function () {
                    $rootScope.$broadcast('newPostMade', true);
                }, 1000 * 30);


                $scope.$on('$destroy', function () {
                    $interval.cancel(newPostInterval);

                    $rootScope.$$listeners['newPostMade'] = [];
                });

                getData().then(() => {
                    if ($stateParams.postId != null) {
                        $scope.selectedConversation = $scope.conversations.find(x => x.postId === $stateParams.postId);
                        if ($scope.selectedConversation != null) {
                            $scope.loadPost($scope.selectedConversation);
                        }
                    }
                });

                initialized = true;
            };

            var applyFilters = function () {
                if ($scope.conversations.length === 0 && allConversations.length === 0)
                    {return;}

                if ($scope.filters.internalOnly === false) {
                    if (allConversations.length === 0)
                        {allConversations = ng.copy($scope.conversations);}

                    $scope.conversations = ng.copy(allConversations);
                    return;
                }

                allConversations = ng.copy($scope.conversations);
                $scope.conversations = allConversations.filter(x => x.isInternal == $scope.filters.internalOnly);
            };

            $scope.$watch('filters.internalOnly', applyFilters, true);

            var refreshConversations = function () {
                ft.log('Im refreshing the posts...');

                $scope.isLoading = true;
                getMessagesAsync(false).then(handleGetMessages).finally(function () { $scope.isLoading = false; });
            };

            $rootScope.getAvatarInitials = function (fullName) {
                var matches = fullName.match(/\b(\w)/g);
                if (matches.length < 2) {
                    return fullName.slice(0, 2).toUpperCase();
                }

                matches = matches.slice(0, 2);
                return matches.join('').toUpperCase();
            };

            $scope.showMoreMessages = function () {
                // Don't do the call if it's already fetching, or there could not possibly be a new message because it didn't
                // fetch the settings amount of items from the last call
                if ($scope.fetchingMessages || $scope.messageLimit >= ($scope.conversations.length + app.settings.conversations.fetchRange + additionalReminderCount))
                    {return;}

                $scope.isLoading = true;
                $scope.fetchingMessages = true;

                getMessagesAsync(true)
                    .then(addConversationsToList)
                    .finally(() => {
                        $scope.messageLimit += app.settings.conversations.fetchRange;
                        $scope.isLoading = false;
                        $scope.fetchingMessages = false;
                    });
            };

            var addConversationsToList = function (results) {
                for (const conversation of results.posts) {
                    $scope.conversations.push(conversation);
                }
            };

            var getData = function (): IPromise<void> {
                $scope.isLoading = true;

                var prms = [
                    DataService.getAudiencesAsync(childId),
                    getMessagesAsync(false)
                ];

                if (childId) {
                    prms.push(DataService.getReminderFor(childId, moment().utc().format('YYYY-MM-DD')));
                }

                return $q.all(prms).then(function (results) {
                    handleGetAudiences(results[0] as ChildCare.Definitions.GroupApp.WriteAudienceResponse);
                    handleGetMessages(results[1] as ChildCare.Definitions.GroupApp.PostsResponse);

                    if (results[2]) {
                        handleGetReminders((results[2] as ChildCare.Definitions.GroupApp.PostsResponse).posts);
                    }
                }).finally(function () {
                    $scope.isLoading = false;
                });
            };

            var getMessagesAsync = function (loadMore: boolean) {
                var dateTime = moment().utc().format('YYYY-MM-DDTHH-mm-ss');
                if (!lastFetchDate) {
                    lastFetchDate = dateTime;
                    return DataService.getMessagesAsync(lastFetchDate, childId);
                }

                if (loadMore) {
                    var lastConvo = $scope.conversations[$scope.conversations.length - 1];
                    return DataService.getMessagesAsync(lastConvo.lastUpdatedUTC, childId);
                }

                var returnValue = DataService.getMessagesSinceAsync(lastFetchDate, childId);
                lastFetchDate = dateTime;
                return returnValue;
            };

            var handleGetReminders = function (posts: ChildCare.Definitions.GroupApp.PostsResponseItem[]) {
                posts.forEach(function (post) {
                    var existingPost = $scope.conversations.find(x => x.postId == post.postId);

                    if (existingPost)
                        {$scope.conversations.splice($scope.conversations.indexOf(existingPost), 1);}

                    $scope.conversations.push(post);
                });

                additionalReminderCount = $scope.conversations.length - app.settings.conversations.fetchRange;
            };

            var handleGetMessages = function (results: ChildCare.Definitions.GroupApp.PostsResponse) {
                if (!results.posts) {
                    results = results[0];
                }

                const posts = results.posts as ReadablePostsResponseItem[];
                if (posts.length === 0) {
                    return;
                }

                if ($scope.conversations.length === 0) {
                    $scope.conversations = posts;
                } else {
                    if ($scope.selectedConversation) {
                        $scope.sendNotification = false;
                        $scope.loadPost($scope.selectedConversation);
                    }

                    // Send a notification that there are new messages
                    if ($scope.sendNotification) {
                        $rootScope.$broadcast(
                            'ft-notifications-add',
                            {
                                title: 'Nieuwe berichten!',
                                message: 'Er zijn ' + posts.length + ' nieuwe berichten!'
                            }
                        );
                    }

                    posts.forEach(function (post) {
                        var postExists = $scope.conversations.find(x => x.postId == post.postId);

                        if ($scope.sendNotification) {
                            post.read = false;
                        }

                        if (postExists) {
                            var index = $scope.conversations.indexOf(postExists);
                            $scope.conversations[index] = post;
                        } else {
                            $scope.conversations.push(post);
                        }
                    });
                }

                applyFilters();
            };

            var handleGetAudiences = function (results: ChildCare.Definitions.GroupApp.WriteAudienceResponse) {
                $scope.audiences = results.groups;
            };

            $scope.loadPost = function (conversation: ChildCare.Definitions.GroupApp.PostResponse | ChildCare.Definitions.GroupApp.PostsResponseItem): void {
                $scope.messageListView = false;
                $scope.selectedConversation = conversation;

                $scope.isLoading = true;
                DataService.getPost(conversation).then(function (post) {
                    $scope.selectedConversation = post;
                    $scope.selectedConversation.createdOn = conversation.createdOn;
                }).finally(function () {
                    $scope.isLoading = false;
                    markAsRead(conversation);
                });
            };

            var markAsRead = function (conversation) {
                var index = $scope.conversations.indexOf(conversation);
                conversation.read = true;

                $scope.conversations[index] = conversation;
            };

            $scope.answerComment = function (comment: ChildCare.Definitions.GroupApp.PostComment) {
                $scope.replyComment = comment;
            };

            $scope.backToListView = function () {
                $scope.messageListView = true;
                $scope.replyComment = null;
                $scope.selectedConversation = null;
                $scope.message = '';
            };

            $scope.sendMessage = function (event: { message: string }, message: string) {
                $scope.message = message;

                if (!$scope.message || $scope.message === '' || $scope.isLoading)
                    {return;}

                $scope.isLoading = true;

                var prm = DataService.answerToPostAsync($scope.selectedConversation, $scope.replyComment, message);
                prm.then(handleSaveAnswerToPost);
                prm.finally(function () {
                    $scope.isLoading = false;
                    $scope.replyComment = null;
                    event.message = '';

                    $rootScope.$emit('newPostMade', false);
                });
            };


            var handleSaveAnswerToPost = function (result) {
                $scope.loadPost($scope.selectedConversation);
            };

            $scope.openNewConversationModal = function () {
                $uibModal.open({
                    animation: true,
                    templateUrl: 'partials/conversation/modal.html?v=' + ft.randomNumber,
                    controller: 'kpConversationModalController',
                    size: 'lg',
                    backdrop: true,
                    keyboard: true,
                    resolve: {
                        audiences: function () {
                            return ng.copy($scope.audiences);
                        },
                        childId: function () {
                            return ng.copy(childId);
                        }
                    }
                });
            };

            //#endregion
            $timeout(initialize);
        }
    ]);
