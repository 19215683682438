import { PresencesStatus } from '@/enums';
import { ChildCare } from '@/data-models';
import * as moment from 'moment';

import TransportChild = ChildCare.Definitions.Transport.Models.TransportChild;
import Child = ChildCare.Definitions.Child;

function isTransportChild(child: Child): child is TransportChild {
    if (child == null) {
        return false;
    }

    return child['statuses'] != null;
}

export const getChildStatus = (fromTimes: string[], child: TransportChild | Child): PresencesStatus => {
    if (!isTransportChild(child)) {
        return PresencesStatus.Expected;
    }

    for (const timeOfDay of fromTimes.map(x => moment.duration(x))) {
        let childTimes = Object.keys(child.statuses).map(x => ({ key: x, time: moment.duration(x) }));
        childTimes = childTimes.sort((a, b) => b.time.asMilliseconds() - a.time.asMilliseconds());

        for (const childTime of childTimes) {
            if (timeOfDay >= childTime.time) {
                return child.statuses[childTime.key];
            }
        }
    }

    return PresencesStatus.NotExpected;
};