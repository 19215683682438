import { AppDataService } from '@/services/app-data-service';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { IAugmentedJQuery, IDirective, IPromise, IQService, IScope, ITimeoutService } from 'angular';
import { appModule } from '@/definitions';
import { isTextContentBlock, isCardContentBlock, isCardLeftBlock, isCardRightBlock, isImagesBlock, isImagesContentBlock, Story, StoryBlock, StoryBlockContent, TextBlock, TextContentBlock, ImagesContentBlock, CardContentBlock } from '@/stories/types';
import uuidv1 from 'uuid/v1';
import { getEnvironment } from '@/environment';
import { resizeImage, toBase64 } from '@/resize-image';
import moment from 'moment';

const emptyGuid = '00000000-0000-0000-0000-000000000000';

class DateValue {
    value: Date;
}
interface Scope extends IScope {
    emptyGuid;

    story: Story;

    date: moment.Moment;
    publishOn: DateValue;
    publishOnOrg: Date;

    upload(blockId: string, index?: number): void;
    removeImage(block: StoryBlock, index?: number): void;

    addImage(block: StoryBlock, index: number, files: FileList): Promise<void>;
    hasImage(block: StoryBlock): boolean;
    hasImageX(block: StoryBlock): boolean;
    hasImageIndex(url: string): boolean;
    hasImageAt(block: StoryBlock, index: number): boolean;
    hasImageXAt(block: StoryBlock, index: number): boolean;
    getImagesClass(block: StoryBlock): string;
    blockChanged(block: StoryBlock): void;

    isLoading: boolean;
    save();
    cancel();
    doPublish();
    doUnpublish();
    getFormattedDateString(d: Date): string;
    getChanged(): StoryBlock[];
    blockIsChanged(): boolean;
    orgBlocks: StoryBlock[];
    cancelBlock();
    saveBlock();
    goOverview();
    cancelPublishOn();
    savePublishOnExt();
    hasPublishChange();
}

class StoryDetailsController {
    private $scope: Scope;
    private dataService: AppDataService;
    private $state: IStateService;
    private $q: IQService;
    private onRouteChangeOff: any;
    private $timeout: any;

    private uibModal: any;

    private changed: StoryBlock[] = [];

    public static $inject = ['$scope', '$timeout', '$state', '$q', 'AppDataService', '$stateParams', '$uibModal'];
    constructor($scope: Scope, $timeout: ITimeoutService, $state: IStateService, $q: IQService,
        dataService: AppDataService, $stateParams: IStateParamsService) {
        this.$scope = $scope;
        this.dataService = dataService;
        this.$state = $state;
        this.$q = $q;
        this.$timeout = $timeout;


        this.$scope.upload = this.upload.bind(this);
        this.$scope.removeImage = this.removeImage.bind(this);
        this.$scope.addImage = this.addImage.bind(this);
        this.$scope.hasImage = this.hasImage.bind(this);
        this.$scope.hasImageX = this.hasImageX.bind(this);
        this.$scope.hasImageIndex = this.hasImageIndex.bind(this);
        this.$scope.hasImageAt = this.hasImageAt.bind(this);
        this.$scope.hasImageXAt = this.hasImageXAt.bind(this);
        this.$scope.save = this.save.bind(this);
        this.$scope.cancel = this.cancel.bind(this);
        this.$scope.getImagesClass = this.getImagesClass.bind(this);
        this.$scope.blockChanged = this.blockChanged.bind(this);
        this.$scope.doPublish = this.doPublish.bind(this);
        this.$scope.doUnpublish = this.doUnpublish.bind(this);
        this.$scope.getFormattedDateString = this.getFormattedDateString.bind(this);
        this.$scope.getChanged = this.getChanged.bind(this);
        this.$scope.blockIsChanged = this.blockIsChanged.bind(this);
        this.$scope.cancelBlock = this.cancelBlock.bind(this);
        this.$scope.saveBlock = this.saveBlock.bind(this);
        this.$scope.goOverview = this.goOverview.bind(this);
        this.$scope.savePublishOnExt = this.savePublishOnExt.bind(this);
        this.$scope.cancelPublishOn = this.cancelPublishOn.bind(this);
        this.$scope.hasPublishChange = this.hasPublishChange.bind(this);

        $timeout(() => this.loadStory($stateParams.id));
    }

    public async $onInit() {
        const checkDirty = function (event: any) {

            var sc = event.currentScope as Scope;
            var ch = sc.getChanged();
            if (!sc.hasPublishChange()) {
                if (!ch) {
                    return;
                }
                if (ch.length === 0) {
                    return;
                }
            }

            alert('Er zijn niet opgeslagen veranderingen\nKies voor \'Annuleren\' of \'Opslaan\'');

            event.preventDefault();
            event.returnValue = '';
            return;
        };

        this.onRouteChangeOff = this.$scope.$on('$stateChangeStart', checkDirty);

        window.onbeforeunload = (event) => {
            var ch = this.$scope.getChanged();

            if (!this.$scope.hasPublishChange()) {
                if (!ch) {
                    return;
                }
                if (ch.length === 0) {
                    return;
                }
            }

            event.preventDefault();
            event.returnValue = '';
            return '';
        };
    }

    public async $onDestroy() {
        this.onRouteChangeOff();
        delete window.onbeforeunload;
    }

    private getFormattedDateString(d: Date) {
        return moment(d).format('DD-MM-YYYY');
    }

    public getChanged(): StoryBlock[] {
        return this.changed;
    }

    private doPublish() {
        const d = new Date();
        d.setMilliseconds(0);
        d.setSeconds(0);
        this.$scope.publishOn.value = d;
    }

    private doUnpublish() {
        this.$scope.publishOn.value = moment('0001-01-01').toDate();
    }

    private removeImage(block: StoryBlock, index?: number) {
        if (isCardContentBlock(block.content)) {
            block.content.imageUrl = getEnvironment().apiUrl.replace('api/', `getPicture.aspx?id=${emptyGuid}`);
            this.blockChanged(block);
        } else if (isImagesContentBlock(block.content)) {
            block.content.imageUrls[index] = getEnvironment().apiUrl.replace('api/', `getPicture.aspx?id=${emptyGuid}`);
            this.blockChanged(block);
        }
    }

    private upload(blockId: string, index?: number) {

        let selector = `.imageUpload[data-block-id='${blockId}']`;
        if (index != null) {
            selector += `[data-image-index='${index}']`;
        }
        ($(selector).get(0) as HTMLInputElement).value = '';
        $(selector).trigger('click');
    }

    private hasImageIndex(url) {
        return url.startsWith('data') || !url.endsWith(emptyGuid);
    }

    private hasImage(block: StoryBlock) {
        if (isCardLeftBlock(block) || isCardRightBlock(block)) {
            return block.imageUrl && !block.imageUrl.endsWith(emptyGuid);
        }

        return false;
    }

    private hasImageX(block: StoryBlock) {
        if (isCardLeftBlock(block) || isCardRightBlock(block)) {
            if (block.imageUrl && block.imageUrl.endsWith(emptyGuid)) {
                return false;
            }
            const thisBlock = (block as unknown) as CardContentBlock;
            return (block.imageUrl && !block.imageUrl.endsWith(emptyGuid)) ||
             (thisBlock.image && thisBlock.image.content);
        }

        return false;
    }

    private hasImageXAt(block: StoryBlock, index: number): boolean {
        if (isImagesBlock(block)) {

            if (block.imageUrls && block.imageUrls.length > 0 && block.imageUrls[index].endsWith(emptyGuid)) {
                return false;
            }
            const thisBlock = (block as unknown) as ImagesContentBlock;
            return (block.imageUrls && block.imageUrls.length > 0 && !block.imageUrls[index].endsWith(emptyGuid)) ||
                (thisBlock.images && thisBlock.images.length > 0 && thisBlock.images[index].content && thisBlock.images[index].content.length > 0);
        }

        return false;
    }

    private hasImageAt(block: StoryBlock, index: number): boolean {
        if (isImagesBlock(block)) {
            var result = block.imageUrls && block.imageUrls.length > 0 && !block.imageUrls[index].endsWith(emptyGuid);
            if (result) {
                if (block.imageUrls[index].startsWith('data')) {
                    return false;
                }
            }
            return result;
        }

        return false;
    }

    private blockChanged(block: StoryBlock): void {
        if (!this.changed.includes(block)) {
            this.changed.push(block);
        }

    }

    private canAddImages(block: StoryBlock): boolean {
        return true;
    }

    private getImagesClass(block: StoryBlock): string {
        if (isImagesBlock(block)) {
            // if (this.hasImages(block)) {
            //     return `images-block-${block.imageUrls.length}`;
            // } else {
            //     return `images-block-${block.content['imageUrls']?.length ?? 0}`;
            // }
            return 'images-block-4';
        }

        return '';
    }

    private async loadStory(storyId: string) {
        this.$scope.isLoading = true;

        try {
            this.$scope.story = await this.dataService.getStory(storyId);
            this.$scope.date = this.$scope.story.date;

            this.$scope.publishOn = new DateValue();
            this.$scope.publishOn.value = this.$scope.story.publishOn.toDate();
            this.$scope.publishOnOrg = this.$scope.publishOn.value;

            for (const block of this.$scope.story.blocks) {
                this.initializeContentBlocks(block);
            }
            this.$scope.orgBlocks = JSON.parse(JSON.stringify(this.$scope.story.blocks));

        } catch (error) {
            console.error('fetching story failed');
            console.error(error);
        } finally {
            this.$scope.isLoading = false;
        }

        this.$scope.$apply();
    }

    private async addImage(block: StoryBlock, index: number, files: FileList): Promise<void> {
        //if (files.length === 0 || this.hasImage(block) || this.hasImages(block)) {
        if (files.length === 0) {
            return;
        }

        const file = files[0];
        let content = await this.blobToDataURL(file);
        content = await resizeImage(content, 1024);

        if (isCardContentBlock(block.content)) {
            block.content.imageUrl = content;
            this.blockChanged(block);
        } else if (isImagesContentBlock(block.content)) {

            block.content.imageUrls[index] = content;

            //this.updateImagesBlock(block, index);
            this.blockChanged(block);
        }

        this.$scope.$apply();
    }

    public blockIsChanged(id: string): boolean {
        if (!this.changed) {
            return false;
        }
        if (this.changed.length === 0) {
            return false;
        }
        return this.changed.some(x => id === x.id);
    }

    public cancelBlock(block) {
        const orgBlock = this.$scope.orgBlocks.filter(x => x.id === block.id)[0];
        if (block.blockType === 'text') {
            const orgContent = orgBlock.content as TextContentBlock;
            block.content.title = orgContent.title;
            block.content.text = orgContent.text;
            block.content.optionalVisible = orgContent.optionalVisible;

            this.changed = this.changed.filter(function( obj ) {
                return obj.id !== block.id;
            });
        } else if (block.blockType === 'images') {
            const orgContent = orgBlock.content as ImagesContentBlock;
            block.content.optionalVisible = orgContent.optionalVisible;
            if (orgContent.images) {
                block.content.images = JSON.parse(JSON.stringify(orgContent.images));
            } else {
                block.content.images = undefined;
            }
            
            block.content.imageUrls = orgContent.imageUrls.slice();
            this.changed = this.changed.filter(function( obj ) {
                return obj.id !== block.id;
            });
        } else if (block.blockType === 'cardLeft' || block.blockType === 'cardRight') {
            const orgContent = orgBlock.content as CardContentBlock;

            block.content.optionalVisible = orgContent.optionalVisible;
            block.content.title = orgContent.title;
            block.content.text = orgContent.text;
            if (orgContent.image) {
                block.content.image = JSON.parse(JSON.stringify(orgContent.image));
            } else {
                block.content.image = undefined;
            }
            block.content.imageUrl = orgContent.imageUrl;

            this.changed = this.changed.filter(function( obj ) {
                return obj.id !== block.id;
            });
        } else if (block.blockType === 'presences') {
            const orgContent = orgBlock.content as StoryBlockContent;
            block.content.optionalVisible = orgContent.optionalVisible;
            this.changed = this.changed.filter(function( obj ) {
                return obj.id !== block.id;
            });
        }
    }

    public saveBlock(block) {
        if (block.blockType === 'text') {
            this.saveTextBlock(block).then(() => {
                this.changed = this.changed.filter(function( obj ) {
                    return obj.id !== block.id;
                });
            });

        } else if (block.blockType === 'images') {
            this.saveImagesBlock(block).then(() => {
                this.changed = this.changed.filter(function( obj ) {
                    return obj.id !== block.id;
                });
                
            });
        } else if (block.blockType === 'cardLeft' || block.blockType === 'cardRight') {
            this.saveCardBlock(block).then(() => {
                this.changed = this.changed.filter(function( obj ) {
                    return obj.id !== block.id;
                });
            });
        }
    }

    private savePublishOnExt() {
        if (this.hasPublishChange()) {
            this.$scope.story.publishOn = moment(this.$scope.publishOn.value);
            if (this.$scope.getFormattedDateString(this.$scope.publishOn.value) === '01-01-0001') {
                this.$scope.story.publishOn = moment('0001-01-01 00:19:32');
            }
           this.savePublishOn(this.$scope.story);
           this.$scope.publishOnOrg = this.$scope.publishOn.value;
        }
    }

    private hasPublishChange() {
        if (!this.$scope.publishOn) {
            return false;
        }
        return this.$scope.publishOn.value !== this.$scope.publishOnOrg;
    }

    private cancelPublishOn() {
        this.$scope.publishOn.value = this.$scope.publishOnOrg;
    }

    private save() {
        const tasks = new Array<IPromise<void>>();
        for (const block of this.changed) {
            tasks.push(this.saveCardBlock(block));
            tasks.push(this.saveImagesBlock(block));
            tasks.push(this.saveTextBlock(block));
        }

        if (this.$scope.publishOn.value !== this.$scope.publishOnOrg) {
            this.$scope.story.publishOn = moment(this.$scope.publishOn.value);
            if (this.$scope.getFormattedDateString(this.$scope.publishOn.value) === '01-01-0001') {
                this.$scope.story.publishOn = moment('0001-01-01 00:19:32');
            }
            tasks.push(this.savePublishOn(this.$scope.story));
        }

        this.$q.all(tasks).then(() => {
            this.changed = [];
            this.$state.go('app.stories');
        });
    }

    private goOverview() {
        this.$state.go('app.stories');
    }

    private savePublishOn(story: Story): IPromise<void> {
        return this.dataService.updateStory(story);
    }

    private saveTextBlock(block: StoryBlock): IPromise<void> {
        if (!isTextContentBlock(block.content)) {
            return this.$q.resolve();
        }
        return this.dataService.saveContentBlock(this.$scope.story.id, block.id, block.content);
    }

    private saveCardBlock(block: StoryBlock): IPromise<void> {
        if (!isCardContentBlock(block.content)) {
            return this.$q.resolve();
        }

        const hasNewImage = block.content.imageUrl.startsWith('data');
        block.content.image = {
            url: hasNewImage ? undefined : block.content.imageUrl,
            content: hasNewImage ? toBase64(block.content.imageUrl) : undefined
        };
        //block.content.imageUrl = undefined;

        return this.dataService.saveContentBlock(this.$scope.story.id, block.id, block.content);
    }

    private saveImagesBlock(block: StoryBlock): IPromise<void> {
        if (!isImagesContentBlock(block.content)) {
            return this.$q.resolve();
        }

        block.content.images = [];
        for (const url of block.content.imageUrls) {
            if (url.startsWith('data')) {
                block.content.images.push({
                    content: toBase64(url)
                });
            } else {
                block.content.images.push({
                    url: url
                });
            }
        }

        return this.dataService.saveContentBlock(this.$scope.story.id, block.id, block.content);
    }

    private cancel() {
        this.changed = [];
        this.$state.go('app.stories');
    }

    private blobToDataURL(blob: Blob): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = _e => resolve(reader.result as string);
            reader.onerror = _e => reject(reader.error);
            reader.onabort = _e => reject(new Error('Read aborted'));
            reader.readAsDataURL(blob);
        });
    }

    private initializeContentBlocks(block: StoryBlock): void {
        this.createEmptyContentBlock(block);
        this.copyBlockToContent(block);
        this.updateImagesBlock(block, 0);
        this.updateImagesBlock(block, 1);
        this.updateImagesBlock(block, 2);
        this.updateImagesBlock(block, 3);
    }

    private updateImagesBlock(block: StoryBlock, index: number) {
        if (isImagesBlock(block) && isImagesContentBlock(block.content) && this.hasImageAt(block, index) && this.canAddImages(block)) {
            const url = getEnvironment().apiUrl.replace('api/', `getPicture.aspx?id=${emptyGuid}`);
            block.content.imageUrls[index] = url;
        }
    }

    private copyBlockToContent(block: StoryBlock) {
        for (const key in block) {
            if (key === 'content') {
                continue;
            }

            const contentValue = block.content[key];
            if (contentValue == null) {
                block.content[key] = block[key];
            }
        }
    }

    private createEmptyContentBlock(block: StoryBlock) {
        if (block.content == null) {
            block.content = {
                id: uuidv1(),
                name: undefined,
                blockType: block.blockType,
                optionalVisible: true
            };
        }
    }
}

appModule.controller('StoryDetailsController', StoryDetailsController);

export class FileSelectedDirective implements IDirective {
    restrict = 'A';
    scope = {
        onSelected: '&onSelected'
    };

    link = ($scope: IScope & { onSelected: Function }, element: IAugmentedJQuery) => {
        element.bind('change', event => {
            $scope.$apply(() => {
                const files = (event.target as unknown as HTMLInputElement).files;
                $scope.onSelected({ files });
            });
        });
    };
}



appModule.directive('fileSelected', () => new FileSelectedDirective());
