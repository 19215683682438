import { appModule } from '@/definitions';
import Dropzone from 'dropzone';
import { FortyTwoRootScopeService } from '@/FortyTwoFramework';

export interface IFortyTwoDropzoneRootScopeService extends FortyTwoRootScopeService {
    dropzone: Dropzone;
}

appModule.directive('dropzone', ['$rootScope', function($rootScope: IFortyTwoDropzoneRootScopeService) {
        return function(scope, element, attrs) {
            const config = scope[attrs.dropzone];

            // create a Dropzone for the element with the given options
            $rootScope.dropzone = new Dropzone(element[0], config.options);

            for (const handler in config.eventHandlers) {
                $rootScope.dropzone.on(handler, config.eventHandlers[handler]);
            }
        };
    }
]);
