appModule.controller('kpPresenceDetailModalController',
    ['$scope', '$uibModalInstance', 'group', 'event',
        function ($scope, $uibModalInstance, group, event) {
            $scope.group = group;
            $scope.event = event;

            $scope.formatToDate = function (date) {
                return moment(date).format('DD-MM-YYYY');
            };

            $scope.close = function () {
                $uibModalInstance.dismiss();
            };
        }
    ]);
