import { IComponentOptions, IScope, IController, IPromise } from 'angular';
import { IStateService } from 'angular-ui-router';
import { ftSettingsService, FortyTwoLoginService } from '@/FortyTwoFramework';
import { AppDataService } from '@/services/app-data-service';
import { ILoadingBar } from '@/models';
import { RoleChecker } from '@/role-checker';
import { ChildCare } from '@/data-models';
import { appModule } from '@/definitions';

enum Mode {
    group = 'group',
    user = 'user'
}

interface Scope extends IScope {
    mode: keyof typeof Mode;
    title: string;
    user: ChildCare.Definitions.Account.UserModel;
    group: ChildCare.Definitions.Account.GroupModel;
    name: string;
    navigateToGroups: () => IPromise<void>;
}

class GroupSwitcherController implements IController {
    public static $inject = ['$scope', '$state', 'AppDataService', 'ftSettingService', 'LoadingBar', 'ftLoginService', RoleChecker.serviceName];

    public mode: keyof typeof Mode;
    public title: string;

    private readonly $scope: Scope;
    private readonly $state: IStateService;
    private readonly appDataService: AppDataService;
    private readonly ftSettingService: ftSettingsService;
    private readonly loadingBar: ILoadingBar;
    private readonly ftLoginService: FortyTwoLoginService;

    public constructor($scope: Scope, $state: IStateService, appDataService: AppDataService, ftSettingService: ftSettingsService, loadingBar: ILoadingBar, ftLoginService: FortyTwoLoginService, roleCheckerService: RoleChecker) {
        this.$scope = $scope;
        this.$state = $state;
        this.appDataService = appDataService;
        this.ftSettingService = ftSettingService;
        this.loadingBar = loadingBar;
        this.ftLoginService = ftLoginService;

        this.mode = Mode.group;

        this.$scope.navigateToGroups = roleCheckerService.switchGroup;
    }

    public async $onInit() {
        this.$scope.mode = this.mode;
        this.$scope.title = this.title;
        this.loadingBar.show = true;

        try {
            this.$scope.user = await this.ftLoginService.getCurrentUserAsync();

            if (this.mode !== Mode.user) {
                const selectedGroupId = this.ftSettingService.get(window.appSettings.group.selected);
                if (!selectedGroupId) {
                    this.$state.go('app.groups', { previous: this.$state.current.name });
                }

                const { groups } = await this.appDataService.getGroupsAsync();

                if (this.$scope.user.groups?.length === 1) {
                    this.ftSettingService.set(window.appSettings.group.selected, this.$scope.user.groups[0].id);
                    this.$scope.group = groups.find(x => x.id === this.$scope.user.groups[0].id);
                    this.$scope.name = this.$scope.group.name;
                } else {
                    this.$state.go('app.groups', { previous: this.$state.current.name });
                }
            } else {
                this.$scope.name = this.$scope.user.name;
            }
        } finally {
            this.loadingBar.show = false;
        }
    }
}

class GroupSwitcherComponent implements IComponentOptions {
    public controller: any;
    public template: string;
    public bindings: any;

    constructor() {
        this.bindings = {
            mode: '@?',
            title: '@',
        };

        this.controller = GroupSwitcherController;
        this.template = require('./template.html');
    }
}

appModule.component('groupSwitcher', new GroupSwitcherComponent());
