appModule.factory('LoadingBar', function() {
    return {
        show: false
    };
});

appModule.controller('kpLoadingController', [
    '$scope',
    'LoadingBar',
    function($scope, LoadingBar) {
        $scope.show = false;

        $scope.$watch(
            function() {
                return LoadingBar.show;
            },
            function(newVal, oldVal) {
                $scope.show = newVal;
            }
        );
    }
]);
