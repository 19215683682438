import { appModule } from '@/definitions';
import moment from 'moment';
import { IQService, IScope, IPromise, ITimeoutService } from 'angular';
import { AppDataService } from '@/services/app-data-service';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { ftSettingsService, FortyTwoLoginService } from '@/FortyTwoFramework';
import { StoryTemplate } from './types';
import { IStateService } from 'angular-ui-router';

interface ILocalScope extends IScope {
    selectStoryTemplate: () => void;
    newStory: () => void;
    cancel: () => void;

    storyTemplates: StoryTemplate[];
}

appModule.controller('kpStoryNewModalController',
    ['$scope', '$q', '$uibModalInstance', 'AppDataService', '$timeout', 'ftSettingService', '$state',
    function ($scope: ILocalScope, $q: IQService, $uibModalInstance: IModalInstanceService, dataService: AppDataService,
        $timeout: ITimeoutService, settingsService: ftSettingsService, $state: IStateService) {

        this.dataService = dataService;
        this.settingsService = settingsService;
        this.$scope = $scope;
        this.$scope.model = {};
        this.$scope.isLoading = true;
        this.$state = $state;
        
        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };

        const load = async () => {
            const groupId = this.settingsService.get(window.appSettings.group.selected);
            await this.dataService.getStoryTemplatesForGroup(groupId).then((x: StoryTemplate[]) => {
                this.$scope.storyTemplates = x;
                this.$scope.isLoading = false;
            });
        };

        $scope.newStory = async () => {
            //console.log(this.$scope.model.pickedStoryTemplate);
            const groupId = this.settingsService.get(window.appSettings.group.selected);
            await this.dataService.createStory(this.$scope.model.pickedStoryTemplate.id, groupId)
                .then((storyId: string) => {
                    $uibModalInstance.close('saved');
                    this.$state.go('app.story-details', { id: storyId });
                });
        };

        $timeout(() => load());
    }
]
);