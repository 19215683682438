$(document).ready(function($) {
    $(document).on('minimizeListView', '.chat-listview', function(event) {
        $(this).animate({ height: '283px' });
    });

    $(document).on('maximizeListView', '.chat-listview', function(event) {
        $(this).animate({ height: '423px' });
    });

    $(document).on('focus', '.ms-reply textarea', function(event) {
        $(this).animate({ height: '200px' });
        $('.chat-listview').trigger('minimizeListView');
    });

    $(document).on('blur', '.ms-reply textarea', function(event) {
        $(this).animate({ height: '60px' });
        $('.chat-listview').trigger('maximizeListView');
    });
});
