import { appModule } from '@/definitions';
import { IScope, ITimeoutService } from 'angular';
import { AppDataService } from '@/services/app-data-service';
import { IStateParamsService } from 'angular-ui-router';
import { ChildCare } from '@/data-models';

import DocumentsResponse = ChildCare.Definitions.Family.DocumentsResponse;
import { FortyTwoLoginService } from '@/FortyTwoFramework';

interface ILocalScope extends IScope {
    isLoading: boolean;
    documents: DocumentsResponse[];
    download: (document: DocumentsResponse) => void;
}

appModule.controller('kpChildDocumentController',
    ['$scope', 'AppDataService', '$stateParams', 'LoadingBar', '$timeout', 'ftLoginService',
        function ($scope: ILocalScope, DataService: AppDataService, $stateParams: IStateParamsService, LoadingBar, $timeout: ITimeoutService, ftLoginService: FortyTwoLoginService) {
            $scope.isLoading = false;

            $scope.$watch('isLoading', function (newVal) {
                LoadingBar.show = newVal;
            });

            var initialize = function () {
                $scope.isLoading = true;

                DataService.getDocumentsForChild($stateParams.id).then(documents => {
                    $scope.documents = documents;
                }).finally(() => $scope.isLoading = false);
            };

            $scope.download = (document) => {
                const tokenData = ftLoginService.getTokenData();

                var token = encodeURIComponent(tokenData.token.value);

                var element = window.document.createElement('a');
                element.setAttribute('href', `${document.url}?accessToken=${token}`);
                element.setAttribute('download', document.title);
                element.setAttribute('target', '_blank');

                element.style.display = 'none';
                window.document.body.appendChild(element);

                element.click();

                window.document.body.removeChild(element);
            };

            $timeout(initialize);
        }
    ]
);