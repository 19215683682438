import { appModule, ft } from '@/definitions';
import moment from 'moment';
import { swal } from 'bootstrap-sweetalert';

import { ftSettingsService } from '@/FortyTwoFramework';
import { AppDataService } from '@/services/app-data-service';
import { IModalService } from 'angular-ui-bootstrap';
import { IController, ILocationService, IIntervalService, ITimeoutService, IScope, IPromise } from 'angular';
import { IStateService } from 'angular-ui-router';

import { ChildCare } from '@/data-models';

import PresencesResponse = ChildCare.Definitions.GroupApp.PresencesResponse;
import PresencesGroup = ChildCare.Definitions.GroupApp.PresencesGroup;
import { Child, Event, ChildType, DiaryEvent } from '@/child-factory';
import { PresencesStatus } from '@/enums';
import { ILoadingBar } from '@/models';
import { RoleChecker } from '@/role-checker';

interface IModel {
    showAll: boolean;
    bulk: boolean;
    showDiarySchedules: boolean;
    labels: boolean;
    firstChildStatusIsPresent: boolean | null;
    toggledBulkChildren: Child[];
    statusExpectedAmount: number;
    statusPresentAmount: number;
    statusSickAmount: number;
    statusAbsentAmount: number;
    statusNotExpectedAmount: number;
}

class TimeLineHeader {
    constructor(
        public startTime: moment.Duration,
        public endTime: moment.Duration,
        public label: string
    ) {

    }

    public formatLabel() {
        if (this.label != null) {
            return this.label;
        }
        const startLabel = moment.utc(this.startTime.asMilliseconds()).format('HH:mm');
        const endLabel = moment.utc(this.endTime.asMilliseconds()).format('HH:mm');

        return `${startLabel} - ${endLabel}`;
    }
}

interface ITimeTable {
    hourStart: number;
    hourEnd: number;
    timeLabels: string[];
    childrenCounts: TimeLineHeader[];
    diaryTimeLabels: string[];
    diaryChildrenCounts: TimeLineHeader[];
}

interface ILocalScope extends IScope {
    isLoading: boolean;
    initialized: boolean;
    group: PresencesGroup | null;
    PresencesStatus: typeof PresencesStatus;
    showMassButton: boolean;
    filter: PresencesStatus[];
    model: IModel;
    timetable: ITimeTable;
    pickedDate: moment.Moment;
    allChildren: Child[];
    children: Child[];
    nextDay: string;
    previousDay: string;
    today: string;

    displayDatePicker(): void;
    navigateToGroups(): void;
    updateBlockHeight(): void;
    computeBlockWidth(startTime: moment.Duration, endTime: moment.Duration): number;
    computeBlockOffset(startTime: moment.Duration): number;
    setFilter(status: PresencesStatus | PresencesStatus[]): void;
    isFilterSelected(status: PresencesStatus | PresencesStatus[]): boolean;
    shouldDisplayCheckbox(child: Child): boolean;
    toggleChild(child: Child): void;
    openDetailModal(event: Event): void;
    openChildModal(child: Child): void;
    openModal(): void;
    openPresenceModal(child: Child | Child[], bulk: boolean): void;
    gotoToday(): void;
    refresh(): void;
    gotoNextDay(): void;
    gotoPreviousDay(): void;
    open($event: any, opened: any): void;
    createOrDeleteDiaryEntry(diaryEvent: DiaryEvent): void;
    getTickIconClass(diaryEvent: DiaryEvent): string;
}

class PresenceOverviewController implements IController {
    static $inject = [
        '$scope',
        '$timeout',
        'AppDataService',
        'ftSettingService',
        '$uibModal',
        '$location',
        '$localStorage',
        '$state',
        '$interval',
        'LoadingBar',
        'mdcDateTimeDialog',
        '$translate',
        RoleChecker.serviceName];

    private selectedGroupId: string | null;
    private updateRedLineInterval: IPromise<any>;

    constructor(
        private $scope: ILocalScope,
        private $timeout: ITimeoutService,
        private DataService: AppDataService,
        private SettingService: ftSettingsService,
        private $uibModal: IModalService,
        private $location: ILocationService,
        private $localStorage: any,
        private $state: IStateService,
        private $interval: IIntervalService,
        private LoadingBar: ILoadingBar,
        private mdcDateTimeDialog: any,
        private $translate: any,
        private roleCheckerService: RoleChecker
    ) {
        this.initializeScreen();
        this.loadScopeWatches();

        this.updateRedLineInterval = this.$interval(() => {
             this.updateRedLine();
        }, 1000 * 60);

         this.$scope.$on('$destroy', () => {
             this.$interval.cancel(this.updateRedLineInterval);
        });

        this.$scope.computeBlockWidth = this.computeBlockWidth.bind(this);
        this.$scope.computeBlockOffset = this.computeBlockOffset.bind(this);
        this.$scope.setFilter = this.setFilter.bind(this);
        this.$scope.isFilterSelected = this.isFilterSelected.bind(this);
        this.$scope.shouldDisplayCheckbox = this.shouldDisplayCheckbox.bind(this);
        this.$scope.toggleChild = this.toggleChild.bind(this);
        this.$scope.openDetailModal = this.openDetailModal.bind(this);
        this.$scope.openChildModal = this.openChildModal.bind(this);
        this.$scope.openModal = this.openModal.bind(this);
        this.$scope.openPresenceModal = this.openPresenceModal.bind(this);
        this.$scope.displayDatePicker = this.displayDatePicker.bind(this);
        this.$scope.updateBlockHeight = this.updateBlockHeight.bind(this);
        this.$scope.refresh = this.refresh.bind(this);
        this.$scope.gotoToday = this.gotoToday.bind(this);
        this.$scope.gotoNextDay = this.gotoNextDay.bind(this);
        this.$scope.gotoPreviousDay = this.gotoPreviousDay.bind(this);
        this.$scope.open = this.open.bind(this);
        this.$scope.navigateToGroups = this.roleCheckerService.switchGroup;
        this.$scope.createOrDeleteDiaryEntry = this.createOrDeleteDiaryEntry.bind(this);
        this.$scope.getTickIconClass = this.getTickIconClass.bind(this);

        this.$timeout(() => {
            if (this.$scope.initialized) { return; }

            this.selectedGroupId = this.SettingService.get(window.appSettings.group.selected);
            if (!this.selectedGroupId) {
                this.$state.go('app.groups',{previous:this.$state.current.name});
            }

            this.updatePresenceOverview();
            this.$scope.initialized = true;
        });
    }

    private initializeScreen() {
        this.selectedGroupId = null;

        this.$scope.isLoading = false;
        this.$scope.initialized = false;
        this.$scope.group = null;
        this.$scope.PresencesStatus = PresencesStatus;
        this.$scope.showMassButton = true;
        this.$scope.filter = [];

        this.$scope.model = {
            showAll: false,
            bulk: false,
            showDiarySchedules: false,
            labels: this.$localStorage.showLabels ?? false,
            firstChildStatusIsPresent: null,
            toggledBulkChildren: [],
            statusExpectedAmount: 0,
            statusPresentAmount: 0,
            statusSickAmount: 0,
            statusAbsentAmount: 0,
            statusNotExpectedAmount: 0
        };

        this.$scope.timetable = {
            hourStart: 6,
            hourEnd: 20,
            timeLabels: [],
            childrenCounts: [],
            diaryChildrenCounts: [],
            diaryTimeLabels: []
        };

        this.$scope.pickedDate = this.$location.search().date ?
            moment(this.$location.search().date, 'DD/MM/YYYY') :
            moment();

        this.$scope.allChildren = [];
        this.$scope.children = [];
    }

    private loadScopeWatches() {
        this.$scope.$watch('model.showAll', () => {
            this.updateBlockHeight();
        });

        this.$scope.$watch('model.bulk', (oldVal: boolean, newVal: boolean) => {
            if (newVal) {
                this.$scope.model.toggledBulkChildren = [];
                this.$scope.model.firstChildStatusIsPresent = null;
            }
        });

        this.$scope.$watch('isLoading', (newVal: boolean, oldVal: boolean) => {
            this.LoadingBar.show = newVal;
        });

        this.$scope.$watch('children', () => {
            this.updateStatusCount();
        }, true);

        this.$scope.$watch('pickedDate', () => {
            this.$scope.showMassButton = !!this.isToday();
            if (!this.isToday()) {
                this.$scope.model.bulk = false;
            }

            this.$location.path('/app/presence').search({ date: this.$scope.pickedDate.format('DD-MM-YYYY') });
            this.updatePresenceOverview();
        }, true);

        this.$scope.$watch('model.labels', (value: boolean) => this.$localStorage.showLabels = value);
    }

    private computeDurationInHours(start: moment.Duration, end: moment.Duration): number {
        return end.clone().subtract(start).asHours();
    }

    private getDurationHours(startHour: number, endHour: number): number {
        return endHour >= startHour ? endHour - startHour : 24 + endHour - startHour;
    }

    private computeBlockWidth(startTime: moment.Duration, endTime: moment.Duration) {
        const eventDurationInHours = this.computeDurationInHours(startTime, endTime);
        const totalHoursToDisplay = this.getDurationHours(this.$scope.timetable.hourStart, this.$scope.timetable.hourEnd);
        let result = eventDurationInHours / totalHoursToDisplay * 100;

        const offset = this.computeBlockOffsetAsNumber(startTime);
        if (offset + result > 100) {
            result = 100 - offset;
        }
        return result + '%';
    }

    private computeBlockOffset(startTime: moment.Duration) {
        return this.computeBlockOffsetAsNumber(startTime) + '%';
    }

    private computeBlockOffsetAsNumber(startTime: moment.Duration) {
        var scopeStartHours = this.$scope.timetable.hourStart;
        var eventStartHours = startTime.asHours();
        var hoursBeforeEvent = this.getDurationHours(scopeStartHours, eventStartHours);
        return hoursBeforeEvent / this.getDurationHours(this.$scope.timetable.hourStart, this.$scope.timetable.hourEnd) * 100;
    }

    private arraysEqual(a: any, b: any): boolean {
        if (a === b) {
            return true;
        }
        if (a == null || b == null) {
            return false;
        }
        if (a.length != b.length) {
            return false;
        }

        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) { return false; }
        }

        return true;
    }

    private setFilter(status: PresencesStatus | PresencesStatus[]) {

        let statusArray: PresencesStatus[];
        if (!Array.isArray(status)) {
            statusArray = [status];
        } else {
            statusArray = status;
        }

        this.$scope.filter = this.arraysEqual(this.$scope.filter, statusArray) ? [] : statusArray;
        this.$scope.children = this.$scope.filter.length === 0 ?
            this.$scope.allChildren :
            this.$scope.allChildren.filter(x => statusArray.some(s => s == x.status));

        this.updateBlockHeight();
    }

    private isFilterSelected(status: PresencesStatus | PresencesStatus[]): boolean {
        let statusArray: PresencesStatus[];
        if (!Array.isArray(status)) {
            statusArray = [status];
        } else {
            statusArray = status;
        }

        const areEqual = this.arraysEqual(this.$scope.filter, statusArray);

        return areEqual;
    }

    private addOrRemove(array: any, value: any): boolean {
        var index = array.indexOf(value);

        if (index === -1) {
            array.push(value);
            return true;
        }

        array.splice(index, 1);
        return false;
    }

    private shouldDisplayCheckbox(child: Child): boolean {
        return this.$scope.model.bulk &&
            this.$scope.model.firstChildStatusIsPresent == null ||
            (this.$scope.model.firstChildStatusIsPresent === true && child.status === PresencesStatus.Present) ||
            (this.$scope.model.firstChildStatusIsPresent === false && child.status !== PresencesStatus.Present);
    }

    private toggleChild(child: Child): void {
        var added = this.addOrRemove(this.$scope.model.toggledBulkChildren, child);

        if (this.$scope.model.firstChildStatusIsPresent === null && added) {
            this.$scope.model.firstChildStatusIsPresent = child.status === PresencesStatus.Present;
        }

        if (this.$scope.model.firstChildStatusIsPresent !== null && !added && this.$scope.model.toggledBulkChildren.length === 0) { this.$scope.model.firstChildStatusIsPresent = null; }
    }

    private openDetailModal(event: Event) {
        this.$uibModal.open({
            animation: true,
            templateUrl: 'partials/presence/detailModal.html?v=' + ft.randomNumber,
            controller: 'kpPresenceDetailModalController',
            size: 'sm',
            backdrop: true,
            keyboard: true,
            resolve: {
                event: () => event,
                group: () => this.$scope.group
            }
        });
    }

    private openChildModal(child: Child) {
        this.$localStorage.childModalUser = child;
        this.$location.path('/app/child/' + child.id);
    }

    private openModal() {
        this.openPresenceModal(this.$scope.model.toggledBulkChildren, true);
    }

    private openPresenceModal(child: Child | Child[], bulk: boolean): void {
        if (this.$scope.model.bulk && !bulk) {
            this.toggleChild(child as Child);
            return;
        }

        const modal = this.$uibModal.open({
            animation: true,
            templateUrl: 'partials/presence/childModal.html?v=' + ft.randomNumber,
            controller: 'kpPresenceChildModalController',
            size: 'custom',
            backdrop: true,
            keyboard: true,
            resolve: {
                child: () => child,
                bulk: () => bulk,
                selectedGroup: () => this.selectedGroupId,
                date: () => this.$scope.pickedDate
            }
        });

        modal.result.then(result => {
                if (result !== 'saved') {
                    return;
                }

                this.$scope.model.bulk = false;
                this.$scope.model.toggledBulkChildren = [];
                this.$scope.model.firstChildStatusIsPresent = null;

                this.updatePresenceOverview();
            }, () => {
                this.$scope.model.bulk = false;
                this.$scope.model.toggledBulkChildren = [];
                this.$scope.model.firstChildStatusIsPresent = null;
            });
    }

    private updateRedLine(): void {
        try {
            const now = moment();
            const timeOfDay = moment.duration({
                hours: now.hours(),
                minutes: now.minutes()
            });

            var offset = this.computeBlockOffset(timeOfDay);
            var timeIndicator = $('.time-indicator');
            var presenceScrollableSection = $('.presence .timetable section');

            timeIndicator.css('left', offset);

            var width = presenceScrollableSection.width();
            var linePosition = timeIndicator.position() ? timeIndicator.position().left : null;
            if (linePosition == null || width == null) {
                return;
            }

            var scroll = linePosition - (width / 2);
            presenceScrollableSection.scrollLeft(scroll);
        } catch (error) {
            console.log(error);
        }
    }

    private updatePresenceOverview(): void {
        this.$scope.isLoading = true;

        if (!this.selectedGroupId) {
            return;
        }

        this.DataService.getPresenceOverviewData(this.selectedGroupId, this.$scope.pickedDate, true)
            .then(response => { this.handlePresenceData(response); })
            .finally(() => {
                this.$scope.isLoading = false;
            });
    }

    private handlePresenceData(result: PresencesResponse): void {
        this.$scope.children = [];
        this.$scope.allChildren = [];
        this.$scope.timetable.childrenCounts = [];
        this.$scope.timetable.diaryChildrenCounts = [];

        if (!result.children) {
            this.$scope.isLoading = false;
            return;
        }

        this.$scope.group = result.group;
        this.$scope.timetable.hourStart = parseInt(result.group.openingTimes.start.substring(0, 2));

        const endHour = parseInt(result.group.openingTimes.end.substring(0, 2));
        this.$scope.timetable.hourEnd = parseInt(result.group.openingTimes.end.substring(3, 5)) > 0 ? endHour + 1 : endHour;
        this.$scope.timetable.hourEnd = this.$scope.timetable.hourEnd === 0 ? 24 : this.$scope.timetable.hourEnd;

        this.$scope.timetable.timeLabels = [];
        for (let j = this.$scope.timetable.hourStart; j <= this.$scope.timetable.hourEnd; j++) {
            this.$scope.timetable.timeLabels.push(this.prettyFormatHour(j));
        }

        this.$scope.timetable.diaryTimeLabels = [];
        for (let j = this.$scope.timetable.hourStart; j <= this.$scope.timetable.hourEnd; j++) {
            this.$scope.timetable.diaryTimeLabels.push(this.prettyFormatHour(j));
        }

        for (const child of result.children) {
            const kid = new Child(child, ChildType.PresencesChild);

            if (child.labels) {
                for (const label of child.labels) {
                    kid.addLabel(label);
                }
            }

            for (const event of child.events) {
                kid.addEvent(event);
            }

            for (const diaryEvent of child.diaryEvents)
            {
                kid.addDiaryEvent(diaryEvent);
            }

            this.$scope.allChildren.push(kid);
        }

        this.$scope.children = this.$scope.allChildren;

        if (result.header) {
            for (const item of result.header.items) {
                const startTime = moment.duration(item.startTime);
                const endTime = moment.duration(item.endTime);

                this.$scope.timetable.childrenCounts.push(new TimeLineHeader(startTime, endTime, item.count.toString()));
            }
        }

        if (result.diaryScheduleHeader) {
            for (const item of result.diaryScheduleHeader.items) {
                const startTime = moment.duration(item.startTime);
                const endTime = moment.duration(item.endTime);

                this.$scope.timetable.diaryChildrenCounts.push(new TimeLineHeader(startTime, endTime, item.count.toString()));
            }
        }

        this.$scope.nextDay = result.dates.nextDay;
        this.$scope.previousDay = result.dates.previousDay;
        this.$scope.today = result.dates.today;

        this.updateBlockHeight();
    }

    private updateBlockHeight(): void {
        this.$timeout(() => {
            var aside = $('.timetable aside li');

            var items = $('.room-timeline li');
            for (let i = 0; i < items.length; i++) {
                var height = $(aside[i + 1]).css('height');

                $(items[i]).css('height', height);
                $(items[i]).find('a').css('height', (parseInt(height.substring(0, height.length - 2)) - 8) + 'px');
            }

            this.updateRedLine();
        });
    }

    private prettyFormatHour(hour: number) {
        var prefix = hour < 10 ? '0' : '';
        return prefix + hour + ':00';
    }

    private updateStatusCount(): void {

        this.$scope.model.statusExpectedAmount = 0;
        this.$scope.model.statusPresentAmount = 0;
        this.$scope.model.statusNotExpectedAmount = 0;
        this.$scope.model.statusAbsentAmount = 0;
        this.$scope.model.statusSickAmount = 0;

        for (const child of this.$scope.allChildren) {
            const status = child.status as any;

            if (status == PresencesStatus.Expected) {
                this.$scope.model.statusExpectedAmount++;
            } else if (status == PresencesStatus.Present) {
                this.$scope.model.statusPresentAmount++;
            } else if (status == PresencesStatus.NotExpected) {
                this.$scope.model.statusNotExpectedAmount++;
            } else if (status == PresencesStatus.PickedUp || child.status == PresencesStatus.Absent) {
                this.$scope.model.statusAbsentAmount++;
            } else if (status == PresencesStatus.Sick) {
                this.$scope.model.statusSickAmount++;
            }
        }

        this.$scope.model.showAll = this.$scope.model.statusNotExpectedAmount === this.$scope.allChildren.length;
        this.$scope.model.showAll = this.$scope.model.statusNotExpectedAmount === this.$scope.allChildren.length ||
            this.$scope.filter.some(x => x == PresencesStatus.NotExpected);
    }

    private displayDatePicker(): void {
        this.mdcDateTimeDialog.show({
            currentDate: this.$scope.pickedDate,
            time: false,
            autoOk: true,
            disableParentScroll: true
        }).then((date) => {
            this.$scope.pickedDate = moment(date);
        });
    }

    private isToday(): boolean {
        return moment().startOf('day')
            .diff(this.$scope.pickedDate.startOf('day'), 'day') === 0;
    }

    private refresh(): void {
        window.location.reload();
    }

    private gotoToday(): void {
        if (this.$scope.today) {
            this.$scope.pickedDate = moment(this.$scope.today);
        }
    }

    private gotoNextDay(): void {
        if (this.$scope.nextDay) {
            this.$scope.pickedDate = moment(this.$scope.nextDay);
        }
    }

    private gotoPreviousDay(): void {
        if (this.$scope.previousDay) {
            this.$scope.pickedDate = moment(this.$scope.previousDay);
        }
    }

    private open($event, opened) {
        $event.preventDefault();
        $event.stopPropagation();

        this.$scope[opened] = true;
    }

    private createOrDeleteDiaryEntry(diaryEvent: DiaryEvent): void {
        this.$scope.isLoading = true;

        if (!this.isToday()) {
            this.$scope.isLoading = false;
            return;
        }

        let task: IPromise<any>;

        if (diaryEvent.diaryEntryId == null) {
            let startMoment: moment.Moment = moment().startOf('day');
            startMoment = startMoment.add(diaryEvent.startTime);

            var children: string[] = [ diaryEvent.childId];
            task = this.DataService.saveDiaryEntry(children, diaryEvent.diaryEntryTypeId, diaryEvent.diaryEntryType, startMoment, diaryEvent.diaryScheduleId);
        } else {
            task = this.DataService.deleteDiaryEntry(diaryEvent.childId, diaryEvent.diaryEntryId);
        }

        task
            .then(() => this.updatePresenceOverview())
            .catch(error =>{
                console.error(error);
                swal({
                    title: this.$translate.instant('ERRORS.SAVE-FAILED.TITLE'),
                    text: this.$translate.instant('PRESENCE.SAVE.FAILED'),
                    timer: 2500,
                    type: 'error'
                });
            })
            .finally(() => this.$scope.isLoading = false);
    }

    private getTickIconClass(diaryEvent: DiaryEvent): string {
        if (diaryEvent.diaryEntryId != null) {
            return 'fas fa-check';
        }

        return '';
    }
}

appModule.controller('kpPresenceOverviewController', PresenceOverviewController);
