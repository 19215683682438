import { appModule, ng } from '@/definitions';
import { IController, IQService, IScope } from 'angular';
import {
    IAddress,
    ICountry,
    AppDataService,
    IParent,
    IParentDetailUpdateRequest
} from '@/services/app-data-service';
import { IModalServiceInstance } from 'angular-ui-bootstrap';
import moment from 'moment';
import { Gender } from '@/enums';

class Parent implements IParent {
    birthDate: moment.Moment;
    emailAddress: string;
    firstName: string;
    gender: Gender;
    initials: string;
    lastName: string;
    lastNamePrefix: string;
    mobileNumber: string;
    parentId: string;
    phoneNumber: string;
    phoneWork: string;

    constructor(parent: IParent) {
        Object.assign(this, parent);
    }

    getBirthDate(): moment.Moment {
        return moment(this.birthDate);
    }

    fullName(): string {
        let fullName = this.firstName;
        if (this.lastNamePrefix && this.lastNamePrefix !== '') {
            fullName += ' ' + this.lastNamePrefix;
        }

        if (this.lastName && this.lastName !== '') {
            fullName += ' ' + this.lastName;
        }
        return fullName;
    }
}

class Address implements IAddress {
    city: string;
    countryId: string;
    postalCode: string;
    street: string;
    streetNumber: string;
    googleApiKey = 'AIzaSyB1KCqC2iOubGbKy3kJadFHqtg_ffLcF-M';

    constructor(address: IAddress) {
        Object.assign(this, address);
    }

    getFullAddress() {
        return `${this.street} ${this.streetNumber}, ${this.postalCode} ${this.city}`;
    }

    getGoogleMapsLink() {
        return (
            'https://www.google.com/maps/embed/v1/place?' +
            'key=' +
            this.googleApiKey +
            '&q=' +
            this.getFullAddress().replace(' ', '+')
        );
    }
}

interface ILocalScope extends IScope {
    isLoading: boolean;
    isEditing: boolean;
    parent: Parent;
    address: Address;
    genders: string[];
    countries: ICountry[];
    form: IParentDetailUpdateRequest;
    cancelEditMode: () => void;
    setEditMode: () => void;
    cancel: () => void;
    save: () => void;
}

class ParentModalController implements IController {
    static $inject = ['$scope', 'AppDataService', '$modalInstance', 'parentId'];

    constructor(private $scope: ILocalScope,
                private dataService: AppDataService,
                $modalInstance: IModalServiceInstance,
                private parentId: string) {

        $scope.isLoading = true;
        $scope.isEditing = false;

        $scope.genders = Object.values(Gender);
        this.fetchData();

        $scope.cancel = () => {
            $modalInstance.dismiss();
        };

        $scope.setEditMode = () => {
            $scope.isEditing = true;
        };

        $scope.cancelEditMode = () => {
            $scope.isEditing = false;
            this.initializeEditMode();
        };

        $scope.save = () => {
            $scope.isLoading = true;
            dataService.updateParentDetails(ng.copy($scope.form)).finally(() => {
                $scope.isLoading = false;
                $scope.cancelEditMode();
                this.fetchData();
            });
        };
    }

    private fetchData() {
        this.dataService
            .getParentDetails(this.parentId)
            .then(result => {
                this.$scope.parent = new Parent(result.parent);
                this.$scope.address = new Address(result.address);
                this.$scope.countries = result.countries;

                this.initializeEditMode();
            })
            .finally(() => {
                this.$scope.isLoading = false;
            });
    }

    public initializeEditMode() {
        this.$scope.form = {
            birthDate: this.$scope.parent.getBirthDate(),
            emailAddress: this.$scope.parent.emailAddress,
            firstName: this.$scope.parent.firstName,
            gender: this.$scope.parent.gender,
            initials: this.$scope.parent.initials,
            lastName: this.$scope.parent.lastName,
            lastNamePrefix: this.$scope.parent.lastNamePrefix,
            mobileNumber: this.$scope.parent.mobileNumber,
            parentId: this.$scope.parent.parentId,
            phoneNumber: this.$scope.parent.phoneNumber,
            phoneWork: this.$scope.parent.phoneWork,

            city: this.$scope.address.city,
            countryId: this.$scope.address.countryId,
            postalCode: this.$scope.address.postalCode,
            street: this.$scope.address.street,
            streetNumber: this.$scope.address.streetNumber
        };
    }
}

appModule.controller('kpParentModalController', ParentModalController);
