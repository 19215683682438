import { appModule, ft } from '@/definitions';
import moment from 'moment';
import { IScope, IQService, IFilterService, copy } from 'angular';
import { AppDataService } from '@/services/app-data-service';
import { ftSettingsService } from '@/FortyTwoFramework';
import { IModalService } from 'angular-ui-bootstrap';
import { IStateService } from 'angular-ui-router';
import swal from 'bootstrap-sweetalert';
import { ChildCare } from '@/data-models';

import GroupRequestsResponse = ChildCare.Definitions.GroupApp.GroupRequestsResponse;
import GroupsResponse = ChildCare.Definitions.GroupApp.GroupsResponse;
import GroupRequestsRegistration = ChildCare.Definitions.GroupApp.GroupRequestsRegistration;
import TypeOfRegistration = ChildCare.Definitions.Common.TypeOfRegistration;
import { RoleChecker } from '@/role-checker';
import { ILoadingBar } from '@/models';

export class RequestItem {
    createdOn: moment.Moment;
    date: moment.Moment;
    originalDate: moment.Moment;
    description: string;
    registrationId: string;
    registrationType: ChildCare.Definitions.Common.TypeOfRegistration;
    registrationTypeTranslation: string;
    times: string;
    originalTimes: string;
    child: ChildCare.Definitions.Child;

    constructor(registration: GroupRequestsRegistration) {
        this.createdOn = moment(registration.createdOn);
        this.date = moment(registration.date);
        this.originalDate = moment(registration.originalDate);
        this.description = registration.description;
        this.registrationId = registration.registrationId;
        this.registrationType = registration.registrationType;
        this.registrationTypeTranslation = `DATA.${registration.registrationType.toUpperCase()}`;
        this.times = registration.times;
        this.originalTimes = registration.originalTimes;
        this.child = registration.child;
    }
}

interface ILocalScope extends IScope {
    group: any;
    requests: RequestItem[];
    moment: typeof moment;
    possibleTypes: TypeOfRegistration[];
    selectedTypes: TypeOfRegistration[];
    isLoading: boolean;
    tableSorting: any;
    filterByType: (type: any) => void;
    showRequestModal: (request: RequestItem) => void;
    navigateToGroups: () => void;
}

appModule.controller('kpRequestsController',
    ['$scope', '$timeout', 'AppDataService', 'ftSettingService', '$q',
        '$uibModal', '$state', 'LoadingBar', 'ngTableParams', '$filter', RoleChecker.serviceName,
        function ($scope: ILocalScope, $timeout, DataService: AppDataService, SettingService: ftSettingsService, $q: IQService,
                  $uibModal: IModalService, $state: IStateService, LoadingBar: ILoadingBar, ngTableParams, $filter: IFilterService, roleCheckerService: RoleChecker) {

            var selectedGroupId = null;

            $scope.group = null;

            var allRequests = [];
            $scope.requests = [];
            $scope.moment = moment;

            $scope.possibleTypes = [];
            $scope.selectedTypes = [];

            $scope.isLoading = false;
            $scope.$watch('isLoading', (newVal: boolean) => {
                LoadingBar.show = newVal;
            });

            var initialize = function () {
                $scope.isLoading = true;

                selectedGroupId = SettingService.get(window.appSettings.group.selected);
                if (!selectedGroupId) {
                    $state.go('app.groups',{ previous:$state.current.name });
                }

                var promises = [];
                promises.push(DataService.getGroupsAsync());
                promises.push(DataService.getRequestList(selectedGroupId));
                var request = $q.all(promises);

                request.then(function (results) {
                    $scope.group = (results[0] as GroupsResponse).groups.find(x => x.id == selectedGroupId);

                    var requests: RequestItem[] = [];
                    var possibleTypes: TypeOfRegistration[] = [];
                    for (const registration of (results[1] as GroupRequestsResponse).registrations) {
                        requests.push(new RequestItem(registration));

                        if (possibleTypes.indexOf(registration.registrationType) === -1) {
                            possibleTypes.push(registration.registrationType);
                        }
                    }

                    $scope.possibleTypes = copy(possibleTypes);
                    $scope.selectedTypes = copy(possibleTypes);

                    $scope.requests = copy(requests);
                    allRequests = copy(requests);

                }).finally(function () {
                    $scope.isLoading = false;
                });
            };

            $scope.tableSorting = new ngTableParams({
                count: 10
            }, {
                total: $scope.requests.length,
                getData: function ($defer, params) {
                    // use build-in angular filter
                    var orderedData = params.sorting() ? $filter('orderBy')($scope.requests, params.orderBy()) : $scope.requests;
                    $defer.resolve(orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));

                    // set total for recalc pagination
                    params.total($scope.requests.length);
                }
            });

            $scope.filterByType = function (type) {
                $scope.selectedTypes.indexOf(type) === -1 ?
                    $scope.selectedTypes.push(type) :
                    $scope.selectedTypes.splice($scope.selectedTypes.indexOf(type), 1);

                var requests = [];

                for (const request of allRequests) {
                    for (const type of $scope.selectedTypes) {
                        if (request.registrationType === type) {
                            requests.push(request);
                        }
                    }
                }

                $scope.requests = requests;
                refreshTable();
            };

            var refreshTable = function () {
                $scope.tableSorting.reload();
                $scope.tableSorting.page(1);
            };

            $scope.showRequestModal = function (request) {
                var instance = $uibModal.open({
                    animation: true,
                    templateUrl: 'partials/requests/modal.html?v=' + ft.randomNumber,
                    controller: 'kpRequestModalController',
                    backdrop: true,
                    keyboard: true,
                    resolve: {
                        request: function () {
                            return copy(request);
                        },
                        groupId: function () {
                            return copy(selectedGroupId);
                        }
                    }
                });

                instance.result.then(function (request) {
                    swal({
                        title: 'Opgeslagen',
                        text: 'De aanvraag is verwerkt!',
                        timer: 3000,
                        type: 'success'
                    });

                    allRequests.splice(
                        allRequests.indexOf(allRequests.find(x => x.registrationId == request.registrationId)
                        ), 1);

                    $scope.requests.splice(
                        $scope.requests.indexOf($scope.requests.find(x => x.registrationId == request.registrationId)
                        ), 1);
                    refreshTable();
                });
            };

            $scope.navigateToGroups = roleCheckerService.switchGroup;

            $timeout(initialize);
        }
    ]);

