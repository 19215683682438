import { IController, IFilterService, ILocationService, IScope, IQService, IPromise } from 'angular';
import { AppDataService } from '@/services/app-data-service';
import { ILoadingBar } from '@/models';
import { ftSettingsService, FortyTwoLoginService } from '@/FortyTwoFramework';
import { app, appModule, ng, ft } from '@/definitions';
import moment from 'moment';
import { ChildCare } from '@/data-models';
import { IModalService } from 'angular-ui-bootstrap';
import { Color } from '@/HelperClasses';
import swal from 'bootstrap-sweetalert';
import { RoleChecker } from '@/role-checker';
import { Dictionary } from '@/dictionary';
import { PresencesStatus } from '@/enums';

import TransportChildrenResponse = ChildCare.Definitions.Transport.Responses.TransportChildrenResponse;
import TransportRide = ChildCare.Definitions.Transport.Models.TransportRide;
import GroupModel = ChildCare.Definitions.Account.GroupModel;
import UserModel = ChildCare.Definitions.Account.UserModel;
import TransportVehicle = ChildCare.Definitions.Transport.Models.TransportVehicle;
import Employee = ChildCare.Definitions.Locations.Models.Employee;
import TransportChild = ChildCare.Definitions.Transport.Models.TransportChild;
import { TransportTableRow } from '@/travel-planner/transport-table-row';
import { getChildStatus } from '@/travel-planner/get-child-status';

interface Scope extends IScope {
    isLoading: boolean;
    groups: GroupModel[];
    group: GroupModel;
    locations: ChildCare.Definitions.Transport.Responses.TransportLocation[];
    currentLocationId: string;
    isCluster: Boolean;
    user: UserModel;
    isReadOnly: boolean;
    filteredTableData: TransportTableRow[];
    allTableData: TransportTableRow[];
    tableSorting: any;
    model: {
        isDropOff: boolean;
        pickedDate: moment.Moment;
        toggledRows: TransportTableRow[];
        onCapacity: boolean;
    };

    navigateToGroups(): void;
    today(): void;
    displayDatePicker(): void;
    previousDay(): void;
    hexToRgba: (hex, transparency) => string;
    nextDay(): void;
    toggled(row: TransportTableRow): boolean;
    toggleRow(row: TransportTableRow): void;
    getRide(schoolId: string): boolean;
    getVehicleColor(ride: TransportRide): string;
    getContrastColor(bgColor: string): void;
    openModal(): void;
}

class TravelPlannerController implements IController {
    private vehicles: TransportVehicle[];
    private rides: Dictionary<TransportRide>;
    private employees: Employee[];
    private children: Dictionary<TransportChild>;
    private locations: ChildCare.Definitions.Transport.Responses.TransportLocation[];

    private $scope: Scope;
    private $q: IQService;
    private dataService: AppDataService;
    private loadingBar: ILoadingBar;
    private settingsService: ftSettingsService;
    private $location: ILocationService;
    private ngTableParams: any;
    private $filter: IFilterService;
    private $uibModal: IModalService;
    private loginService: FortyTwoLoginService;
    private dateTimeDialog: any;
    private roleCheckerService: RoleChecker;
    private $translate;

    static $inject = ['$scope', '$q', 'AppDataService', 'LoadingBar', 'ftSettingService', '$location', 'ngTableParams', '$filter', '$uibModal', 'ftLoginService', 'mdcDateTimeDialog', RoleChecker.serviceName, '$translate'];

    constructor($scope: Scope, $q: IQService, dataService: AppDataService, loadingBar: ILoadingBar, settingsService: ftSettingsService, $location: ILocationService, ngTableParams: any, $filter: IFilterService, $uibModal: IModalService, loginService: FortyTwoLoginService, dateTimeDialog: any, roleCheckerService: RoleChecker, $translate: any) {
        this.$scope = $scope;
        this.$q = $q;
        this.dataService = dataService;
        this.loadingBar = loadingBar;
        this.settingsService = settingsService;
        this.$location = $location;
        this.ngTableParams = ngTableParams;
        this.$filter = $filter;
        this.$uibModal = $uibModal;
        this.loginService = loginService;
        this.dateTimeDialog = dateTimeDialog;
        this.roleCheckerService = roleCheckerService;
        this.$translate = $translate;

        this.loadScopeWatches();
        this.initializeScreen();
        this.loadScopeFunctions();
    }

    private loadScopeWatches() {
        this.$scope.$watch('isLoading', (load: boolean) => {
            this.loadingBar.show = load;
        });

        this.$scope.$watch('model.pickedDate', () => {
            this.loadData();
            this.$location.path('/app/travel-planner').search({
                date: this.$scope.model.pickedDate.format('DD-MM-YYYY')
            });
        },
            true
        );

        this.$scope.$watch('model.isDropOff', () => {
            this.clearSelection();
            this.applyFilter();
            this.refreshTable();
        });

        this.$scope.$watch('currentLocationId', () => {
            if (this.$scope.currentLocationId) {
                return this.fetchLocation(this.$scope.currentLocationId);
            }
        });
    }

    private applyFilter() {
        this.$scope.filteredTableData = this.$scope.allTableData.filter(x => x.isDropOff === this.$scope.model.isDropOff);
    }

    private initializeScreen() {
        this.$scope.allTableData = [];
        this.$scope.filteredTableData = [];
        this.$scope.group = null;
        this.$scope.groups = [];
        this.$scope.model = {
            isDropOff: true,
            onCapacity: true,
            pickedDate: this.$location.search().date ? moment(this.$location.search().date, 'DD-MM-YYYY') : moment(),
            toggledRows: []
        };

        this.$scope.tableSorting = new this.ngTableParams({ count: 10 }, {
            total: this.$scope.filteredTableData.length,
            getData: ($defer, params) => {
                const orderedData = params.sorting() ? this.$filter('orderBy')(this.$scope.filteredTableData, params.orderBy()) : this.$scope.filteredTableData;
                $defer.resolve(orderedData);
            }
        });
    }

    private refreshTable() {
        this.$scope.tableSorting.reload();
    }

    private fetchLocation(locationId: string): IPromise<void> {
        this.$scope.isLoading = true;
        this.$scope.currentLocationId = locationId;
        this.$scope.isCluster = this.locations.some(x => x.clusterId === locationId);

        const ridesTask = this.dataService.getTransportRides(locationId, this.$scope.model.pickedDate);
        const employeesTask = this.dataService.getEmployeesForLocation(locationId, this.$scope.model.pickedDate);
        const childrenTask = this.dataService.getTransportChildren(locationId, this.$scope.model.pickedDate);

        return this.$q.all([ridesTask, employeesTask, childrenTask]).then(responses => {
            this.rides = Dictionary.toDictionary(responses[0].rides, x => x.rideId);
            this.employees = responses[1].employees;
            this.children = Dictionary.toDictionary(responses[2].children, x => x.childId);

            this.clearSelection();
            this.$scope.allTableData = this.createTableData(responses[2]);
            this.applyFilter();
            this.refreshTable();
        })
            .catch(error => console.log(error))
            .finally(() => this.$scope.isLoading = false);
    }

    private createTableData(response: TransportChildrenResponse): TransportTableRow[] {
        const tableData: TransportTableRow[] = [];

        const schools = Dictionary.toDictionary(response.schools, x => x.schoolId);

        for (const time of response.times) {
            const fromLocation = this.locations.find(x => x.id === time.locationId);

            for (const timeSchool of time.schools) {

                const school = schools.get(timeSchool.schoolId);
                const availableChildren = timeSchool.childIds.map(x => this.children.get(x));

                for (const rideId of timeSchool.rides) {
                    const ride = this.rides.get(rideId);

                    const selectedChildren = ride.children.map(x => this.children.get(x)).filter(x => x != null);

                    tableData.push({
                        ride: ride,
                        time: time,
                        location: fromLocation,
                        school: school,
                        isDropOff: time.isDropOff,
                        selectedChildren: selectedChildren,
                        numberOfChildren: selectedChildren.filter(x => getChildStatus([time.time], x) === PresencesStatus.Expected).length,
                        availableChildren: [...availableChildren]
                    });
                }

                if (timeSchool.childIds && timeSchool.childIds.length > 0) {
                    const row = {
                        time: time,
                        school: school,
                        location: fromLocation,
                        isDropOff: time.isDropOff,
                        numberOfChildren: availableChildren.filter(x => getChildStatus([time.time], x) === PresencesStatus.Expected).length,
                        availableChildren: [...availableChildren],
                    } as TransportTableRow;

                    if (row.numberOfChildren > 0) {
                        tableData.push(row);
                    }
                }
            }
        }

        return tableData;
    }

    private loadData() {
        this.$scope.isLoading = true;

        const userTask = this.loginService.getCurrentUserAsync();
        const locationsTask = this.dataService.getTransportLocations(this.$scope.model.pickedDate);
        const groupsTask = this.dataService.getGroupsAsync();
        const vehiclesTask = this.dataService.getTransportVehicles(this.$scope.model.pickedDate);

        return this.$q.all([userTask, locationsTask, groupsTask, vehiclesTask]).then(responses => {
            this.$scope.user = responses[0];
            this.locations = responses[1].locations;
            this.$scope.locations = this.locations.filter(x => x.clusterId == null || x.id == null); // These are selectable
            this.$scope.groups = responses[2].groups;
            this.vehicles = responses[3].vehicles;

            this.$scope.group = this.$scope.groups.find(x => x.id === this.settingsService.get(app.settings.group.selected));

            let locationId = this.$scope.currentLocationId;
            if (!locationId) {
                locationId = this.$scope.locations[0].clusterId ?? this.$scope.locations[0].id;
            }

            this.$scope.isReadOnly = !RoleChecker.hasPlannerRole(this.$scope.user);

            return this.fetchLocation(locationId);
        }).finally(() => this.$scope.isLoading = false);
    }

    private today() {
        this.$scope.model.pickedDate = moment();
    }

    private previousDay() {
        this.$scope.model.pickedDate.subtract(1, 'day');
    }

    private nextDay() {
        this.$scope.model.pickedDate.add(1, 'day');
    }

    private displayDatePicker() {
        this.dateTimeDialog
            .show({
                currentDate: this.$scope.model.pickedDate,
                time: false,
                autoOk: true,
                disableParentScroll: true
            })
            .then(date => {
                this.$scope.model.pickedDate = moment(date);
            });
    }

    private toggleRow(row: TransportTableRow) {
        const index = this.$scope.model.toggledRows.findIndex(x => x === row);
        if (index >= 0) {
            this.$scope.model.toggledRows.splice(index, 1);
        } else {
            this.$scope.model.toggledRows.push(row);
        }
    }

    private toggled(row: TransportTableRow) {
        return this.$scope.model.toggledRows.find(x => x === row) !== undefined;
    }

    private getVehicleColor(ride: TransportRide) {
        if (ride === undefined) {
            return;
        }

        return this.vehicles.find(x => x.vehicleId === ride.vehicleId).color;
    }

    private hexToRgba(hex, transparency) {
        return Color.hexToRgba(hex, transparency);
    }

    private getContrastColor(bgColor: string) {
        return Color.contrast(Color.hexToRgba(bgColor));
    }

    private editRide() {
        if (this.vehicles.length === 0) {
            swal({
                title: this.$translate.instant('MESSAGES.ERROR-TITLE'),
                text: this.$translate.instant('TRANSPORT.ERRORS.NO-VEHICLES'),
                timer: 3000,
                type: 'error'
            });
            return;
        }

        if (this.employees.length === 0) {
            swal({
                title: this.$translate.instant('MESSAGES.ERROR-TITLE'),
                text: this.$translate.instant('TRANSPORT.ERRORS.NO-EMPLOYEES'),
                timer: 3000,
                type: 'error'
            });
            return;
        }

        const date = this.$scope.model.pickedDate.clone().startOf('day');

        this.$uibModal.open({
            animation: true,
            templateUrl: 'partials/travel/edit-ride.html?v=' + ft.randomNumber,
            controller: 'EditRideController',
            backdrop: true,
            size: 'md',
            keyboard: true,
            resolve: {
                sourceRows: () => ng.copy(this.$scope.model.toggledRows),
                date: () => date.clone(),
                vehicles: () => ng.copy(this.vehicles),
                employees: () => ng.copy(this.employees),
                onCapacity: () => ng.copy(this.$scope.model.onCapacity),
            }
        }).result.then(async () => {
            swal({
                title: 'Opgeslagen',
                text: 'Rit is opgeslagen',
                timer: 3000,
                type: 'success'
            });

            // Probably refresh table & data here
            this.loadData().then(() => {
                this.clearSelection();
                this.refreshTable();
            });
        });
    }

    private clearSelection(): void {
        this.$scope.model.toggledRows = [];
    }

    private loadScopeFunctions() {
        this.$scope.navigateToGroups = this.roleCheckerService.switchGroup;
        this.$scope.today = this.today.bind(this);
        this.$scope.previousDay = this.previousDay.bind(this);
        this.$scope.nextDay = this.nextDay.bind(this);
        this.$scope.displayDatePicker = this.displayDatePicker.bind(this);
        this.$scope.toggleRow = this.toggleRow.bind(this);
        this.$scope.toggled = this.toggled.bind(this);
        this.$scope.getVehicleColor = this.getVehicleColor.bind(this);
        this.$scope.hexToRgba = this.hexToRgba.bind(this);
        this.$scope.getContrastColor = this.getContrastColor.bind(this);

        this.$scope.openModal = this.editRide.bind(this);
    }
}

appModule.controller('kpTravelPlannerController', TravelPlannerController);
