import { appModule, ft } from '@/definitions';
import { IScope, IPromise } from 'angular';
import { AppDataService } from '@/services/app-data-service';
import { IStateParamsService } from 'angular-ui-router';
import { IModalService } from 'angular-ui-bootstrap';
import { RoleChecker } from '@/role-checker';
import { Child, School, FamilyMember, GenderLabel, ChildType } from '@/child-factory';
import moment from 'moment';
import * as ng from 'angular';
import { ChildCare } from '@/data-models';
import { Gender } from '@/enums';

import ChildLabel = ChildCare.Definitions.GroupApp.ChildLabel;
import ChildLabelType = ChildCare.Definitions.GroupApp.ChildLabelType;
type Label = ChildLabel | ChildLabelType;

type PresencesChildInfoMedical = ChildCare.Definitions.GroupApp.PresencesChildInfoMedical;
import PresencesUpdateChildInfoCommand = ChildCare.Definitions.GroupApp.PresencesUpdateChildInfoCommand;
import PresencesUpdateMedicalInfoCommand = ChildCare.Definitions.GroupApp.PresencesUpdateMedicalInfoCommand;

enum EditMode {
    none = 'none',
    medical = 'medical',
    personal = 'personal'
}

interface Form {
    personalInformation?: PresencesUpdateChildInfoCommand;
    medicalInformation?: PresencesUpdateMedicalInfoCommand;
}

enum Action {
    absent = 'absent',
    sick = 'sick',
    incidental = 'incidental',
    swap = 'swap',
    move = 'move',
    studyday = 'studyday',
}

type Mentor = ChildCare.Definitions.SelectionModel<string> & { isInactive: boolean };

interface Scope extends IScope {
    index: number;
    randomNumber: number;
    isLoading: boolean;
    canRequestBeforeContract: boolean;
    form: Form;
    mentors: Mentor[];
    schools: School[];
    genders: GenderLabel[];
    child: Child;
    familyMembers: FamilyMember[];
    responsibleParent: FamilyMember;

    editMode: EditMode;
    enterEditMode(editMode: EditMode): void;
    isPersonEditMode: () => boolean;
    isMedicalEditMode: () => boolean;
    leaveEditMode: () => void;
    save: () => void;

    openParentModal: (parent: any) => void;
    avatarUpload: (type: string, user: any) => void;
    openActionModal: (action: Action) => void;

    labelsState: LabelChanges;
    deleteLabel: (label: Label) => void;
    addLabel: (label: Label) => void;

    isEmpty: (id: string) => boolean;
    isMentorActive: (id: string) => boolean;
}

class LabelChanges {
    public all: Label[] = [];
    public existing: Label[] = [];

    public added: string[] = [];
    public removed: string[] = [];

    public currentLabels: Label[] = [];
    public addableLabels: Label[] = [];

    public setAll(all: Label[]) {
        this.all = all;
        this.update();
    }

    public setExisting(existing: Label[]) {
        this.existing = existing;
        this.update();
    }

    public add(labelId: string): void {
        this.removeFrom(this.removed, labelId);
        if (this.existing.every(x => x.labelId !== labelId)) {
            this.added.push(labelId);
        }

        this.update();
    }

    public remove(labelId: string): void {
        this.removeFrom(this.added, labelId);

        if (this.existing.some(x => x.labelId === labelId)) {
            this.removed.push(labelId);
        }

        this.update();
    }

    private removeFrom(items: string[], item: string): void {
        const index = items.indexOf(item);

        if (index >= 0) {
            items.splice(index);
        }
    }

    private update() {
        this.currentLabels = [
            ...this.existing.filter(x => !this.removed.includes(x.labelId)),
            ...this.added.map(x => this.all.find(y => y.labelId === x))
        ];

        this.addableLabels = this.all.filter(x => this.currentLabels.every(y => y.labelId !== x.labelId));
    }

    public accept() {
        this.existing = [...this.currentLabels];
        this.clear();
        this.update();
    }

    public clear(): void {
        this.added = [];
        this.removed = [];
        this.currentLabels = [];
        this.addableLabels = [];
    }
}

appModule.controller('kpChildDetailController',
    ['$scope', '$q', 'AppDataService', '$stateParams', '$uibModal', 'LoadingBar', 'RoleCheckerService',
        function ($scope: Scope, $q: ng.IQService, appDataService: AppDataService, $stateParams: IStateParamsService, $uibModal: IModalService, LoadingBar, roleCheckerService: RoleChecker) {
            const childId: string = $stateParams.id;
            let initialized = false;
            $scope.randomNumber = ft.randomNumber;


            $scope.$watch('isLoading', function (newVal) {
                LoadingBar.show = newVal;
            });

            $scope.index = 0;
            $scope.isLoading = false;

            $scope.form = {};

            $scope.mentors = [];
            $scope.schools = [];
            $scope.genders = [new GenderLabel(Gender.Female), new GenderLabel(Gender.Male), new GenderLabel(Gender.Unknown)];
            $scope.labelsState = new LabelChanges();
            $scope.editMode = EditMode.none;

            const initialize = (): void => {
                if (initialized) {
                    return;
                }

                fetchData();
                initialized = true;
            };

            $scope.deleteLabel = label => $scope.labelsState.remove(label.labelId);
            $scope.addLabel = label => $scope.labelsState.add(label.labelId);

            const fetchData = (): void => {
                $scope.isLoading = true;

                const childInfoTask = appDataService.getChildInfo(childId).then((result) => {

                    $scope.canRequestBeforeContract = result.canRequestBeforeContract;
                    $scope.child = new Child(result.child, ChildType.PresencesChildInfoChild);
                    $scope.child.medicalInfo = result.medical;

                    $scope.familyMembers = result.family.map(x => new FamilyMember(x));
                    const responsible = $scope.familyMembers.find(x => x.isResponsible);

                    if (responsible != null) {
                        $scope.responsibleParent = responsible.clone();
                        $scope.responsibleParent.phoneNumber = result.child.emergencyNumber;
                    }

                    $scope.schools = result.school.allSchools.map(x => new School(x));
                    $scope.mentors = result.child.allMentors.map(x => ({ ...x, isInactive: false }) as Mentor);
                    if (result.child.mentor != null && $scope.mentors.every(x => x.id !== result.child.mentor.id)) {
                        $scope.mentors.push({...result.child.mentor, isInactive: true });
                    }

                    $scope.child.setSchool($scope.schools.find(x => x.id == result.school.schoolId));
                });

                const childLabelsTask = appDataService.getChildLabels(childId).then(x => $scope.labelsState.setExisting(x.labels));
                const allLabelsTask = appDataService.getAllChildLabels().then(x => $scope.labelsState.setAll(x.labels));

                $q.all([childInfoTask, childLabelsTask, allLabelsTask]).finally(() => $scope.isLoading = false);
            };

            $scope.isEmpty = (id) => id == null || id === '00000000-0000-0000-0000-000000000000';
            $scope.isMentorActive = (id) => !($scope.mentors.find(x => x.id === id)?.isInactive ?? true);

            $scope.avatarUpload = (type, user) => {
                const instance = $uibModal.open({
                    animation: true,
                    templateUrl: 'partials/avatar/upload.html',
                    controller: 'kpAvatarUploadModalController',
                    size: 'sm',
                    backdrop: true,
                    keyboard: true,
                    resolve: {
                        type: () => ng.copy(type),
                        name: () => ng.copy(typeof user.fullName === 'function' ? user.fullName() : user.fullName),
                        id: () => ng.copy(user.id),
                    }
                });

                instance.result.then((result) => {
                    if (result === 'saved') {
                        fetchData();
                    } else {
                        alert('Something went wrong!?');
                    }
                });
            };

            $scope.isPersonEditMode = () => $scope.editMode === EditMode.personal && !$scope.isLoading;
            $scope.isMedicalEditMode = () => $scope.editMode === EditMode.medical && !$scope.isLoading;

            $scope.enterEditMode = function (editMode: EditMode): void {
                switch (editMode) {
                    case EditMode.medical:
                        $scope.form.medicalInformation = {} as PresencesUpdateMedicalInfoCommand;
                        copyChildToMedical($scope.child, $scope.form.medicalInformation);
                        break;
                    case EditMode.personal:
                        $scope.form.personalInformation = {} as PresencesUpdateChildInfoCommand;
                        copyChildToPersonal($scope.child, $scope.form.personalInformation);
                        break;
                }

                $scope.editMode = editMode;
            };

            $scope.leaveEditMode = () => {
                $scope.editMode = EditMode.none;
            };

            $scope.openParentModal = function (parent) {
                $uibModal.open({
                    animation: true,
                    templateUrl: 'partials/parent/modal.html?v=' + ft.randomNumber,
                    controller: 'kpParentModalController',
                    size: 'lg',
                    backdrop: true,
                    keyboard: true,
                    resolve: {
                        parentId: function () {
                            return parent.id;
                        }
                    }
                });
            };

            $scope.save = () => {
                $scope.isLoading = true;
                let task: IPromise<void>;

                switch ($scope.editMode) {
                    case EditMode.medical:
                        task = saveMedicalInfo();
                        break;
                    case EditMode.personal:
                        task = savePersonalInformation();
                        break;
                }

                task.finally(() => {
                    $scope.isLoading = false;
                    $scope.leaveEditMode();
                });
            };

            function saveMedicalInfo(): IPromise<void> {
                const command = $scope.form.medicalInformation;

                return appDataService
                    .saveMedicalInfo({ ...command, childId })
                    .then(() => copyMedicalToChild(command, $scope.child));
            }

            function savePersonalInformation(): IPromise<void> {
                const command = $scope.form.personalInformation;

                const addLabelTasks = $scope.labelsState.added.map(x => appDataService.addChildLabel(childId, x));
                const removeLabelTasks = $scope.labelsState.removed.map(x => appDataService.removeChildLabel(childId, x));

                // Convert javascript Date (with timezone info) to absolute string
                command.birthDate = moment(command.birthDate).format('DD-MM-YYYY');
                const saveChildInfoTask = appDataService.saveChildInfo(command);

                return $q
                    .all([saveChildInfoTask, ...addLabelTasks, ...removeLabelTasks])
                    .then(() => {
                        $scope.labelsState.accept();
                        copyPersonalToChild(command, $scope.child);
                    });
            }

            function copyPersonalToChild(command: PresencesUpdateChildInfoCommand, child: Child): void {
                const { firstName, lastNamePrefix, lastName, birthDate, gender, mentorId, schoolId, remarks } = command;

                child.firstName = firstName;
                child.lastNamePrefix = lastNamePrefix;
                child.lastName = lastName;
                child.birthDate = moment(birthDate, 'DD-MM-YYYY');
                child.gender = new GenderLabel(gender);
                child.mentor = $scope.mentors.find(x => x.id == mentorId);
                child.school = $scope.schools.find(x => x.id == schoolId);
                child.remarks = remarks;
            }

            function copyChildToPersonal(child: Child, command: PresencesUpdateChildInfoCommand): void {
                const { id, firstName, lastNamePrefix, lastName, birthDate, gender, mentor, school, remarks } = child;

                command.childId = id;
                command.firstName = firstName;
                command.lastNamePrefix = lastNamePrefix;
                command.lastName = lastName;
                command.birthDate = birthDate.format('DD-MM-YYYY');
                command.gender = gender.key as Gender;
                command.mentorId = mentor != null ? mentor.id : '00000000-0000-0000-0000-000000000000';
                command.schoolId = school != null ? school.id : '00000000-0000-0000-0000-000000000000';
                command.remarks = remarks;
            }

            function copyChildToMedical(child: Child, command: PresencesUpdateMedicalInfoCommand): void {
                if (child.medicalInfo == null) {
                    return;
                }
                const { doctorName, doctorPhone, remarks } = child.medicalInfo as PresencesChildInfoMedical;

                command.doctorName = doctorName;
                command.doctorPhone = doctorPhone;
                command.remarks = remarks;
            }

            function copyMedicalToChild(form: PresencesUpdateMedicalInfoCommand, child: Child): void {

                child.medicalInfo = { ...form } as PresencesChildInfoMedical;
            }

            $scope.openActionModal = function (action) {
                const capitalizedAction = action.charAt(0).toUpperCase() + action.slice(1);

                let size = 'medium';
                if (action === Action.studyday || action === Action.incidental || action === Action.sick) {
                    size = 'large';
                }

                $uibModal.open({
                    animation: true,
                    templateUrl: 'partials/child/actions/' + action + 'Modal.html?v=' + ft.randomNumber,
                    controller: 'kpAction' + capitalizedAction + 'ModalController',
                    size: size,
                    backdrop: true,
                    keyboard: true,
                    resolve: {
                        child: function () {
                            return $scope.child;
                        },
                        childId: function () {
                            return childId;
                        }
                    }
                });
            };

            roleCheckerService.checkLogin().then(initialize);
        }
    ]
);
