import { Form } from '@/HelperClasses';
import { appModule } from '@/definitions';
import { AppDataService } from '@/services/app-data-service';
import { IScope, ITimeoutService } from 'angular';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { ftSettingsService } from '@/FortyTwoFramework';
import moment from 'moment';
import { ChildCare } from '@/data-models';

import GroupModel = ChildCare.Definitions.Account.GroupModel;
import RequestIncidentalDayTimes = ChildCare.Definitions.GroupApp.RequestIncidentalDayTimes;

declare module '@/data-models' {    
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace ChildCare.Definitions.GroupApp {
        interface RequestIncidentalDayTimes {
            valid: boolean;
        }
    }
}

interface ILocalScope extends IScope {
    isLoading: boolean;
    groups: GroupModel[];
    timesEditable: boolean;
    availableTimes: RequestIncidentalDayTimes[];
    firstForm: {
        date: Date;
        group: string;
        remarks: string;
        valid: boolean;
    };
    secondForm: {
        times: RequestIncidentalDayTimes[];
        valid: boolean;
        useCreditHours: boolean;
    };
    close: () => void;
    previous: () => void;
    save: () => void;
}

appModule.controller('kpActionIncidentalModalController',
    ['$scope', '$uibModalInstance', 'AppDataService', 'childId', 'LoadingBar', '$timeout', 'ftSettingService',
        function ($scope: ILocalScope, $uibModalInstance: IModalInstanceService, DataService: AppDataService, childId: string, LoadingBar, $timeout: ITimeoutService, ftSettingService: ftSettingsService) {
            var initialized = false;
            $scope.isLoading = false;

        $scope.groups = [];

        $scope.timesEditable = false;
        $scope.availableTimes = null;

        $scope.firstForm = {
            date: null,
            group: null,
            remarks: null,
            valid: null
        };

        $scope.secondForm = {
            times: null,
            valid: null,
            useCreditHours: false
        };

        $scope.$watch('isLoading', function(newVal) {
            LoadingBar.show = newVal;
        });

        var initialize = function() {
            if (initialized) {
                return;
            }

            $scope.isLoading = true;
            DataService.getGroupsAsync()
                .then(function(res) {
                    $scope.groups = res.groups;
                    $scope.firstForm.group = ftSettingService.get(window.appSettings.group.selected);
                })
                .finally(function() {
                    $scope.isLoading = false;
                });
        };

        $scope.close = function() {
            $uibModalInstance.dismiss();
        };

        $scope.previous = function() {
            $scope.firstForm.valid = null;
            $scope.secondForm.valid = null;
            $scope.secondForm.times = null;
        };

        $scope.save = function() {
            if ($scope.firstForm.valid) {
                $scope.secondForm.valid = Form.formNullCheck($scope.secondForm, ['valid']);
            }

            $scope.firstForm.valid = Form.formNullCheck($scope.firstForm, ['valid', 'remarks']);

            if ($scope.firstForm.valid && $scope.secondForm.valid) {
                for (const item of $scope.secondForm.times) {
                    if (item.startTime.length === 5) {
                        item.startTime += ':00';
                    }

                    if (item.endTime.length === 5) {
                        item.endTime += ':00';
                    }

                    if (
                        item.startTime > item.endTime ||
                        item.startTime < item.windowStartTime ||
                        item.endTime > item.windowEndTime
                    ) {
                        item.startTime = item.startTime.substring(0, 5);
                        item.endTime = item.endTime.substring(0, 5);

                        item.valid = false;
                        $scope.secondForm.valid = false;
                    }
                }

                $scope.isLoading = true;
                DataService.requestIncidentalDay(
                    childId,
                    $scope.firstForm.remarks,
                    moment($scope.firstForm.date).format('YYYY-MM-DD'),
                    $scope.firstForm.group,
                    $scope.secondForm.useCreditHours,
                    $scope.secondForm.times
                ).finally(function() {
                    $scope.isLoading = false;
                    $uibModalInstance.dismiss();
                });
            }

            if ($scope.firstForm.valid && !$scope.isLoading && $scope.secondForm.valid === null) {
                $scope.isLoading = true;

                DataService.getPossibleIncidentalDays(childId, moment($scope.firstForm.date).format('YYYY-MM-DD')).then(
                    function(result) {
                        $scope.timesEditable = result.areTimesEditable;
                        $scope.availableTimes = result.times;

                        if ($scope.availableTimes.length === 0) {
                            $scope.firstForm.valid = false;
                        }

                        $scope.isLoading = false;
                    }
                );
            }
        };

        $timeout(() => initialize());
    }
]);
