import swal from 'bootstrap-sweetalert';
import { appModule } from '@/definitions';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { IController, IScope } from 'angular';
import { AppDataService } from '@/services/app-data-service';
import { ChildCare } from '@/data-models';
import { FortyTwoRootScopeService } from '@/FortyTwoFramework';

import WriteLocation = ChildCare.Definitions.GroupApp.WriteLocation;
import WriteLocationCareType = ChildCare.Definitions.GroupApp.WriteLocationCareType;

interface Message {
    title?: string;
    content?: string;
}

interface ILocalScope extends IScope {
    audiences: WriteLocation[];
    caretypes: WriteLocationCareType[];
    selectedAudiences: WriteLocation[];
    selectedCareTypes: WriteLocationCareType[];
    message: Message;
    isLoading: boolean;

    cancel(): void;
    hasValidTitle(): boolean;
    hasValidMessage(): boolean;
    isValid(): boolean;
    isAllSelected(): boolean;
    isAudienceSelected(audience: WriteLocation): boolean;
    isCareTypeSelected(caretype: WriteLocationCareType): boolean;
    toggleSelectAllAudiences(): void;
    toggleSelectAudience(audience: WriteLocation): void;
    toggleSelectCareType(caretype: WriteLocationCareType): void;
    selectAudience(index: number): void;
    saveConversation(): void;
    getAvatarInitials(fullName: string): string;
}

class LocationConversationModalController implements IController {

    private $rootScope: FortyTwoRootScopeService;
    private $scope: ILocalScope;
    private $uibModalInstance: IModalInstanceService;
    private dataService: AppDataService;
    private $translate;

    public static $inject = ['$rootScope', '$scope', '$uibModalInstance', 'audiences', 'caretypes', 'AppDataService', '$translate', 'getAvatarInitials'];

    public constructor($rootScope: FortyTwoRootScopeService, $scope: ILocalScope, $uibModalInstance: IModalInstanceService, audiences: WriteLocation[], caretypes: WriteLocationCareType[], dataService: AppDataService, $translate, getAvatarInitials: (fullName: string) => string) {

        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.dataService = dataService;
        this.$translate = $translate;

        this.$scope.audiences = audiences;
        this.$scope.caretypes = caretypes;

        this.$scope.getAvatarInitials = getAvatarInitials;
        this.$scope.cancel = this.cancel.bind(this);
        this.$scope.toggleSelectAllAudiences = this.toggleSelectAllAudiences.bind(this);
        this.$scope.toggleSelectAudience = this.toggleSelectAudience.bind(this);
        this.$scope.toggleSelectCareType = this.toggleSelectCareType.bind(this);
        this.$scope.saveConversation = this.saveConversation.bind(this);
        this.$scope.hasValidTitle = this.hasValidTitle.bind(this);
        this.$scope.hasValidMessage = this.hasValidMessage.bind(this);
        this.$scope.isValid = this.isValid.bind(this);
        this.$scope.isAllSelected = this.isAllSelected.bind(this);
        this.$scope.isAudienceSelected = this.isAudienceSelected.bind(this);
        this.$scope.isCareTypeSelected = this.isCareTypeSelected.bind(this);

        this.initializeForm();
    }

    private initializeForm() {
        this.$scope.selectedAudiences = [...this.$scope.audiences];
        this.$scope.selectedCareTypes = [...this.$scope.caretypes];
        this.$scope.message = {
            title: '',
            content: ''
        };
        this.$scope.isLoading = false;
    }

    private cancel() {
        this.$uibModalInstance.dismiss();
    }

    private toggleSelectAllAudiences() {
        if (this.isAllSelected()) {
            this.$scope.selectedAudiences = [];
        } else {
            this.$scope.selectedAudiences = [...this.$scope.audiences];
        }
    }

    private isAllSelected(): boolean {
        return this.$scope.selectedAudiences.length === this.$scope.audiences.length;
    }

    private isAudienceSelected(audience: WriteLocation): boolean {
        return this.$scope.selectedAudiences.some(x => x.id === audience.id);
    }

    private isCareTypeSelected(caretype: WriteLocationCareType): boolean {
        return this.$scope.selectedCareTypes.some(x => x.careType === caretype.careType);
    }

    private toggleSelectAudience(audience: WriteLocation) {
        this.toggleItem(audience, this.$scope.selectedAudiences, (x, y) => x.id === y.id);
    }

    private toggleSelectCareType(caretype: WriteLocationCareType): void {
        this.toggleItem(caretype, this.$scope.selectedCareTypes, (x, y) => x.careType === y.careType);
    }

    private toggleItem<T>(target: T, collection: T[], isEqual: (item1: T, item2: T) => boolean): void {
        const index = collection.findIndex(x => isEqual(x, target));
        if (index > -1) {
            collection.splice(index, 1);
        } else {
            collection.push(target);
        }
    }

    private hasValidTitle(): boolean {
        return this.$scope.message.title != null && this.$scope.message.title.length > 0;
    }

    private hasValidMessage(): boolean {
        return this.$scope.message.content != null && this.$scope.message.content.length > 0;

    }

    private isValid(): boolean {
        if (!this.hasValidTitle() || !this.hasValidMessage()) {
            return false;
        }

        if (this.$scope.selectedCareTypes.length === 0) {
            return false;
        }

        if (this.$scope.selectedAudiences.length === 0) {
            return false;
        }

        return true;
    }

    private dismiss() {
        this.initializeForm();
        this.$uibModalInstance.dismiss();
    }

    private saveConversation() {
        if (this.$scope.isLoading || !this.isValid()) {
            return;
        }

        this.$scope.isLoading = true;

        const selectedAudienceIds = this.$scope.selectedAudiences.map(x => x.id);
        const selectedCareTypeNames = this.$scope.selectedCareTypes.map(x => x.careType);

        this.dataService.saveLocationConversation(this.$scope.message, selectedAudienceIds, selectedCareTypeNames)
            .then(() => this.handleSaveConversation(), () => this.handleSaveConversationError())
            .finally(() => this.$scope.isLoading = false);
    }

    private handleSaveConversation() {
        this.$rootScope.$emit('newPostMade', false);
        this.dismiss();

        swal({
            title: this.$translate.instant('MESSAGES.CONVERSATION-SAVE-OK-TITLE'),
            text: this.$translate.instant('MESSAGES.CONVERSATION-SAVE-OK-MESSAGE'),
            timer: 2000,
            type: 'success'
        });
    }

    private handleSaveConversationError() {
        this.dismiss();

        swal({
            title: this.$translate.instant('MESSAGES.CONVERSATION-SAVE-ERROR-TITLE'),
            text: this.$translate.instant('MESSAGES.CONVERSATION-SAVE-ERROR-MESSAGE'),
            timer: 2500,
            type: 'error'
        });
    }
}

appModule.controller('kpLocationConversationModalController', LocationConversationModalController);