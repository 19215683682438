appModule.filter('trusted', ['$sce', function ($sce) {
    return function (url) {
        return $sce.trustAsResourceUrl(url);
    };
}]);

appModule.filter('capitalize', function () {
    return function (input) {
        return (input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
    };
});