import { appModule } from '@/definitions';
import moment, { Moment, Duration } from 'moment';
import { IScope } from 'angular';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { AppDataService } from '@/services/app-data-service';
import { ILoadingBar } from '@/models';
import { ChildCare } from '@/data-models';

type Command = ChildCare.Planning.Commands.ReportAbsent.Command;


function formatTime(duration: moment.Duration): string {
    if (duration == null) {
        return null;
    }

    return moment.utc(duration.as('milliseconds')).format('HH:mm');
}

interface Scope extends IScope {
    isLoading: boolean;
    valid: boolean;
    save: () => void;
    canSave: () => boolean;
    displayDialog: (field: 'startTime' | 'endTime') => void;
    formatTime: (duration: Duration) => string;
    close: () => void;
    form: {
        startDate: Date;
        startTime: Duration;
        endDate: Date;
        endTime: Duration;
        reason: string;
    };
    validation: { [key: string]: boolean };
    isValid: (field: string) => boolean;
}

class AbsentModalController {
    public static $inject = ['$scope', '$uibModalInstance', 'AppDataService', 'childId', 'LoadingBar', 'mdcDateTimeDialog'];

    private $scope: Scope;
    private $uibModalInstance: IModalInstanceService;
    private dataService: AppDataService;
    private childId: string;
    private dateTimeDialog: any;

    public constructor($scope: Scope, $uibModalInstance: IModalInstanceService, dataService: AppDataService, childId: string, loadingBar: ILoadingBar, dateTimeDialog: any) {
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.dataService = dataService;
        this.childId = childId;
        this.dateTimeDialog = dateTimeDialog;

        $scope.$watch('isLoading', (isLoading: boolean) => loadingBar.show = isLoading);

        this.$scope.form = {
            startDate: moment().toDate(),
            startTime: moment.duration(moment().format('HH:mm')),
            endDate: moment().add(1, 'day').toDate(),
            endTime: moment.duration('23:59'),
            reason: null
        };

        this.$scope.formatTime = formatTime;
        this.$scope.close = this.close.bind(this);
        this.$scope.displayDialog = this.displayDialog.bind(this);
        this.$scope.save = this.save.bind(this);
        this.$scope.canSave = this.canSave.bind(this);
        this.$scope.isValid = this.isValid.bind(this);
    }


    private isValid(field: string): boolean {
        if (this.$scope.validation == null) {
            return true;
        }

        return this.$scope.validation[field] ?? true;
    }


    private close(): void {
        this.$uibModalInstance.dismiss();
    }

    private displayDialog(field: 'startTime' | 'endTime') {
        this.dateTimeDialog.show({
            date: false,
            currentDate: this.$scope.form[field],
            minuteSteps: 1,
            autoOk: true,
            disableParentScroll: true
        }).then((date: Date) => {
            this.$scope.form[field] = moment.duration(moment(date).format('HH:mm'));
        });
    }

    private async save() {
        this.$scope.validation = this.validate(this.$scope.form);
        if (Object.keys(this.$scope.validation).length > 0) {
            return;
        }

        const { startDate, startTime, endDate, endTime, reason } = this.$scope.form;

        this.$scope.isLoading = true;

        const start = moment(startDate).startOf('day').add(moment.duration(startTime));
        let end: moment.Moment = undefined;
        if (endDate != null) {
            end = moment(endDate).startOf('day');
            if (endTime != null) {
                end.add(moment.duration(endTime));
            }
        }

        const command: Command = {
            childIds: [this.childId],
            start: start.format('YYYY-MM-DDTHH:mm:ss'),
            end: end?.format('YYYY-MM-DDTHH:mm:ss'),
            type: 'afwezig' as ChildCare.Afwezigtype,
            remarks: reason
        };

        try {
            await this.dataService.reportChildAbsentAsync(command);
        } finally {
            this.$scope.isLoading = false;
            this.$uibModalInstance.dismiss();
            this.$scope.$apply();
        }
    }

    private validateDate(date?: Date): Moment {
        if (date == null) {
            return null;
        }

        const converted = moment(date);
        if (!converted.isValid()) {
            return null;
        }

        return converted;
    }

    private canSave(): boolean {
        this.$scope.validation = this.validate(this.$scope.form);
        return Object.keys(this.$scope.validation).length === 0;
    }

    private validate(data: Scope['form']): { [key: string]: boolean } {
        const result = { };

        const fromDate = this.validateDate(data.startDate);
        if (fromDate == null) {
            result['startDate'] = false;
        }

        const tillDate = this.validateDate(data.endDate);
        if (tillDate == null) {
            result['endDate'] = false;
        }

        const fromTime = data.startTime;
        if (fromTime == null) {
            result['startTime'] = false;
        }

        const tillTime = data.endTime;
        if (tillTime == null) {
            result['endTime'] = false;
        }

        if (fromDate == null || tillDate == null) {
            return result;
        }

        if (fromDate.isAfter(tillDate)) {
            result['endDate'] = false;
        }

        if (fromTime == null || tillTime == null) {
            return result;
        }

        const from = fromDate.clone().add(fromTime);
        const till = tillDate.clone().add(tillTime);

        if (from.isSameOrAfter(till)) {
            result['endTime'] = false;
        }

        return result;
    }
}

appModule.controller('kpActionAbsentModalController', AbsentModalController);
