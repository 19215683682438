import { appModule } from '@/definitions';
import moment from 'moment';
import { Child, ChildType } from '@/child-factory';
import { AppDataService } from '@/services/app-data-service';
import { RequestItem } from '@/controllers/requests';
import { IModalServiceInstance } from 'angular-ui-bootstrap';
import { IScope } from 'angular';
import swal from 'bootstrap-sweetalert';

import TypeOfRegistration = ChildCare.Definitions.Common.TypeOfRegistration;
import { ChildCare } from '@/data-models';


interface ILocalScope extends IScope {
    isLoading: boolean;
    saving: boolean;
    data:
    {
         remarks: any;
    };
    requestDetails:
    {
        child: Child;
        otherChildren: Child[];
        date: moment.Moment;
        description: string;
        groupCanApprove: boolean;
        parentRemarks: string;
        registrationId: string;
        typeOfRegistration: TypeOfRegistration;
        numberOfEmployeesCurrent: number;
        numberOfEmployeesRequested: number;
    };
    submitRequest: (action: any) => void;
    close: () => void;
}

appModule.controller('kpRequestModalController',
    ['$scope', '$uibModalInstance', 'request', 'groupId', 'AppDataService', '$timeout', '$translate',
        function ($scope: ILocalScope, $uibModalInstance: IModalServiceInstance, request: RequestItem, groupId: string, DataService: AppDataService, $timeout, $translate) {

            $scope.isLoading = false;
            $scope.saving = false;

            $scope.data = {
                remarks: null
            };

            var initialize = function () {
                $scope.isLoading = true;
                DataService.getRequestDetails(groupId, request.registrationId, request.registrationType)
                    .then(function (response) {
                        $scope.requestDetails = {
                            child: new Child(response.child, ChildType.GroupRequestsApprovalChild),
                            otherChildren: response.otherChildren.map(x => new Child(x, ChildType.GroupRequestsApprovalChild)),
                            date: moment(response.date),
                            description: response.description,
                            groupCanApprove: response.groupCanApprove,
                            parentRemarks: response.parentRemarks,
                            registrationId: response.registrationId,
                            typeOfRegistration: response.typeOfRegistration,
                            numberOfEmployeesCurrent: response.numberOfEmployeesCurrent,
                            numberOfEmployeesRequested: response.numberOfEmployeesRequested
                        };
                    }).finally(function () {
                        $scope.isLoading = false;
                    });
            };

            $scope.submitRequest = function (action) {
                $scope.isLoading = true;
                $scope.saving = true;

                var details = $scope.requestDetails;
                DataService.sendRequestAction(
                    groupId, details.registrationId, details.typeOfRegistration,
                    action ? 'approved' : 'rejected', $scope.data.remarks
                ).then(function () {
                    $uibModalInstance.close(request);
                }).catch(function () {
                    swal({
                        title: $translate.instant('ERRORS.SAVE-FAILED.TITLE'),
                        text: $translate.instant('ERRORS.SAVE-FAILED.MESSAGE'),
                        timer: 3000,
                        type: 'error'
                    });
                }).finally(function () {
                    $scope.saving = false;
                    $scope.isLoading = false;
                    $uibModalInstance.dismiss();
                });
            };

            $scope.close = function () {
                $uibModalInstance.dismiss();
            };

            $timeout(initialize());
        }
    ]
);
