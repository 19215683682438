import { appModule } from '@/definitions';
import moment from 'moment';
import { AppDataService } from '@/services/app-data-service';
import { ChildCare } from '@/data-models';
import { IScope } from 'angular';

type Command = ChildCare.Planning.Commands.ReportAbsent.Command;

interface Scope extends IScope {
    valid: boolean;
    isLoading: boolean;
    ends: 'today' | 'tomorrow' | 'unknown';
    reason: string;
    close: () => any;
    save: () => void;

}

appModule.controller('kpActionSickModalController',
    ['$scope', '$uibModalInstance', 'AppDataService', 'childId', 'LoadingBar',
        function ($scope: Scope, $uibModalInstance, dataService: AppDataService, childId, LoadingBar) {
            $scope.valid = true;
            $scope.isLoading = false;
            $scope.ends = 'today';

            $scope.$watch('isLoading', (newVal: Boolean) => LoadingBar.show = newVal);
            $scope.close = () => $uibModalInstance.dismiss();

            $scope.save = () => {
                let end: moment.Moment;

                switch ($scope.ends) {
                    case 'today':
                        end = moment().endOf('day');
                        break;
                    case 'tomorrow':
                        end = moment().add(1, 'day').endOf('day');
                        break;
                    case 'unknown':
                        end = undefined;
                        break;
                }

                const command: Command = {
                    childIds: [childId],
                    start: moment().format('YYYY-MM-DDTHH:mm:ss'),
                    end: end?.format('YYYY-MM-DDTHH:mm:ss'),
                    type: 'ziek' as ChildCare.Afwezigtype,
                    remarks: $scope.reason
                };

                if ($scope.valid) {
                    $scope.isLoading = true;
                    dataService.reportChildSickAsync(command)
                        .then()
                        .finally(function () {
                            $scope.isLoading = false;
                            $uibModalInstance.dismiss();
                        });
                }
            };
        }
    ]);
