import { IHttpService, IPromise, IQService } from 'angular';
import { ChildCare } from '@/data-models';

import AppConfigurationModel = ChildCare.Definitions.Configuration.AppConfigurationModel;
import FeatureModel = ChildCare.Definitions.Configuration.FeatureModel;
import moment from 'moment';
import {EnvironmentMode} from '@/environment';


export enum Feature {
    TravelPlanner = 'travelplanner',
    ActivityPlanner = 'activityplanner',
    LocationMessages = 'locationmessages',
    Stories = 'stories'
}

export class FeatureService {
    public static $inject: string[] = ['$http', '$q'];

    private $http: IHttpService;
    private $q: IQService;

    private lastFetch?: moment.Moment;
    private features: FeatureModel[];

    constructor($http: IHttpService, $q: IQService) {
        this.$http = $http;
        this.$q = $q;
    }

    private getFeatures(): IPromise<FeatureModel[]> {
        if (this.lastFetch != null && this.lastFetch.diff(moment(), 'minutes') < 10) {
            return this.$q.resolve(this.features);
        }

        console.log('cache expired, refetching features');

        return this.$http
                .get<AppConfigurationModel>(`${window.appModule.baseApiUrl}/configuration`)
                .then(response => {
                    if (response.status !== 200) {
                        return [];
                    }

                    this.features = response.data.features;

                    console.log('enabled features for this instance');
                    for (const enabledFeature of this.features) {
                        console.log(`feature: ${enabledFeature.name} code: ${enabledFeature.code}`);
                    }

                    this.lastFetch = moment();
                    return this.features;
                })
                .catch(() => []);
    }

    public isEnabled(feature: Feature): IPromise<boolean> {
        if (env.mode === EnvironmentMode.development) {
            return this.$q.resolve(true);
        }

        return this.getFeatures().then(features => features.some(x => x.code === feature));
    }
}

window.appModule.service('FeatureService', FeatureService);
