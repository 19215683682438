appModule.provider('ftApp', [
    function() {
        this.$get = ['ftLoginService', function(ftLoginService) {
            return new service(ftLoginService);
        }];

        const service = function(ftLoginService) {
            this.initializeAppContent = function() {
                return ftLoginService.isLoggedInAsync();
            };
        };
    }
]);
