export class UrlHelper {
    public static isUrlAbsolute(url: string): boolean {
        return new RegExp('^(?:[a-z]+:)?//', 'i').test(url);
    }

    public static getTenant(): string {
        const tenantRegex = /(?:(?:groep|backoffice|ouder|vervoer)-)?([a-z-0-9]+)\.kindplanner.nl/i;
        const match = tenantRegex.exec(location.hostname);
        if (match != null) {
            return match[1];
        }

        return null;
    }

    public static createAbsoluteMediaUrl(mediaServiceUrl: string, relativeUrl: string): string {
        const tenantPart = mediaServiceUrl.lastIndexOf('/');

        mediaServiceUrl = mediaServiceUrl.substring(0, tenantPart);

        return `${mediaServiceUrl}/${relativeUrl}`;
    }
}
