import { AlbumsController } from './albums';
import { appModule, ft } from '@/definitions';
import ng, { IController } from 'angular';
import { ChildCare } from '@/data-models';

class LocationManagerAlbumsController extends AlbumsController implements IController {
    private locations: ChildCare.Definitions.GroupApp.WriteLocation[];
    private caretypes: ChildCare.Definitions.GroupApp.WriteLocationCareType[];

    public async $onInit() {
        const response = await this.dataService.getWriteLocations();

        this.locations = response.locations;
        this.caretypes = response.careTypes;
    }

    protected openUploadMediaModal() {
        const instance = this.$uibModal.open({
            animation: true,
            templateUrl: 'partials/locationmanager/album-model.html?v=' + ft.randomNumber,
            size: 'lg',
            backdrop: true,
            keyboard: true,
            controller: 'kpLocationManagerAlbumsModalController',
            resolve: {
                locations: () => ng.copy(this.locations),
                caretypes: () => ng.copy(this.caretypes)
            },
        });

        instance.result.then(() => {
            this.refreshMediaAlbums();
        });
    }
}

appModule.controller('kpLocationManagerAlbumsController', LocationManagerAlbumsController);