import {appModule, ng} from '@/definitions';
import { Child, ChildType } from '@/child-factory';
import {ChildCare} from '@/data-models';

import GroupChildrenOnLocationResponse = ChildCare.Definitions.GroupApp.GroupChildrenOnLocationResponse;
import { AppDataService } from '@/services/app-data-service';
import { ftSettingsService } from '@/FortyTwoFramework';
import { IScope } from 'angular';
import { RoleChecker } from '@/role-checker';

interface ILocalScope extends IScope {
    allChildren: {};    group: any;
    selectedGroup: any;
    groups: any[];
    isLoading: boolean;
    resetSelectedGroup: () => void;
    goToChildDetail: (child: any) => void;
    navigateToGroups: () => void;
}

appModule.controller('kpEmergencyListController',
    ['$scope', '$timeout', 'AppDataService', '$state', 'LoadingBar', 'ftSettingService', RoleChecker.serviceName,
        function ($scope: ILocalScope, $timeout, DataService: AppDataService, $state, LoadingBar, SettingService: ftSettingsService, roleCheckerService: RoleChecker) {
            $scope.allChildren = {};
            $scope.group = null;
            $scope.selectedGroup = null;
            $scope.groups = [];
            $scope.isLoading = false;

            let initialized = false;

            $scope.$watch('isLoading', (isLoading) => {
                LoadingBar.show = isLoading;
            });

            $scope.$watch('selectedGroup', (selectedGroup) => {
                let promise;
                if (selectedGroup) {
                    promise = DataService.getEmergencyList($scope.selectedGroup);
                } else {
                    promise = DataService.getEmergencyList(null);
                }

                $scope.isLoading = true;
                promise.then((result: GroupChildrenOnLocationResponse) => {
                    $scope.allChildren = {};

                    for (const child of result.children) {
                        const firstLetter = child.childName.charAt(0);

                        if (!Array.isArray($scope.allChildren[firstLetter])) {
                            $scope.allChildren[firstLetter] = [];
                        }

                        $scope.allChildren[firstLetter].push(new Child(child, ChildType.GroupChildOnLocation));
                    }
                }).finally(() => {
                    $scope.isLoading = false;
                });
            });

            const initialize = function () {
                if (initialized) {
                    return;
                }

                $scope.isLoading = true;
                DataService.getGroupsAsync().then((result) => {
                    $scope.groups = ng.copy(result.groups);
                    $scope.group = result.groups.find(x => x.id === SettingService.get(window.appSettings.group.selected));
                    $scope.selectedGroup = SettingService.get(window.appSettings.group.selected);
                }).finally(() => {
                    $scope.isLoading = false;
                });

                initialized = true;
            };

            $scope.resetSelectedGroup = () => {
                $scope.selectedGroup = SettingService.get(window.appSettings.group.selected);
            };

            $scope.goToChildDetail = function (child) {
                $state.go('app.child', {id: child.id});
            };

            $scope.navigateToGroups = roleCheckerService.switchGroup;

            $timeout(initialize);

        }
    ]);
