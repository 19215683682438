import { ChildCare } from '@/data-models';
import moment from 'moment';

import { Gender } from '@/enums';

import PresencesStatus = ChildCare.Definitions.GroupApp.PresencesStatus;
import PresenceType = ChildCare.Definitions.GroupApp.PresenceType;
import PresencesEventPlanning = ChildCare.Definitions.GroupApp.PresencesEventPlanning;
import PresencesMedical = ChildCare.Definitions.GroupApp.PresencesMedical;
import PresencesChildInfoSchoolSelection = ChildCare.Definitions.GroupApp.PresencesChildInfoSchoolSelection;
import PresencesChild = ChildCare.Definitions.GroupApp.PresencesChild;
import PresencesLabel = ChildCare.Definitions.GroupApp.PresencesLabel;
import PresencesEvent = ChildCare.Definitions.GroupApp.PresencesEvent;
import PresencesDiaryEvent = ChildCare.Definitions.GroupApp.PresencesDiaryEvent;
import PresencesTimeInterval = ChildCare.Definitions.GroupApp.PresencesTimeInterval;

import DiaryEntry = ChildCare.Definitions.Family.DiaryEntry;

import PresencesChildInfoChild = ChildCare.Definitions.GroupApp.PresencesChildInfoChild;
import PresencesChildInfoMedical = ChildCare.Definitions.GroupApp.PresencesChildInfoMedical;
import PresencesChildInfoFamilyMember = ChildCare.Definitions.GroupApp.PresencesChildInfoFamilyMember;

import GroupChildOnLocation = ChildCare.Definitions.GroupApp.GroupChildOnLocation;
import GroupRequestsApprovalChild = ChildCare.Definitions.GroupApp.GroupRequestsApprovalChild;

export class GenderLabel {
    public key: string;
    public name: string;

    constructor(private gender: Gender) {
        this.key = gender.toString();
        this.name = GenderLabel.getName(gender);
    }

    public getIcon(): string {
        switch (this.gender) {
            case Gender.Unknown:
                return 'genderless';
            case Gender.Male:
                return 'mars';
            case Gender.Female:
                return 'venus';
        }
    }

    public static getName(gender: Gender): string {
        switch (gender) {
            case Gender.Unknown:
                return 'Onbekend';
            case Gender.Male:
                return 'Man';
            case Gender.Female:
                return 'Vrouw';
        }
    }
}

export class DiaryType {
    constructor(public id: string, public name: string, public color: string, public icon: string) {}
}

export class Diary {
    constructor(public id: string, public type: DiaryType, public text: string, public time: string) {}
}

export class Label {
    constructor(public title: string, public text: string, public color: string) {}
}

export class School {
    public googleApiKey = 'AIzaSyB1KCqC2iOubGbKy3kJadFHqtg_ffLcF-M';
    public id: string;
    public name: string;
    public street: string;
    public postalCode: string;
    public city: string;

    constructor(school: PresencesChildInfoSchoolSelection) {
        this.id = school.id;
        this.name = school.name;
        this.street = school.street;
        this.postalCode = school.postalCode;
        this.city = school.city;
    }

    public getFullAddress(): string {
        return `${this.street}, ${this.postalCode} ${this.city}`;
    }

    public getGoogleMapsLink(): string {
        return `https://www.google.com/maps/embed/v1/place?key=${this.googleApiKey}&q=${this.getFullAddress().replace(
            ' ',
            '+'
        )}`;
    }
}

export class FamilyMember {
    public id: string;
    public firstName: string;
    public lastNamePrefix: string;
    public lastName: string;
    public profilePicture: string;
    public phoneNumber: string;
    public isResponsible: boolean;
    public birthDate: moment.Moment;

    private member: PresencesChildInfoFamilyMember;

    constructor(member: PresencesChildInfoFamilyMember) {
        this.member = member;

        this.id = member.parentId;
        this.firstName = member.firstName;
        this.lastNamePrefix = member.lastNamePrefix;
        this.lastName = member.lastName;
        this.profilePicture = member.profilePicture;
        this.phoneNumber = member.phoneNumber;
        this.isResponsible = member.isResponsible;
        this.birthDate = moment(member.birthDate);
    }

    public clone(): FamilyMember {
        return new FamilyMember(this.member);
    }

    public getAvatarInitials() {
        let matches = this.fullName().match(/\b(\w)/g);
        if (matches.length < 2) {
            return this.fullName()
                .slice(0, 2)
                .toUpperCase();
        }

        matches = matches.slice(0, 2);
        return matches.join('').toUpperCase();
    }

    public fullName() {
        let fullName = this.firstName;

        if (this.lastNamePrefix != null && this.lastNamePrefix !== '') {
            fullName += ' ' + this.lastNamePrefix;
        }

        if (this.lastName != null && this.lastName !== '') {
            fullName += ' ' + this.lastName;
        }

        return fullName;
    }
}

export class Event {
    constructor(
        public presenceType: PresenceType,
        public startTime: moment.Duration,
        public endTime: moment.Duration,
        public remarks: string,
        public planning: PresencesEventPlanning
    ) {}

    public formatLabel(): string {
        const startLabel = moment.utc(this.startTime.asMilliseconds()).format('HH:mm');
        const endLabel = moment.utc(this.endTime.asMilliseconds()).format('HH:mm');

        const label = `${startLabel} - ${endLabel}`;
        let status = null;
        if (this.presenceType === 'absent') {
            status = 'Afwezig';
        } else if (this.presenceType === 'beforeContract') {
            status = 'Wendag';
        } else if (this.presenceType === 'sick') {
            status = 'Ziek';
        } else if (this.presenceType === 'swapDayTraded' || this.presenceType === 'swapDay') {
            status = 'Geruild';
        } else if (this.presenceType === 'incidental') {
            status = this.remarks;
        }

        return status == null ? label : label + ' | ' + status;
    }

    public getCssClass(): string {
        return (
            'type-' +
            this.presenceType
                .replace(/([A-Z])/g, '-$1')
                .trim()
                .toLowerCase()
        );
    }
}

export class DiaryEvent {
    constructor(
        public childId: string,
        public diaryEntryType: string,
        public startTime: moment.Duration,
        public endTime: moment.Duration,
        public colorCode: string,
        public quantity: number,
        public diaryEntryId: string,
        public diaryEntryTypeId: string,
        public diaryScheduleId: string
    ) {}

    public formatLabel(): string {
        const label = `${this.diaryEntryType} (${this.quantity})`;
        return label;
    }

    public getCssClass(): string {
        return (
            '.diary-schedule'
        );
    }
}

type ChildTypes =
      ChildCare.Definitions.Child
    | ChildCare.Definitions.ExtendedChild
    | PresencesChild
    | PresencesChildInfoChild
    | GroupChildOnLocation
    | GroupRequestsApprovalChild;

export enum ChildType {
    PresencesChild,
    PresencesChildInfoChild,
    GroupChildOnLocation,
    GroupRequestsApprovalChild
}

export class Child {
    public id: string;

    public firstName: string;
    public lastNamePrefix?: string;
    public lastName?: string;

    public fullName: string;

    public profilePicture: string;
    public birthDate?: moment.Moment;
    public events: Event[] = [];
    public diaryEvents: DiaryEvent[] = [];
    public presences: PresencesTimeInterval[] = [];
    public expectedTimes: PresencesTimeInterval[] = [];
    public labels: Label[] = [];
    public status?: PresencesStatus;
    public arrivedAt?: moment.Moment;
    public gender: GenderLabel;
    public medical?: PresencesMedical;
    public medicalInfo?: PresencesChildInfoMedical;
    public remarks?: string;
    public mentor?: ChildCare.Definitions.SelectionModel<string>;
    public school?: School;
    public hasReminder?: boolean;
    public groupNames?: string;
    public diaries: Diary[] = [];
    public times: string;

    public childType?: ChildType;

    constructor(child: ChildTypes, childType?: ChildType) {
        this.childType = childType;

        this.id = child.childId;
        this.firstName = child.childName;
        this.gender = new GenderLabel(child.gender);
        if (child.picture != null && child.picture.url != null) {
            this.profilePicture = child.picture.url;
        }

        if (Child.isExtendedChild(child)) {
            this.firstName = child.firstName;
            this.lastNamePrefix = child.lastNamePrefix;
            this.lastName = child.lastName;
            this.birthDate = moment(child.birthDate);
        }

        if (Child.isFromChildInfo(child, childType)) {
            this.status = child.status;
            this.mentor = child.mentor;
            this.remarks = child.remarks;
        } else if (Child.isFromGroupChild(child, childType)) {
            this.groupNames = child.groupNames;
        } else if (Child.isFromApprovalChild(child, childType)) {
            this.times = child.times;
        } else if (Child.isFromPresencesChild(child, childType)) {
            this.status = child.status;
            this.medical = child.medical;
            this.hasReminder = child.hasReminder;
            this.presences = child.presences;
            this.expectedTimes = child.expectedTimes;
        }

        this.setFullName();
    }

    private static isExtendedChild(child: ChildTypes): child is ChildCare.Definitions.ExtendedChild {
        return child['firstName'] != null;
    }

    private static isFromChildInfo(child: ChildTypes, childType?: ChildType): child is PresencesChildInfoChild {
        return childType != null && childType === ChildType.PresencesChildInfoChild;
    }

    private static isFromGroupChild(child: ChildTypes, childType?: ChildType): child is GroupChildOnLocation {
        return childType != null && childType === ChildType.GroupChildOnLocation;
    }

    private static isFromApprovalChild(child: ChildTypes, childType?: ChildType): child is GroupRequestsApprovalChild {
        return childType != null && childType === ChildType.GroupRequestsApprovalChild;
    }

    private static isFromPresencesChild(child: ChildTypes, childType?: ChildType): child is PresencesChild {
        return childType != null && childType === ChildType.PresencesChild;
    }

    public addDiary(diary: DiaryEntry) {
        let diaryType: DiaryType;
        if (diary.diaryEntryType != null) {
            diaryType = new DiaryType(
                diary.diaryEntryType.diaryEntryTypeId,
                diary.diaryEntryType.name,
                diary.diaryEntryType.color,
                diary.diaryEntryType.icon
            );
        }

        this.diaries.push(new Diary(diary.diaryEntryId, diaryType, diary.text, diary.time));
    }

    public addLabel(label: PresencesLabel) {
        this.labels.push(new Label(label.name, label.description, label.colorCode));
    }

    public addEvent(event: PresencesEvent) {
        const startTime = moment.duration(event.startTime);
        const endTime = moment.duration(event.endTime);

        this.events.push(new Event(event.presenceType, startTime, endTime, event.remarks, event.planning));
    }

    public addDiaryEvent(event: PresencesDiaryEvent) {
        const startTime = moment.duration(event.startTime);
        const endTime = moment.duration(event.endTime);

        this.diaryEvents.push(new DiaryEvent(this.id,
                                             event.diaryEntryType,
                                             startTime,
                                             endTime,
                                             event.colorCode,
                                             event.quantity,
                                             event.diaryEntryId,
                                             event.diaryEntryTypeId,
                                             event.diaryScheduleId)
                            );
    }

    public getCssClassForGender() {
        if (this.gender) {
            return (
                'gender-' +
                this.gender
                    .getIcon()
                    .replace(' ', '-')
                    .toLowerCase()
            );
        }
    }

    public cssClassForStatus() {
        if (this.status) {
            return (
                'status-' +
                this.status
                    .replace(/([A-Z])/g, '-$1')
                    .trim()
                    .toLowerCase()
            );
        }
    }

    public isBirthday() {
        if (this.birthDate) {
            return this.birthDate.format('MM/DD') === moment().format('MM/DD');
        }
    }

    public hasMedical() {
        return this.medical != null;
    }

    public hasMedicalInfo() {
        return this.medicalInfo != null && this.medicalInfo.remarks != null && this.medicalInfo.remarks != '';
    }

    public getAvatarInitials() {
        if (this.fullName == null) {
            return '';
        }

        let matches = this.fullName.match(/\b(\w)/g);
        if (matches.length < 2) {
            return this.fullName.slice(0, 2).toUpperCase();
        }

        matches = matches.slice(0, 2);
        return matches.join('').toUpperCase();
    }

    private setFullName(): void {
        this.fullName = this.firstName;
        if (this.lastNamePrefix && this.lastNamePrefix !== '') {
            this.fullName += ' ' + this.lastNamePrefix;
        }

        if (this.lastName && this.lastName !== '') {
            this.fullName += ' ' + this.lastName;
        }
    }

    public setMentor(mentor: ChildCare.Definitions.SelectionModel<string>) {
        this.mentor = mentor;
    }

    public setRemarks(remarks: string) {
        this.remarks = remarks;
    }

    public setSchool(school: School) {
        this.school = school;
    }

    public ageString(): string {
        if (this.birthDate == null) {
            return 'Onbekend';
        }

        const now = moment();
        const years = now.diff(this.birthDate, 'years');
        const months = now.diff(this.birthDate, 'months') % 12;

        let ageString = '';
        if (years > 0) {
            ageString += `${years} jaar`;
        }

        if (years > 0 && months > 0) {
            ageString += ', ';
        }

        if (months == 1) {
            ageString += '1 maand';
        } else if (months > 1) {
            ageString += `${months} maanden`;
        }

        return ageString;
    }
}
