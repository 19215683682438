import { appModule } from '@/definitions';
import { IScope, IDirectiveFactory, IAugmentedJQuery, IDirective, IAttributes } from 'angular';

export interface IFileChangeScope extends IScope {
    fileChanged: () => void;
}

export class FileChangeDirective implements IDirective {
    restrict = 'A';
    require = 'ngModel';
    scope = {
        fileChanged: '&onFileChanged'
    };

    link = (scope: IFileChangeScope, element: IAugmentedJQuery, attrs: IAttributes, ctrl: any) => {
        element.on('change', onChange);

        scope.$on('destroy', function() {
            element.off('change', onChange);
        });

        function onChange() {
            const newFileName = (element[0] as HTMLInputElement).files[0];
            ctrl.$setViewValue(newFileName);

            scope.$apply(() => {
                scope.fileChanged();
            });
        }
    };

    static factory(): IDirectiveFactory {
        return () => new FileChangeDirective();
    }
}

appModule.directive('fileChange', FileChangeDirective.factory());
