import { appModule } from '@/definitions';
import { Calendar, EventApi, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import momentPlugin, { toMoment } from '@fullcalendar/moment';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

import enLocale from '@fullcalendar/core/locales/en-gb';
import nlLocale from '@fullcalendar/core/locales/nl';

import { ITimeoutService, IScope, ICompileService, IAugmentedJQuery } from 'angular';

import template from '@public/partials/child/tabs/planning.html';
import { AppDataService } from '@/services/app-data-service';
import moment from 'moment';
import { IStateParamsService } from 'angular-ui-router';
import { EventNonDateInput, EventRenderingChoice } from '@fullcalendar/core/structs/event';

import '@public/css/calendar-child.less';

interface ICalendarScope extends IScope {
    showMonth: boolean;
    gotoPreviousMonth: () => void;
    gotoNextMonth: () => void;
    gotoToday: () => void;
}

appModule.controller('kpChildPlanningController',
    ['$element', '$scope', '$stateParams', '$compile', '$timeout', 'AppDataService', 'LoadingBar', '$translate',
        function ($element: IAugmentedJQuery, $scope: ICalendarScope, $stateParams: IStateParamsService, $compile: ICompileService, $timeout: ITimeoutService, dataService: AppDataService, loadingBar, $translate) {

            const childId: string = $stateParams.id;
            let calendar: Calendar;

            const getLocale = () => {
                var locale = (window.navigator as any).userLanguage || window.navigator.language;
                if (locale.toLowerCase() === 'nl-nl') {
                    locale = 'nl';
                }

                return locale;
            };

            const fetchEvents = (fetchInfo, successCallback, failureCallback) => {
                const startDate: moment.Moment = moment(fetchInfo.start);

                const startDay = startDate.date();
                const firstMonth = startDate.month();
                let year: number = startDate.year();

                let monthToFetch = firstMonth;
                // The first day in the calendar can be the previous month, in that case take the next month
                if (startDay !== 1) {
                    monthToFetch = (monthToFetch + 1) % 12;
                    if (monthToFetch < firstMonth) {
                        year = year + 1;
                    }
                }

                console.log(`Fetching month ${monthToFetch + 1}`);

                loadingBar.show = true;
                dataService.getPlanning(childId, monthToFetch + 1, year).then(function (result) {

                    const events: EventInput[] = [];
                    for (const week of result.weeks) {
                        for (const day of week.days) {

                            if (day.type === 'closed' || day.type === 'holiday') {
                                events.push(toBackgroundEvent(day));
                                events.push(toDayEvent(day));
                            }

                            for (const block of day.blocks) {
                                events.push(toEvent(block));
                            }
                        }
                    }

                    successCallback(events);

                }).finally(function () {
                    loadingBar.show = false;
                });
            };

            const toBackgroundEvent = (day): EventNonDateInput => {
                const start = moment.utc(day.date);

                const event = {
                    classNames: ['planning-day-backgound', day.type],
                    start: start.format('YYYY-MM-DD'),
                    end: start.format('YYYY-MM-DD'),
                    rendering: 'background' as EventRenderingChoice,
                    extendedProps: {
                        description: day.description,
                        date: start
                    }
                };

                return event;
            };

            const toDayEvent = (day): EventInput => {
                const start = moment.utc(day.date);

                const event = {
                    classNames: ['planning-day', day.type],
                    start: start.format('YYYY-MM-DD'),
                    end: start.format('YYYY-MM-DD'),
                    title: day.description
                };

                return event;
            };

            const toEvent = (block): EventInput => {

                const start = moment.utc(block.date).add(moment.duration(block.start));
                const end = moment.utc(block.date).add(moment.duration(block.end));

                const event = {
                    id: block.id,
                    title: createTitle(block),
                    classNames: ['planning-block', block.status, block.presence],
                    start: start.toISOString(),
                    end: end.toISOString(),
                    isAllDay: false
                };

                return event;
            };

            var createTitle = function (block) {
                const types = window.app.planning.types;
                const presenceTypes = window.app.planning.presence;

                if (block.type === types.absent) {
                    if (block.presence == presenceTypes.absent) {
                        return $translate.instant('PLANNING.ABSENT');
                    }
                    if (block.presence == presenceTypes.sick) {
                        return $translate.instant('PLANNING.SICK');
                    }
                    if (block.presence == presenceTypes.holiday) {
                        return $translate.instant('PLANNING.HOLIDAY');
                    }
                }

                if (block.type === types.registration) {
                    return block.description;
                }

                if (block.type === types.study) {
                    return $translate.instant('PLANNING.STUDY');
                }

                if (block.type === types.swap) {
                    return $translate.instant('PLANNING.SWAPPED');
                }

                if (block.type === types.incidental) {
                    return $translate.instant('PLANNING.INCIDENTAL');
                }
            };

            const initialize = () => {

                const root = $element.get(0);
                for (const element of $compile(template)($scope).get()) {
                    root.appendChild(element);
                }

                const contentElement = document.getElementById('calendar-content');
                const calenderElement = document.createElement('div');
                calenderElement.setAttribute('id', 'calendar-control');

                contentElement.appendChild(calenderElement);

                calendar = new Calendar(calenderElement, {
                    locales: [enLocale, nlLocale],
                    plugins: [dayGridPlugin, momentPlugin],
                    timeZone: 'UTC',
                    locale: getLocale(),
                    defaultView: 'dayGridMonth',
                    height: 600,
                    header: {
                        left: 'dayGridMonth,dayGridWeek',
                        center: 'title',
                        right: 'today prev,next'
                    },
                    events: fetchEvents,
                    eventTimeFormat: 'HH:mm',
                    displayEventEnd: true
                });

                calendar.render();
            };

            $timeout(() => initialize());
        }]);
