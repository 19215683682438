import { appModule } from '@/definitions';
import { RoleChecker } from '@/role-checker';
import { FortyTwoLoginService, ftSettingsService } from '@/FortyTwoFramework';
import { IScope, ITimeoutService } from 'angular';

interface Scope extends IScope {
    isLoading: boolean;
}

appModule.controller('kpAppInitializeController', ['$scope', '$timeout', 'ftSettingService', 'ftLoginService', 'RoleCheckerService',
    function($scope: Scope, $timeout: ITimeoutService, ftSettingService: ftSettingsService, ftLoginService: FortyTwoLoginService, roleCheckerService: RoleChecker) {
        let initialized = false;

        $scope.isLoading = false;

        const initialize = function() {
            if (initialized) {
                return;
            }

            ftLoginService.getCurrentUserAsync().then(user => {
                if (user.groups && user.groups.length === 1) {
                    ftSettingService.set(window.appSettings.group.selected, user.groups[0].id);
                } else {
                    ftSettingService.set(window.appSettings.group.selected, null);
                }

                return roleCheckerService.navigateToRoleStartPage();
            });

            initialized = true;
        };

        $timeout(initialize);
    }
]);
