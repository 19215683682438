import { appModule, frameworkSettings, frameworkEvents } from '@/definitions';
import { IStateService } from 'angular-ui-router';
import { ApplicationInsightsService } from '@/services/application-insights-service';

appModule.controller('ftAppController', ['$rootScope','$scope', '$state', '$stateParams', '$timeout', '$urlRouter', 'ftConfiguration', 'ftLoginService', 'ftSettingService', 'AppDataService', '$localStorage', '$sessionStorage', 'RoleCheckerService', 'mdcDefaultParams', 'ftConnection',
    function($rootScope, $scope, $state: IStateService, $stateParams, $timeout, $urlRouter, ftConfiguration, ftLoginService, ftSettingService, DataService, $localStorage, $sessionStorage, roleCheckerService, mdcDefaultParams, ftConnection) {
        mdcDefaultParams({ lang: 'nl', cancelText: 'Sluiten', todayText: 'Nu', okText: 'Ok' });
        var initialized = false;
        var splashTime = 1000;
        var time;
        var offDestroy, offLogin, offStateChangeSuccess;

        // Refresh the token upon startup and set an interval that runs the expire time - 10 seconds.
        // This is to fix an issue where calls could be done with invalid tokens
        ftLoginService.refreshLoginAsync().then(function() {
            startRefreshTokenInterval();
        });

        var startRefreshTokenInterval = function() {
            setInterval(function() {
                ftLoginService.refreshLoginAsync();
            }, getTokenRefreshSpeed());
        };

        var getTokenRefreshSpeed = function() {
            if ($localStorage.tokenRefreshSpeed) {
                return $localStorage.tokenRefreshSpeed;
            }

            // Get the expire date and subtract 10 seconds from it
            var expireDate = new Date($sessionStorage.token.expires);
            expireDate.setSeconds(expireDate.getSeconds() - 10);

            var now = new Date().toUTCString();

            // Get time difference in milliseconds
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            var refreshSpeed = new Date(expireDate.toUTCString()) - new Date(now);
            $localStorage.tokenRefreshSpeed = refreshSpeed;

            return refreshSpeed;
        };

        $scope.isLoading = false;
        $rootScope.showSplash = true;
        $scope.pageStyle = null;

        $scope.logout = function() {
            // remove everyone from station call
            ftLoginService.doLogout();
            $localStorage[window.appSettings.group.selected] = undefined;

            $state.go('outside-app.login');
        };

        var getData = function() {
            time = new Date().getTime();

            DataService.getConfigurationAsync()
                .then(results => {
                    $rootScope.configuration = results;
                    ftConfiguration.configure(results);

                    var interval = new Date().getTime() - time;

                    if (interval < 1000) {
                        $timeout(hideSplash, splashTime);
                    } else {
                        hideSplash();
                    }

                    initializeRoute();
                })
                .catch(() => {
                    hideSplash();
                    $timeout(() => ftConnection.check(), 500);
                })
                .finally(function() {
                    $scope.isLoading = true;
                });
        };

        var handleOnLogin = function(evt, obj) {
            roleCheckerService.checkLogin().then(() => {
                if (obj && obj.redirect) {
                    window.location.replace(obj.redirect);
                } else {
                    $state.go('app.initialize');
                }
            });
        };

        var handleStateChangeSuccess = function() {
            var current = $state.current;

            // only save current state after login (when in-app)
            if (current.name.indexOf('app') == 0) {
                ftSettingService.set(frameworkSettings.keys.currentState, [current.name, $stateParams]);
            }

            $scope.pageStyle = (current as any).pageStyle || null;
        };

        var handleOnDestroy = function() {
            if (offDestroy) {
                offDestroy();
            }
            if (offLogin) {
                offLogin();
            }
            if (offStateChangeSuccess) {
                offStateChangeSuccess();
            }
        };

        var initialize = function() {
            if (initialized) {
                return;
            }

            initializeEventHandlers();
            getData();

            initialized = true;
        };

        var initializeEventHandlers = function() {
            offStateChangeSuccess = $rootScope.$on('$stateChangeSuccess', handleStateChangeSuccess);
            offLogin = $rootScope.$on(frameworkEvents.authentication.login, handleOnLogin);
            offDestroy = $scope.$on('$destroy', handleOnDestroy);
        };

        var initializeRoute = function() {
            ftLoginService.isLoggedInAsync().then(
                () => {
                    navigateToLastState();
                },
                () => {
                    console.log('User is not logged in');
                    $localStorage[window.appSettings.group.selected] = undefined;

                    $state.go('outside-app.login');
                }
            );
        };

        var navigateToLastState = function() {
            var lastState = ftSettingService.get(frameworkSettings.keys.currentState);

            if (lastState) {
                try {
                    $state.go(lastState[0], lastState[1]);
                } catch (error) {
                    console.log(error);
                    console.log(lastState);
                    console.log('Failed to restore last state');
                    $state.go('app.presence');
                }
            } else if (window.location.href.indexOf('/app/') < 0) {
                $state.go('app.presence');
            } else {
                $urlRouter.sync();
            }
        };

        var hideSplash = function() {
            $rootScope.showSplash = false;
        };

        $timeout(initialize);
    }
]);

appModule.controller('kpHomeController', function() {});
