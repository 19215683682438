import {EnvironmentMode} from '@/environment';

if (env.mode !== EnvironmentMode.development) {
    window.addEventListener('load', async () => {
        if ('serviceWorker' in navigator) {
            const registration = await navigator.serviceWorker.register('/service-worker.js');

            // Check every hour
            setInterval(() => registration.update(), 60 * 60 * 1000);

            let refreshing = false;
            navigator.serviceWorker.addEventListener('controllerchange', function () {
                if (refreshing) {
                    return;
                }

                window.location.reload();
                refreshing = true;
            });
        }
    });
}