import { app, appModule } from '@/definitions';
import { IController, IScope } from 'angular';
import { AppDataService } from '@/services/app-data-service';
import { IModalServiceInstance } from 'angular-ui-bootstrap';
import { ILoadingBar } from '@/models';
import { ftSettingsService } from '@/FortyTwoFramework';
import moment from 'moment';
import { ChildCare } from '@/data-models';

import RequestBeforeContractCommandTimes = ChildCare.Definitions.GroupApp.RequestBeforeContractCommandTimes;

class GroupAndDateSelection {
    public date: Date;
    public groupId: string;
    public remarks: string;

    constructor(date: moment.Moment, groupId: string) {
        this.date = date.toDate();
        this.groupId = groupId;
    }

    public isValid(): boolean {
        if (!this.hasValidDate()) {
            return false;
        }

        if (this.groupId == null) {
            return false;
        }

        return true;
    }

    public hasValidDate(): boolean {
        return moment(this.date).isSameOrAfter(moment(), 'day');
    }
}

class TimeSelectionForm {
    times: RequestBeforeContractCommandTimes[];

    constructor() {
        this.times = [];
    }

    public reset() {
        this.times = [];
    }

    public isValid(): boolean {
        return true;
    }
}

interface ILocalScope extends IScope {
    isLoading: boolean;
    isSaving: boolean;
    timesEditable: boolean;
    groups: ChildCare.Definitions.Account.GroupModel[];
    availableTimes: any[];
    groupAndDateForm: GroupAndDateSelection;
    timeSelectionForm: TimeSelectionForm;

    formIndex: number;

    close(): void;
    previous(): void;
    next(): void;
    save(): void;
}

class BeforeContractModalController implements IController {
    static $inject = ['$scope', 'AppDataService', '$uibModalInstance', 'LoadingBar', 'ftSettingService', 'childId'];

    constructor(
        private $scope: ILocalScope,
        private dataService: AppDataService,
        private modalInstance: IModalServiceInstance,
        private loadingBar: ILoadingBar,
        private settingsService: ftSettingsService,
        private childId: string
    ) {
        this.initializeScope();
        this.loadData();
    }

    private loadData() {
        this.$scope.isLoading = true;

        return this.dataService
            .getGroupsAsync()
            .then(response => {
                this.$scope.groups = response.groups;
            })
            .finally(() => (this.$scope.isLoading = false));
    }

    private isLoadingChanged(newVal: boolean) {
        this.loadingBar.show = newVal;
    }

    private next() {
        this.$scope.formIndex = 1;
    }

    private previous() {
        this.$scope.formIndex = 0;
        this.$scope.timeSelectionForm.reset();
    }

    private close() {
        this.modalInstance.dismiss();
    }

    private save() {
        if (!this.$scope.groupAndDateForm.isValid() || !this.$scope.timeSelectionForm.isValid()) {
            return;
        }

        this.$scope.isSaving = true;

        this.dataService
            .requestBeforeContractDay(
                this.childId,
                this.$scope.groupAndDateForm.remarks,
                moment(this.$scope.groupAndDateForm.date),
                this.$scope.groupAndDateForm.groupId,
                this.$scope.timeSelectionForm.times
            )
            .finally(() => {
                this.$scope.isSaving = false;
                this.close();
            });
    }

    private selectedDateChanged(newDate: Date) {
        if (newDate == null) {
            this.$scope.availableTimes = [];
            this.$scope.timeSelectionForm.reset();
        }

        this.$scope.isLoading = true;

        this.dataService
            .getPossibleBeforeContractDays(this.childId, moment(newDate))
            .then(result => {
                this.$scope.timesEditable = result.areTimesEditable;
                this.$scope.availableTimes = result.times;
            })
            .finally(() => (this.$scope.isLoading = false));
    }

    private initializeScope() {
        const groupId = this.settingsService.get(app.settings.group.selected);

        this.$scope.groupAndDateForm = new GroupAndDateSelection(moment(), groupId);
        this.$scope.timeSelectionForm = new TimeSelectionForm();

        this.$scope.$watch('isLoading', this.isLoadingChanged.bind(this));
        this.$scope.$watch('groupAndDateForm.date', this.selectedDateChanged.bind(this));

        this.$scope.close = this.close.bind(this);
        this.$scope.next = this.next.bind(this);
        this.$scope.previous = this.previous.bind(this);
        this.$scope.save = this.save.bind(this);

        this.$scope.formIndex = 0;
    }
}

appModule.controller('kpActionBeforeContractModalController', BeforeContractModalController);
