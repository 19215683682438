import { IController, IScope, IPromise } from 'angular';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { appModule } from '@/definitions';
import { ChildCare } from '@/data-models';
import { Dictionary } from '@/dictionary';
import { AppDataService } from '@/services/app-data-service';

type AreaType = ChildCare.Definitions.Activity.AreaType;
type Activity = ChildCare.Definitions.Activity.Activity;

interface ILocalScope extends IScope {
    isLoading: boolean;
    selectionCompleted: boolean;
    currentActivities: Dictionary<Activity[]>;
    selectedActivities: Dictionary<string>;
    cancel: () => void;
    select: () => void;
    getActivitiesForLevel: (parentActivityId: string, level: number) => Activity[];
    getAreaName: (activities: Activity[]) => string;
}

export const CustomActivityId = 'ad338a88-11a2-408f-ad1f-aa48ded37419';

class SelectActivityController implements IController {
    static $inject = ['$uibModalInstance', '$scope', '$translate', 'AppDataService', 'locationId'];

    private activitiesPerLevel: Dictionary<Activity[]>;
    private areaTypes: Dictionary<AreaType>;
    private activityAreaTypeId: string;
    private $uibModalInstance: IModalInstanceService;
    private $scope: ILocalScope;
    private dataService: AppDataService;
    private locationId: string;
    private $translate;

    constructor($uibModalInstance: IModalInstanceService, $scope: ILocalScope, $translate, dataService: AppDataService, locationId: string) {

        this.$uibModalInstance = $uibModalInstance;
        this.$scope = $scope;
        this.$translate = $translate;
        this.dataService = dataService;
        this.locationId = locationId;

        this.$scope.$watch('selectedActivities', this.onSelectionChanged.bind(this), true);

        this.$scope.getActivitiesForLevel = this.getActivitiesForLevel.bind(this);
        this.$scope.getAreaName = this.getAreaName.bind(this);
        this.$scope.select = this.select.bind(this);
        this.$scope.cancel = this.cancel.bind(this);

        this.$scope.currentActivities = new Dictionary<Activity[]>();
        this.$scope.selectedActivities = new Dictionary<string>();

        this.initializeData();
    }

    private createActivityLevels(activities: Activity[]): Dictionary<Activity[]> {
        const levels = new Dictionary<Activity[]>();

        let parents: string[] = [];
        let current = -1;

        do {
            current++;
            const currentLevel = levels.add(current.toString(), []);
            const nextParents: string[] = [];

            for (const activity of activities.filter(x => current === 0 && x.parentActivityId == null || parents.includes(x.parentActivityId))) {
                currentLevel.push(activity);
                nextParents.push(activity.activityId);
            }

            parents = nextParents;
        } while (levels.get(current.toString()).length > 0);

        return levels;
    }

    private initializeData(): IPromise<void> {
        this.$scope.isLoading = true;

        return this.dataService.getExistingActivityTypes(this.locationId).then(response => {
            this.activitiesPerLevel = this.createActivityLevels(response.activities);
            this.areaTypes = Dictionary.toDictionary(response.areaTypes, x => x.areaTypeId);
            this.activityAreaTypeId = response.areaTypes.find(x => x.name === 'Activiteit')?.areaTypeId;
        }).finally(() => {
            this.$scope.isLoading = false;
        }).then(() => {
            this.onSelectionChanged(new Dictionary<string>(), new Dictionary<string>());
        });
    }

    private resetToHierarchicalState(current: Dictionary<string>, previous: Dictionary<string>) {
        const currentKeys = current.keys();
        const previousKeys = previous.keys();

        // If the length are not equal then something is added or removed
        // no need the check if some of the previous selections has changed
        if (currentKeys.length !== previousKeys.length) {
            return;
        }

        for (const level of currentKeys) {
            if (current[level] === previous[level]) {
                continue;
            }

            this.$scope.selectionCompleted = false;

            for (let otherLevel = parseInt(level) + 1; otherLevel <= currentKeys.length; otherLevel++) {
                current.remove(otherLevel);
                this.$scope.currentActivities.remove(otherLevel);
            }
        }
    }

    private onSelectionChanged(current: Dictionary<string>, previous: Dictionary<string>) {

        if (this.$scope.isLoading) {
            return;
        }

        const currentActivities = this.$scope.currentActivities;

        this.resetToHierarchicalState(current, previous);

        const currentLevel = current.length();
        const previousLevel = currentLevel - 1;
        const nextLevel = currentLevel + 1;
        const lastActivityId = current.get(previousLevel);

        const currentLevelActivities = this.getActivitiesForLevel(lastActivityId, currentLevel);

        currentActivities[currentLevel] = currentLevelActivities;

        const selectionCompleted = this.getActivitiy(this.$scope.selectedActivities[previousLevel])?.areaTypeId  === this.activityAreaTypeId;

        const shouldAddCustomActivity = !selectionCompleted && (
            currentLevelActivities.length === 0 ||
            currentLevelActivities.every(x => this.getActivitiesForLevel(x.activityId, nextLevel).length === 0)
        );

        if (shouldAddCustomActivity && lastActivityId !== CustomActivityId) {

            const parentActivity = this.activitiesPerLevel.get(previousLevel)?.find(x => x.activityId === lastActivityId);

            currentLevelActivities.push(this.createCustomActivity(parentActivity));
            if (currentLevelActivities.length === 1) {
                this.$scope.selectedActivities[currentLevel] = CustomActivityId;
            }
        }

        this.$scope.selectionCompleted = selectionCompleted;
    }

    private createCustomActivity(templateActivity: Activity): Activity {
        return {
            activityId: CustomActivityId,
            groupSize: templateActivity?.groupSize,
            name: this.$translate.instant('ACTIVITY.CUSTOM-ACTIVITY'),
            poster: undefined,
            color: templateActivity?.color,
            areaTypeId: this.activityAreaTypeId,
            parentActivityId: undefined,
            description: templateActivity?.description
        };
    }

    private getActivitiesForLevel(parentActivityId: string, level: number): Activity[] {
        return this.activitiesPerLevel.get(level).filter(x => x.parentActivityId === parentActivityId);
    }

    private getActivitiy(activityId: string): Activity {
        if (activityId === CustomActivityId) {
            return this.createCustomActivity({} as Activity);
        }

        for (const activities of this.activitiesPerLevel.values()) {
            const activity = activities.find(x => x.activityId === activityId);
            if (activity != null) {
                return activity;
            }
        }

        return null;
    }

    private getArea(activities: Activity[]) {
        if (activities == null || activities.length === 0) {
            return null;
        }

        const activity = activities[0];
        return this.areaTypes.get(activity.areaTypeId);
    }

    private getAreaName(activities: Activity[]) {
        const area = this.getArea(activities);

        if (area) {
            return area.name;
        }

        return '';
    }

    private select() {
        let activity: Activity;

        const selectedActivityId = this.$scope.selectedActivities.lastValue();

        if (selectedActivityId === CustomActivityId) {
            const parentActivityId = this.$scope.selectedActivities.at(this.$scope.selectedActivities.length() - 2).value;
            activity = this.findActivity(parentActivityId);

        } else {
            activity = this.findActivity(selectedActivityId);
        }

        this.$uibModalInstance.close(activity);
    }

    private findActivity(activityId: string) {
        return this.activitiesPerLevel
            .values()
            .reduce((acc, val) => acc.concat(val), [])
            .find(x => x.activityId === activityId);
    }

    private cancel() {
        this.$uibModalInstance.dismiss();
    }
}

appModule.controller('kpSelectActivityController', SelectActivityController);
