import { appModule } from '@/definitions';
import { Calendar, EventApi, EventInput, View } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import momentPlugin from '@fullcalendar/moment';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

import enLocale from '@fullcalendar/core/locales/en-gb';
import nlLocale from '@fullcalendar/core/locales/nl';

import { ITimeoutService, IScope, IQService, ICompileService } from 'angular';

import { AppDataService } from '@/services/app-data-service';
import moment from 'moment';

import { ftSettingsService } from '@/FortyTwoFramework';
import { ChildCare } from '@/data-models';

import GroupModel = ChildCare.Definitions.Account.GroupModel;
import GroupTimelineItem = ChildCare.Definitions.GroupApp.GroupTimelineItem;
import { Color } from '@/HelperClasses';
import { RoleChecker } from '@/role-checker';
import { IStateService } from 'angular-ui-router';

interface ICalendarScope extends IScope {
    group: GroupModel;
    selectedGroupId: string;
    onSelect: (calEvent: any, jsEvent: any, view: any) => void;
    navigateToGroups: () => void;
    isLoading: boolean;
}

appModule.controller('kpCalendarOverviewController',
    ['$scope', '$timeout', 'AppDataService', 'ftSettingService', '$q', '$compile', '$state', 'LoadingBar', RoleChecker.serviceName,
        function ($scope: ICalendarScope, $timeout: ITimeoutService, DataService: AppDataService, SettingService: ftSettingsService, $q: IQService, $compile: ICompileService, $state: IStateService, LoadingBar, roleCheckerService: RoleChecker) {
            let calendar: Calendar;

            $scope.isLoading = false;
            $scope.$watch('isLoading', newVal => LoadingBar.show = newVal);

            const eventClicked = (eventClickInfo: { el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: View }) => {
                $state.go('app.child', {id: eventClickInfo.event.extendedProps.childId});
            };

            const renderEvent = (info: { event: EventApi; el: HTMLElement; view: View }) => {
                const titleElement = info.el.querySelector('.fc-title') as HTMLDivElement;
                titleElement.innerHTML = titleElement.innerText;

                info.el.style.color = Color.contrast(info.el.style.backgroundColor);
            };

            const getLocale = () => {
                let locale = (window.navigator as any).userLanguage || window.navigator.language;
                if (locale.toLowerCase() === 'nl-nl') {
                    locale = 'nl';
                }

                return locale;
            };

            const createId = (item: GroupTimelineItem): string => {
                return `${item.childId}/${item.start}/${item.on}/${item.end}/${item.timelineType}`;
            };

            const toEvent = (item: GroupTimelineItem): EventInput | any => {

                const stringStart = item.on ? item.on : item.start;
                const stringEnd = item.on ? item.on : item.end;

                const start = moment.utc(stringStart);
                const end = moment.utc(stringEnd);

                const event = {
                    id: createId(item),
                    title: '<b>' + item.childName + '</b><br />' + item.description,
                    classNames: ['mutation-item', item.timelineType],
                    start: start.toISOString(),
                    end: end.toISOString(),
                    extendedProps: {
                        childId: item.childId
                    },
                    backgroundColor: getBackgroundColor(item.timelineType)
                };

                return event;
            };

            const getBackgroundColor = (itemType: ChildCare.Definitions.GroupApp.TimelineType): string => {
                switch (itemType) {
                    case 'birthday': return '#4BAEEA';
                    case 'absent': return '#E7E6E6';
                    case 'holiday': return '#E7E6E6';
                    case 'sick': return '#F44336';
                    case 'startPlanning': return '#008000';
                    case 'startPlanningHasPrevious': return '#7DAA55';
                    case 'endPlanning': return '#ED7D31';
                    case 'incidental': return '#7DAA55';
                    case 'swapdayFrom': return '#E7E6E6';
                    case 'swapdayTo': return '#7DAA55';
                    case 'beforeContract': return '#F6C142';
                    case 'studyDay': return '#7DAA55';
                }
            };

            const fetchEvents = (fetchInfo, successCallback, failureCallback) => {
                const startDate = moment(fetchInfo.start);
                const endDate = moment(fetchInfo.end);

                $scope.isLoading = true;

                const prms = [];
                while (endDate > startDate || startDate.format('M') === endDate.format('M')) {
                    prms.push(DataService.getCalendarTimeline($scope.selectedGroupId, startDate.format('YYYY/MM/01')));
                    startDate.add(1, 'month');
                }

                const request = $q.all(prms);
                request.then(function (results: ChildCare.Definitions.GroupApp.GroupTimelineResponse[]) {

                    const events = [];
                    for (const result of results) {
                        for (const item of result.items.sort((a, b) => a.childName.localeCompare(b.childName))) {
                            const event = toEvent(item);
                            if (!events.some(x => x.id === event.id)) {
                                events.push(event);
                            }
                        }
                    }
                    successCallback(events);

                }).finally(() => $scope.isLoading = false);
            };

            const initialize = function () {
                $scope.isLoading = true;

                $scope.selectedGroupId = SettingService.get(window.appSettings.group.selected);
                if (!$scope.selectedGroupId) {
                    $state.go('app.groups',{previous:$state.current.name});
                }

                DataService.getGroupsAsync().then(function (result) {
                    $scope.group = result.groups.find(x => x.id == $scope.selectedGroupId);
                });

                const calenderElement = document.getElementById('calendar-control');

                calendar = new Calendar(calenderElement, {
                    locales: [enLocale, nlLocale],
                    plugins: [dayGridPlugin, momentPlugin],
                    timeZone: 'UTC',
                    locale: getLocale(),
                    defaultView: 'dayGridMonth',
                    height: 600,
                    header: {
                        left: 'dayGridMonth,dayGridWeek',
                        center: 'title',
                        right: 'today prev,next'
                    },
                    events: fetchEvents,
                    displayEventTime: false,
                    eventClick: eventClicked,
                    eventRender: renderEvent
                });

                calendar.render();
            };

            $scope.onSelect = function (calEvent, jsEvent, view) {
                console.log(calEvent);
                $state.go('app.child', { id: calEvent.childId });
            };

            $scope.navigateToGroups = roleCheckerService.switchGroup;

            $timeout(initialize);
        }
    ]);

