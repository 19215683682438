import { AppModule } from '@/FortyTwoFramework';

export const ft = window.ft;
export const ng = window.ng;

export const app = window.ft.app;
export const appModule = window.ft.app.module as AppModule;

export const frameworkModule = ft.framework.module;
export const frameworkEvents = frameworkModule.events;
export const frameworkSettings = frameworkModule.settings;
export const frameworkSortingDirections = frameworkSettings.sorting.directions;
export const frameworkLevels = frameworkModule.levels;
export const frameworkNavigationType = frameworkModule.navigation.type;

export const componentsModule = ft.components.module;
export const componentsModels = componentsModule.models;
export const componentsEvents = componentsModule.events;

export const layoutModule = ft.layout.module;
export const layoutModels = layoutModule.models;
export const layoutEvents = layoutModule.events;
export const layoutSettings = layoutModule.settings;
export const layoutBladeSizes = layoutSettings.blade.size;
