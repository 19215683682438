import { ApplicationInsights, ITelemetryItem, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { FortyTwoLoginService } from '@/FortyTwoFramework';
import { IHttpService, IQService, IRootScopeService, ILocationService, IRequestConfig } from 'angular';
import { ChildCare } from '@/data-models';
import uuidv1 from 'uuid/v1';
import { ft, appModule } from '@/definitions';

const instanceId = uuidv1();

type AppConfigurationModel = ChildCare.Definitions.Configuration.AppConfigurationModel;

export class ApplicationInsightsService {
    public static $inject = ['$rootScope', '$http', '$q', '$location', 'ftLoginService'];

    private $http: IHttpService;
    private $q: IQService;
    private loginService: FortyTwoLoginService;
    public static insights: ApplicationInsights;

    constructor($rootScope: IRootScopeService, $http: IHttpService, $q: IQService, $location: ILocationService, loginService: FortyTwoLoginService) {
        this.$http = $http;
        this.$q = $q;
        this.loginService = loginService;

        this.setup = this.setup.bind(this);
        this.trackEvent = this.trackEvent.bind(this);

        if (ApplicationInsightsService.insights == null) {
            this.loginService.isLoggedInAsync().then(async isLoggedIn => {
                if (isLoggedIn) {
                    await this.setup();
                    this.trackEvent('User authenticated');
                } else {
                    $rootScope.$on(ft.framework.module.events.authentication.login, async () => {
                        await this.setup();
                        this.trackEvent('User authenticated');
                    });
                }
            });
        }
    }

    public trackEvent(name: string, properties?: any): void {
        if (ApplicationInsightsService.insights == null) {
            return;
        }

        try {
            ApplicationInsightsService.insights.trackEvent({ name: name, properties });
        } catch (e) {
            console.log(`Failed logging event ${name} to application insights`);
            console.exception(e);
        }
    }

    public async setup(): Promise<void> {
        const [key, user] = await this.$q.all([
            this.getInstrumentationKey(),
            this.loginService.getCurrentUserAsync()
        ]);

        if (key == null || user == null) {
            return;
        }

        try {
            ApplicationInsightsService.insights = new ApplicationInsights({
                config: {
                    connectionString: `InstrumentationKey=${key}`,
                    enableAutoRouteTracking: true,
                    autoTrackPageVisitTime: true
                }
            });

            ApplicationInsightsService.insights.loadAppInsights();
            ApplicationInsightsService.insights.trackPageView();

            const telemetryInitializer = (item: ITelemetryItem): void | boolean => {
                item.tags['ai.cloud.role'] = 'GroupApp';
                item.tags['ai.cloud.roleInstance'] = instanceId;
                item.data.Tenant = user.tenantName;
            };

            ApplicationInsightsService.insights.addTelemetryInitializer(telemetryInitializer);

            const senatize = (value: string) => {
                return value?.replace(',', '-').replace(';', '-').replace('=', '-').replace(' ', '-').replace('|', '-');
            };

            ApplicationInsightsService.insights.setAuthenticatedUserContext(senatize(user.name), senatize(user.tenantName), true);
        } catch (e) {
            console.log('Failed initializing Application Insights');
            console.exception(e);
        }
    }

    private async getInstrumentationKey(): Promise<string> {
        try {
            const response = await this.$http.get<AppConfigurationModel>(`${window.appModule.baseApiUrl}/configuration`);
            if (response.status !== 200) {
                return null;
            }

            return response.data.settings.telemetryInstrumentationKey;
        }
        catch (e) {
            console.log('Failed obtaining Application Insights instrumentation key from configuration call');
            console.exception(e);
            return null;
        }
    }
}

const insightsInterceptor = function () {
    return {
        request: function (config: IRequestConfig) {
            if (config.method != 'OPTIONS' && ApplicationInsightsService.insights != null) {
                config.headers['request-id'] = ApplicationInsightsService.insights.context.telemetryTrace.traceID;
            }

            return config;
        }
    };
};

/*appModule.config(['$httpProvider', function ($httpProvider: IHttpProvider) {
    $httpProvider.interceptors.push(insightsInterceptor);
}]);*/

appModule.service('ApplicationInsightsService', ApplicationInsightsService);