import { appModule } from '@/definitions';

import { IModalInstanceService } from 'angular-ui-bootstrap';
import { AppDataService } from '@/services/app-data-service';
import { IScope, IController } from 'angular';

import { ChildCare } from '@/data-models';
import moment from 'moment';

import TimesRequest = ChildCare.Definitions.Registration.TimesRequest;
import PresencesCommand = ChildCare.Definitions.GroupApp.PresencesCommand;
import TimesModel = ChildCare.Definitions.Registration.TimesModel;
import Timeslot = ChildCare.Definitions.Registration.Timeslot;

import { Child } from '@/child-factory';
import { PresencesAction, PresencesStatus } from '@/enums';

const chiIncidenteleOpvang = 3458;

interface CheckableTimeSlot extends Timeslot {
    checked: boolean;
}

interface Model {
    isValid: boolean;
    pickedTime: moment.Moment;
    comments: string;
}

interface CustomScope extends IScope {
    child: Child;
    isLoading: boolean;
    model: Model;
    timeSlots: CheckableTimeSlot[];
    isTradeAble: boolean;
    isToday: () => boolean;
    save: () => void;
    cancel: () => void;
}

// eslint-disable-next-line @typescript-eslint/class-name-casing
class kpPresenceUnexpectedChildModalController implements IController {
    static $inject = ['$scope', 'AppDataService', '$uibModalInstance', 'child', 'selectedGroup', 'date'];

    constructor(
        private $scope: CustomScope,
        private AppDataService: AppDataService,
        private $uibModalInstance: IModalInstanceService,
        private child: Child,
        private selectedGroup: string,
        private date: moment.Moment
    ) {
        $scope.child = child;

        $scope.isLoading = false;

        $scope.model = {
            isValid: true,
            pickedTime: moment(),
            comments: null
        };

        this.initialize();
    }

    private initialize() {
        this.hookScope();

        this.setArrivedAt(this.child);

        const request: TimesRequest = {
            date: this.date.toLocaleString(),
            entityType: chiIncidenteleOpvang,
            finalOnly: true,
            childId: this.child.id,
            type: ChildCare.Definitions.Registration.TimesRequestType.All,
            forInternal: undefined
        };

        this.AppDataService
            .getAvailableTimes(this.child.id, request)
            .then(this.handleGetAvailableTimes.bind(this))
            .finally(() => {
                this.$scope.isLoading = false;
            });
    }

    private hookScope() {
        this.$scope.isToday = this.isToday.bind(this);
        this.$scope.save = this.save.bind(this);
        this.$scope.cancel = this.cancel.bind(this);
    }

    private handleGetAvailableTimes(response: TimesModel) {
        let timeSlots: CheckableTimeSlot[];

        if (response.availableTimeslots.length > 0) {
            timeSlots = response.availableTimeslots.map(x => x as CheckableTimeSlot);
        } else if (response.tradableTimeslots.length > 0) {
            this.$scope.isTradeAble = true;
            timeSlots = response.tradableTimeslots.map(x => x as CheckableTimeSlot);
        }

        this.$scope.timeSlots = timeSlots.map(t => {
            t.checked = true;
            return t;
        });
    }

    private setArrivedAt(child: Child) {
        for (const presence of child.presences) {
            if (presence.startTime && !presence.endTime) {
                child.arrivedAt = moment(presence.startTime);
            }
        }
    }

    private isToday() {
        const now = moment().startOf('day');
        const startOfDay = this.date.startOf('day');

        return now.diff(startOfDay, 'day') === 0;
    }

    private save() {
        const model = this.$scope.model;

        model.isValid = true;

        // TODO: UI Error maken als tijd < dan picked tijd
        if (!this.validatePickedTime(this.child)) {
            model.isValid = false;
            return;
        }

        this.child.arrivedAt = model.pickedTime;

        this.$scope.isLoading = true;

        const command: PresencesCommand = {
            groupId: this.selectedGroup,
            childIds: [this.child.id],
            action: PresencesAction.Present,
            date: this.date.toLocaleString(),
            time: model.pickedTime.format('HH:mm'),
            remarks: model.comments,
            result: undefined
        };

        this.AppDataService.savePresence(command).then(() => {
            this.child.status = PresencesStatus.Present;
            this.$scope.isLoading = false;
            this.$uibModalInstance.close('saved');
        });
    }

    private cancel() {
        this.$uibModalInstance.dismiss();
    }

    private validatePickedTime(kid: Child) {
        if (kid.arrivedAt && this.$scope.model.pickedTime.format('HH:mm:SS') < kid.arrivedAt.format('HH:mm:SS')) {
            console.log('the picked time was under the start time of this event, impossible!');
            return false;
        }

        return true;
    }
}

appModule.controller('kpPresenceUnexpectedChildModalController', kpPresenceUnexpectedChildModalController);
