import { IController, IScope, IAngularEvent } from 'angular';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { appModule } from '@/definitions';
import { ChildCare } from '@/data-models';
import { Dictionary } from '@/dictionary';
import { PresencesStatus } from '@/enums';
import { getChildStatus } from '@/travel-planner/get-child-status';

type Child = ChildCare.Definitions.Child;

interface ILocalScope extends IScope {
    toggled: (child: Child) => void;
    toggleChild: (child: Child) => void;
    showStatus: (child: Child) => boolean;
    getStatus: (child: Child) => void;
    children: Child[];
    selected: Dictionary<boolean>;

    save(): void;
    cancel(): void;
}

class ChildPickerController implements IController {
    static $inject = ['$uibModalInstance', '$scope', 'moments','children', 'selectedChildren'];

    private $scope: ILocalScope;
    private $uibModalInstance: IModalInstanceService;

    private moments: string[];

    constructor($uibModalInstance: IModalInstanceService, $scope: ILocalScope, moments: string[], children: Child[], selectedChildren: Child[]) {
        this.$uibModalInstance = $uibModalInstance;
        this.$scope = $scope;

        this.moments = moments;

        this.$scope.selected = Dictionary.toDictionary2(selectedChildren, child => child.childId, () => true);
        $scope.children = children.sort((a, b) => a.childName.localeCompare(b.childName));

        $scope.toggleChild = this.toggleChild.bind(this);
        $scope.toggled = this.toggled.bind(this);
        $scope.save = this.save.bind(this);
        $scope.cancel = this.cancel.bind(this);
        $scope.showStatus = this.showStatus.bind(this);

        $scope.getStatus = (child: Child) => getChildStatus(this.moments, child);
    }

    private toggleChild(event: IAngularEvent, child: Child) {
        event.preventDefault();

        const isSelected = this.$scope.selected.get(child.childId) == true; // coercing value to true or false
        const newValue = this.$scope.selected.set(child.childId, !isSelected);
        console.log(`toggled ${child.childName}: ${isSelected} -> ${newValue}`);
    }

    private toggled(child: Child) {
        const returnValue = this.$scope.selected.get(child.childId) == true; // coercing value to true or false
        console.log(`isSelected: ${returnValue} child=${child.childName}`);

        return returnValue;
    }

    private showStatus(child: Child) {
        const status = getChildStatus(this.moments, child);
        return status !== PresencesStatus.Expected;
    }

    private save() {
        const selectedChildren = this.$scope.children.filter(x => this.$scope.selected.get(x.childId) == true);
        this.$uibModalInstance.close(selectedChildren);
    }

    private cancel = () => {
        this.$uibModalInstance.dismiss();
    };
}

appModule.controller('ChildPickerController', ChildPickerController);
