import { appModule, app, ft } from '@/definitions';
import { IController, IScope, IPromise } from 'angular';
import { ILoadingBar } from '@/models';
import { IModalService } from 'angular-ui-bootstrap';
import moment from 'moment';
import { AppDataService } from '@/services/app-data-service';
import { ChildCare } from '@/data-models';
import { FortyTwoLoginService, FortyTwoRootScopeService } from '@/FortyTwoFramework';
import ng from 'angular';
import { IStateParamsService } from 'angular-ui-router';

type WriteLocationsResponse = ChildCare.Definitions.GroupApp.WriteLocationsResponse;
type WriteLocation = ChildCare.Definitions.GroupApp.WriteLocation;
type WriteLocationCareType = ChildCare.Definitions.GroupApp.WriteLocationCareType;
type PostComment = ChildCare.Definitions.GroupApp.PostComment;
type PostResponse = ChildCare.Definitions.GroupApp.PostResponse;
type PostsResponseItem = ChildCare.Definitions.GroupApp.PostsResponseItem;
type PostsResponse = ChildCare.Definitions.GroupApp.PostsResponse;

interface Scope extends IScope {
    fetchingMessages: boolean;
    isLoading: boolean;
    conversations: PostsResponseItem[];
    messageLimit: number;
    messageListView: boolean;
    selectedConversation: PostResponse;
    replyComment: PostComment;
    message: string;
    sendNotification: boolean;
    hideInternalFilter: boolean;

    audiences: WriteLocation[];
    caretypes: WriteLocationCareType[];

    getReplyedAs(comment: any): any;
    showMoreMessages(): void;
    loadPost(selectedConversation: any);
    answerComment(comment: ChildCare.Definitions.GroupApp.PostComment): void;
    backToListView(): void;
    sendMessage(event: any, message: any): void;
    openNewConversationModal(): void;
    getAvatarInitials(fullName: string): string;
}

class LocationManagerConversationController implements IController {

    private lastFetchDate: string;
    private childId: string;
    private $rootScope: FortyTwoRootScopeService;
    private $scope: Scope;
    private $q: ng.IQService;
    private dataService: AppDataService;
    private $uibModal: IModalService;
    private $stateParams;
    private $interval: ng.IIntervalService;
    private loadingBar: ILoadingBar;
    private $translate;

    private unsubscribeOnNewPostMade: () => void;

    public static $inject = ['$rootScope', '$scope', '$q', 'AppDataService', '$uibModal', '$stateParams', '$interval', 'LoadingBar', '$translate'];
    constructor($rootScope: FortyTwoRootScopeService, $scope: Scope, $q: ng.IQService, dataService: AppDataService, $uibModal: IModalService, $stateParams: IStateParamsService, $interval: ng.IIntervalService, loadingBar: ILoadingBar, $translate) {

        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$q = $q;
        this.dataService = dataService;
        this.$uibModal = $uibModal;
        this.$stateParams = $stateParams;
        this.$interval = $interval;
        this.loadingBar = loadingBar;
        this.$translate = $translate;

        this.handleGetMessages = this.handleGetMessages.bind(this);

        this.lastFetchDate = null;
        this.childId = this.$stateParams.id;

        this.initializeScope();

        this.getData();
    }

    private initializeScope() {
        this.$scope.getAvatarInitials = this.getAvatarInitials.bind(this);
        this.$scope.showMoreMessages = this.showMoreMessages.bind(this);
        this.$scope.loadPost = this.loadPost.bind(this);
        this.$scope.answerComment = this.answerComment.bind(this);
        this.$scope.backToListView = this.backToListView.bind(this);
        this.$scope.sendMessage = this.sendMessage.bind(this);
        this.$scope.openNewConversationModal = this.openNewConversationModal.bind(this);
        this.$scope.getReplyedAs = this.getReplyedAs.bind(this);

        this.$scope.fetchingMessages = false;
        this.$scope.isLoading = false;
        this.$scope.conversations = [];
        this.$scope.messageLimit = app.settings.conversations.fetchRange;
        this.$scope.messageListView = true;
        this.$scope.message = '';

        this.$scope.audiences = [];
        this.$scope.caretypes = [];

        this.$scope.hideInternalFilter = true;

        this.$scope.$watch('isLoading', (newVal: boolean) => this.loadingBar.show = newVal);

        this.unsubscribeOnNewPostMade = this.$rootScope.$on('newPostMade', (_event, sendNotification: boolean) => {
            this.$scope.sendNotification = sendNotification;
            this.refreshConversations();
        });

        const newPostInterval = this.$interval(() => {
            this.$rootScope.$broadcast('newPostMade', true);
        }, 1000 * 30);

        this.$scope.$on('$destroy', () => {
            this.$interval.cancel(newPostInterval);

            if (this.unsubscribeOnNewPostMade != null) {
                this.unsubscribeOnNewPostMade();
                this.unsubscribeOnNewPostMade = null;
            }
        });
    }

    private getReplyedAs(comment: PostComment): PostComment {
        return this.$scope.selectedConversation.comments.find(x => x.commentId == comment.replyToCommentId);
    }

    private refreshConversations(): void {
        this.$scope.isLoading = true;
        this.getMessagesAsync(false)
            .then(results => this.handleGetMessages(results))
            .finally(() => this.$scope.isLoading = false);
    }

    private showMoreMessages(): void {
        // Don't do the call if it's already fetching, or there could not possibly be a new message because it didn't
        // fetch the settings amount of items from the last call
        if (this.$scope.fetchingMessages || this.$scope.messageLimit >= (this.$scope.conversations.length + app.settings.conversations.fetchRange)) {
            return;
        }

        this.$scope.isLoading = true;
        this.$scope.fetchingMessages = true;

        this.getMessagesAsync(true)
            .then(response => this.addConversationsToList(response))
            .finally(() => {
                this.$scope.messageLimit += app.settings.conversations.fetchRange;
                this.$scope.isLoading = false;
                this.$scope.fetchingMessages = false;
            });
    }

    private loadPost(conversation: PostResponse): void {
        this.$scope.messageListView = false;
        this.$scope.selectedConversation = conversation;

        this.$scope.isLoading = true;
        this.dataService.getPost(conversation).then((post) => {
            this.$scope.selectedConversation = post;
            this.$scope.selectedConversation.createdOn = conversation.createdOn;
        }).finally(() => {
            this.$scope.isLoading = false;
            //this.markAsRead(conversation);
        });
    }

    private getAvatarInitials(fullName: string): string {
        let matches = fullName.match(/\b(\w)/g);
        if (matches.length < 2) {
            return fullName.slice(0, 2).toUpperCase();
        }

        matches = matches.slice(0, 2);
        return matches.join('').toUpperCase();
    }

    private answerComment(comment: PostComment) {
        this.$scope.replyComment = comment;
    }

    private sendMessage(event, message: string) {
        if (message == null || message === '' || this.$scope.isLoading) {
            return;
        }

        this.$scope.message = message;

        this.$scope.isLoading = true;

        this.dataService.answerToPostAsync(this.$scope.selectedConversation, this.$scope.replyComment, message)
            .then(() => this.handleSaveAnswerToPost())
            .finally(() => {
                this.$scope.isLoading = false;
                this.$scope.replyComment = null;
                event.message = '';

                this.$rootScope.$emit('newPostMade', false);
            });
    }

    private openNewConversationModal(): void {
        this.$uibModal.open({
            animation: true,
            templateUrl: 'partials/locationmanager/conversation-modal.html?v=' + ft.randomNumber,
            controller: 'kpLocationConversationModalController',
            size: 'lg',
            backdrop: true,
            keyboard: true,
            resolve: {
                audiences: () => ng.copy(this.$scope.audiences),
                caretypes: () => ng.copy(this.$scope.caretypes),
                getAvatarInitials: () => this.getAvatarInitials.bind(this)
            }
        });
    }

    private backToListView(): void {
        this.$scope.messageListView = true;
        this.$scope.replyComment = null;
        this.$scope.selectedConversation = null;
    }

    private addConversationsToList(results: PostsResponse): void {
        for (const conversation of results.posts) {
            this.$scope.conversations.push(conversation);
        }
    }

    private async getData(): Promise<void> {
        this.$scope.isLoading = true;

        this.$q.all([
            this.dataService.getWriteLocations(),
            this.getMessagesAsync(false)
        ]).then((results) => {
            this.handleGetAudiences(results[0]);
            this.handleGetMessages(results[1]);
        }).finally(() => {
            this.$scope.isLoading = false;
        });
    }

    private getMessagesAsync(loadMore: boolean): IPromise<PostsResponse> {
        const dateTime = moment().utc().format('YYYY-MM-DDTHH-mm-ss');
        if (!this.lastFetchDate) {
            this.lastFetchDate = dateTime;
            return this.dataService.getMessagesAsync(this.lastFetchDate, this.childId);
        }

        if (loadMore) {
            const lastConvo = this.$scope.conversations[this.$scope.conversations.length - 1];
            return this.dataService.getMessagesAsync(lastConvo.lastUpdatedUTC, this.childId);
        }

        const returnValue = this.dataService.getMessagesSinceAsync(this.lastFetchDate, this.childId);
        this.lastFetchDate = dateTime;
        return returnValue;
    }

    private handleGetMessages(results: PostsResponse): void {
        if (!results.posts) {
            results = results[0];
        }

        if (this.$scope.conversations.length === 0) {
            this.$scope.conversations = results.posts;
        } else {
            const posts = results.posts;

            if (posts.length === 0) {
                return;
            }

            if (this.$scope.selectedConversation != null) {
                this.$scope.sendNotification = false;
                this.$scope.loadPost(this.$scope.selectedConversation);
            }

            // Send a notification that there are new messages
            if (this.$scope.sendNotification) {
                this.$rootScope.$broadcast('ft-notifications-add', {
                    title: this.$translate.instant('MESSAGES.NEW-MESSAGES'),
                    message: this.$translate.instant('MESSAGES.NEW-MESSAGES-MESSAGE', { count: posts.length })
                });
            }

            for (const post of posts) {
                const postExists = this.$scope.conversations.find(x => x.postId == post.postId);

                if (this.$scope.sendNotification) {
                    (post as any).read = false;
                }

                if (postExists) {
                    const index = this.$scope.conversations.indexOf(postExists);
                    this.$scope.conversations[index] = post;
                } else {
                    this.$scope.conversations.push(post);
                }
            }
        }
    }

    private handleGetAudiences(results: WriteLocationsResponse): void {
        this.$scope.audiences = results.locations;
        this.$scope.caretypes = results.careTypes;
    }

    private markAsRead(conversation: PostsResponseItem) {
        const index = this.$scope.conversations.indexOf(conversation);
        (conversation as any).read = true;

        this.$scope.conversations[index] = conversation;
    }

    private handleSaveAnswerToPost(): void {
        this.$scope.loadPost(this.$scope.selectedConversation);
    }
}

appModule.controller('kpLocationManagerConversationController', LocationManagerConversationController);