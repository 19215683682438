export enum PresencesStatus {
    NotExpected = 'notExpected',
    Expected = 'expected',
    Present = 'present',
    Sick = 'sick',
    PickedUp = 'pickedUp',
    Absent = 'absent'
}

export enum PresencesAction {
    Unknown = 'unknown',
    Present = 'present',
    Sick = 'sick',
    NotExpected = 'notExpected',
    PickedUp = 'pickedUp'
}

export enum Gender {
    Male = 'male',
    Unknown = 'unknown',
    Female = 'female'
}
