appModule.controller('kpConversationModalController',
    ['$rootScope', '$scope', '$uibModalInstance', 'audiences', 'childId', 'AppDataService',
        function ($rootScope, $scope, $uibModalInstance, audiences, childId, DataService) {

            const selectedAudiences = [];
            audiences.forEach((audienceGroup, groupIndex) => {
                audienceGroup.audiences.forEach((audienceItem, index) => {
                    audienceItem.type = audienceGroup.audienceType;
                    audienceItem.checked = false;
                    audiences[groupIndex].audiences[index] = audienceItem;

                    selectedAudiences.push(audienceItem);
                });
            });
            $scope.audiences = audiences;
            $scope.selectedAudience = childId ? null : audiences[0];
            $scope.selectedAudiences = selectedAudiences;

            $scope.childId = childId;

            $scope.valid = true;
            $scope.message = {
                title: null,
                content: null,
                reminderDate: null
            };
            $scope.audienceIds = [];

            $scope.cancel = function () {
                $uibModalInstance.dismiss();
            };

            $scope.toggleSelectionAudiences = function (type) {
                $scope.allAudiencesSelected = !$scope.allAudiencesSelected;
                $scope.selectedAudiences.forEach(function (item) {
                    if (item.type == type) {
                        item.checked = $scope.allAudiencesSelected;
                    }
                });
            };

            $scope.toggleSelectAudience = function (audience) {
                audience.checked = !audience.checked;

                if (!audience.checked) {
                    $scope.allAudiencesSelected = false;
                    const allAudience = $scope.selectedAudiences.find(x => x.type == audience.type && x.isAllAudience);
                    allAudience.checked = false;
                }

                var amountOfAudiences = $scope.selectedAudiences.filter(
                    x => x.isAllAudience == false && x.type == audience.type
                ).length;

                var checkedAmount = $scope.selectedAudiences.filter(
                    x => x.checked == true && x.type == audience.type
                ).length;

                if (amountOfAudiences === checkedAmount) {
                    $scope.allAudiencesSelected = true;
                    const allAudience = $scope.selectedAudiences.find(x => x.type == audience.type && x.isAllAudience);
                    allAudience.checked = true;
                }
            };

            $scope.hasAudiencesSelected = function () {
                if ($scope.selectedAudiences) {
                    return $scope.selectedAudiences.filter(x => x.checked == true).length > 0;
                }
            };

            $scope.selectAudience = function (index) {
                $scope.selectedAudience = audiences[index];
                const allAudience = $scope.selectedAudiences.find(
                    x => x.isAllAudience && x.type === audiences[index].audienceType
                );

                console.log(allAudience);

                if (allAudience) {
                    console.log('test');
                    $scope.allAudiencesSelected = allAudience.checked;
                    return;
                }

                const checkedAudiencesInType = $scope.selectedAudiences.filter(
                    x => x.type === audiences[index].audienceType && x.checked
                ).length;

                $scope.allAudiencesSelected = checkedAudiencesInType === audiences[index].audiences.length;
            };

            $scope.saveConversation = function () {
                if ($scope.isLoading) {
                    return;
                }

                $scope.isLoading = true;

                $scope.valid = true;
                var audiences = $scope.selectedAudiences.filter(x => x.checked == true);

                if (!ft.hasValue($scope.message.content)) {
                    $scope.valid = false;
                }

                if (!ft.hasValue($scope.message.title)) {
                    $scope.valid = false;
                }

                if (ft.hasValue($scope.message.reminderDate)) {
                    $scope.message.reminderDate = moment($scope.message.reminderDate, 'DD/MM/YYYY').format('YYYY/MM/DD');
                }

                if (!audiences || !audiences.length) {
                    $scope.valid = false;
                }

                if (!$scope.valid) {
                    return;
                }

                var hasAllAudience = audiences.filter(x => x.isAllAudience == true);
                var otherAudiences = audiences.filter(x => x.isAllAudience == false);
                var expectedAudienceIds = [];

                var hasAllAudienceTypes = [];
                hasAllAudience.forEach(function (allAudience) {
                    hasAllAudienceTypes.push(allAudience.type);
                    expectedAudienceIds.push({id: allAudience.id});
                });

                otherAudiences.forEach(function (audience) {
                    if (!hasAllAudienceTypes.includes(audience.type)) {
                        expectedAudienceIds.push({id: audience.id});
                    }
                });

                const prm = DataService.saveConversationAsync($scope.message, expectedAudienceIds, childId);
                prm.then(handleSaveConversation, handleSaveConversationError);
                prm.finally(function () {
                    $scope.isLoading = false;
                });
            };

            var handleSaveConversation = function () {
                $scope.toggleSelectionAudiences();
                $scope.message.content = '';
                $scope.message.title = '';

                $rootScope.$emit('newPostMade', false);
                $uibModalInstance.dismiss();
                swal({
                    title: 'Verzonden!',
                    text: 'Het bericht is verzonden!',
                    timer: 2000,
                    type: 'success'
                });
            };

            var handleSaveConversationError = function () {
                $uibModalInstance.dismiss();
                swal({
                    title: $translate.instant('ERRORS.SAVE-FAILED.TITLE'),
                    text: 'Er ging iets mis bij het verzenden!',
                    timer: 2500,
                    type: 'error'
                });
            };

        }
    ]
);
