window.models = ft.models;
window.appModels = ft.defineNamespace('FortyTwo.app.models');
ft.app.module = ng.module('childcare', [
    'ftComponents',
    'ftLayout',
    'luegg.directives',
    'ngTouch',
    'ui.bootstrap',
    'ngSanitize',
    'ngTable',
    'infinite-scroll',
    'ngMaterial',
    'ngAria',
    'ngMaterialDatePicker',
    'toaster',
    'ngAnimate'
]);
window.appModule = ft.app.module;
