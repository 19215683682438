import { appModule } from '@/definitions';
import { AppDataService } from '@/services/app-data-service';
import { ftSettingsService } from '@/FortyTwoFramework';
import { IModalServiceInstance } from 'angular-ui-bootstrap';
import { IScope, IQService } from 'angular';
import moment from 'moment';
import { ChildCare } from '@/data-models';

import GroupModel = ChildCare.Definitions.Account.GroupModel;

class StudyDay {
    constructor(public id: string, public name: string, public date: moment.Moment, public planningId: string) {}
}

interface ILocalScope extends IScope {
    studyDays: StudyDay[];
    isLoading: boolean;
    useCreditHours: boolean;
    data: {
        selectedStudyDays: StudyDay[];
        groupId: string;
    };
    groups: GroupModel[];
    close: () => void;
    save: () => void;
    selectStudyDay: (studyDay: StudyDay) => void;
}

appModule.controller('kpActionStudydayModalController',
    ['$scope', '$q', '$uibModalInstance', 'AppDataService', 'childId', '$timeout', 'ftSettingService', 'LoadingBar',
        function ($scope: ILocalScope, $q: IQService, $uibModalInstance: IModalServiceInstance, DataService: AppDataService, childId: string, $timeout, ftSettingService: ftSettingsService, LoadingBar) {
            $scope.studyDays = [];
            $scope.isLoading = false;
            $scope.useCreditHours = false;

        $scope.data = {
            selectedStudyDays: [],
            groupId: null
        };

        $scope.$watch('isLoading', function(newVal) {
            LoadingBar.show = newVal;
        });

        var initialize = function() {
            $scope.isLoading = true;

            DataService.getGroupsAsync()
                .then(function(res) {
                    $scope.groups = res.groups;
                    $scope.data.groupId = ftSettingService.get(window.appSettings.group.selected);
                })
                .finally(() => ($scope.isLoading = false));

            DataService.getRequestStudyDays(childId)
                .then(function(result) {
                    $scope.studyDays = result.studyDays.map(
                        x => new StudyDay(x.studydayId, x.name, moment(x.date), x.planningId)
                    );
                })
                .finally(function() {
                    $scope.isLoading = false;
                });
        };

        $scope.close = function() {
            $uibModalInstance.dismiss();
        };

        $scope.save = function() {
            $scope.isLoading = true;

            let groupId = $scope.data.groupId;

            if ($scope.data.groupId == ftSettingService.get(window.appSettings.group.selected)) {
                groupId = undefined;
            }

            const promises = $scope.data.selectedStudyDays.map(x =>
                DataService.requestStudyDays(x, childId, groupId, $scope.useCreditHours)
            );

            $q.all(promises)
                .then(() => {
                    $uibModalInstance.dismiss();
                })
                .finally(() => ($scope.isLoading = false));
        };

        $scope.selectStudyDay = (studyDay: StudyDay) => {
            if ($scope.data.selectedStudyDays.includes(studyDay)) {
                const currentIndex = $scope.data.selectedStudyDays.indexOf(studyDay);
                $scope.data.selectedStudyDays.splice(currentIndex, 1);
            } else {
                $scope.data.selectedStudyDays.push(studyDay);
            }
        };

        $timeout(initialize());
    }
]);
