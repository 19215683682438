import { IComponentOptions, IController, IIntervalService, IHttpService, IRequestShortcutConfig, IRootScopeService } from 'angular';
import { stringify } from 'qs';
import './styles.less';
import { FortyTwoLoginService, ftSettingsService } from '@/FortyTwoFramework';
import angular from 'angular';
import moment from 'moment';
import { IStateService } from 'angular-ui-router';

interface NotificationsBindings {
    time: number;
}

interface Toast {
    type: 'wait' | 'success' | 'warning' | 'error' | 'note';
    title?: string;
    body?: string;
    timeout?: number;
    bodyOutputType?: undefined | 'html' | 'trustedHtml' | 'template' | 'templateWithData' | 'directive';
    clickHandler?: (toast: Toast, isCloseButton: boolean) => void;
    toasterId?: string;
    showCloseButton?: boolean;
    toastId?: string;
    onHideCallback?: (toast: Toast, isCloseButton: boolean) => void;
}

interface Toaster {
    clear(toast: Toast): void;
    pop(toast: Toast): { toasterId: string; toastId: string };
    pop(
        type: Toast['type'], title: Toast['title'], body: Toast['body'], timeout: Toast['timeout'],
        bodyOutputType: Toast['bodyOutputType'], clickHandler: Toast['clickHandler'], toasterId: Toast['toasterId'], showCloseButton: Toast['showCloseButton'],
        toastId: Toast['toastId'], onHideCallback: Toast['onHideCallback']): { toasterId: string; toastId: string };
}

class NotificationsController implements IController, NotificationsBindings {
    public time: number;
    private timeInSeconds: number;

    public messages: NewMessage[];

    public static $inject = ['$interval', 'ftLoginService', 'ftSettingService', '$http', '$rootScope', 'toaster', '$state'];
    constructor(private $interval: IIntervalService,
        private ftLoginService: FortyTwoLoginService,
        private ftSettingsService: ftSettingsService,
        private $http: IHttpService,
        private $rootScope: IRootScopeService,
        private toaster: Toaster,
        private $state: IStateService) {
        this.messages = [];
    }

    public $onInit() {
        this.timeInSeconds = this.time ?? 5 * 60;
        this.$interval(this.refresh.bind(this), this.timeInSeconds * 1000);
    }

    private async refresh(): Promise<void> {
        const newMessages = await this.fetchNewMessagesAsync();

        if (newMessages.length > 0) {
            this.messages = newMessages;
            for (const message of newMessages) {
                //this.$rootScope.$broadcast(window.ft.framework.module.events.notifications.add, { title: `${message.from}: ${message.title}`, message: message.message });
                this.toaster.pop({
                    type: 'success',
                    body: `{ template: 'comment-template', data: { from: '${message.from}', title: '${message.title}', on: '${message.on.format('LT')}' } }`,
                    bodyOutputType: 'templateWithData',
                    showCloseButton: true,
                    clickHandler: (toast, isCloseButton) => {
                        this.toaster.clear(toast);
                        if (isCloseButton == null) {
                            this.navigateTo(message);
                        }
                    }
                });
            }
            this.$rootScope.$apply();
        }
    }

    private navigateTo(message: NewMessage): void {
        this.$state.go('app.groupmedia', { postId: message.postId });
    }

    private async fetchNewMessagesAsync(): Promise<NewMessage[]> {
        const isLoggedIn = await this.ftLoginService.isLoggedInAsync();
        if (!isLoggedIn) {
            console.warn('unable to fetch messages when not logged in');
            return [];
        }

        const { token } = this.ftLoginService.getTokenData();
        const options: IRequestShortcutConfig = {
            headers: {
                'Authorization': `Bearer ${token.value}`
            },
            transformResponse: appendTransform(this.$http.defaults.transformResponse, deserialize)
        };

        const groupId = this.ftSettingsService.get(window.appSettings.group.selected);
        const query = stringify({ groupId, since: moment().subtract(this.timeInSeconds + 5, 'seconds').toISOString(true) });
        const response = await this.$http.get<Response>(`${window.appModule.baseApiUrl}/groups/messages?${query}`, options);
        if (response.status !== 200) {
            return [];
        }

        return response.data.messages;
    }
}

function appendTransform(defaults: string | any[] | angular.IHttpResponseTransformer, transform: (value: Response) => void) {

    // We can't guarantee that the default transformation is an array
    defaults = angular.isArray(defaults) ? defaults : [defaults];

    // Append the new transformation to the defaults
    return defaults.concat(transform);
}

function deserialize(response: Response) {
    if (response.messages == null) {
        return;
    }

    for (const message of response.messages) {
        message.on = moment(message.on);
    }

    return response;
}

class NotificationsComponent implements IComponentOptions {
    public controller: any;
    public template: string;
    public bindings: any;

    constructor() {
        this.bindings = {
            time: '<'
        };

        this.controller = NotificationsController;
        this.template = require('./template.html');
    }
}

interface Response {
    messages: NewMessage[];
}

interface NewMessage {
    postId: string;
    type: MessageType;
    id: string;
    title: string;
    from: string;
    on: moment.Moment;
    message: string;
}

enum MessageType {
    post = 'post',
    comment = 'comment'
}

window.appModule.component('notifications', new NotificationsComponent());
