import { appModule, ft } from '@/definitions';
import { RoleChecker } from '@/role-checker';
import { ftSettingsService, FortyTwoLoginService } from '@/FortyTwoFramework';
import { AppDataService } from '@/services/app-data-service';
import { ITimeoutService, IScope } from 'angular';
import { IStateService } from 'angular-ui-router';
import { ILoadingBar } from '@/models';
import { ChildCare } from '@/data-models';

type GroupModel = ChildCare.Definitions.Account.GroupModel;

interface LocalScope extends IScope {
    randomNumber: number;
    isLoading: boolean;
    group: any;
    navigateToGroups: any;
}

appModule.controller('kpGroupMediaController',
    ['$scope', '$state', '$timeout', 'AppDataService', 'ftSettingService', 'LoadingBar', 'ftLoginService', RoleChecker.serviceName,
        function ($scope: LocalScope, $state: IStateService, $timeout: ITimeoutService, AppDataService: AppDataService, ftSettingService: ftSettingsService, LoadingBar: ILoadingBar, ftLoginService: FortyTwoLoginService, roleCheckerService: RoleChecker) {
            var initialized = false;
            var selectedGroupId: string;

            $scope.randomNumber = ft.randomNumber;
            $scope.isLoading = false;
            $scope.group = null;

            $scope.$watch('isLoading', (newVal: boolean) => {
                LoadingBar.show = newVal;
            });

            const initialize = function () {
                if (initialized) {
                    return;
                }

                selectedGroupId = ftSettingService.get(window.appSettings.group.selected);
                if (!selectedGroupId) {
                    $state.go('app.groups', { previous:$state.current.name });
                }

                $scope.isLoading = true;
                AppDataService.getGroupsAsync().then((results) => {
                    setCurrentGroup(results.groups, selectedGroupId);
                });

                initialized = true;
            };

            //#region screen handlers
            $scope.navigateToGroups = roleCheckerService.switchGroup;

            const setCurrentGroup = (groups: GroupModel[], selectedGroupId: string) => {
                $scope.group = groups.find(x => x.id === selectedGroupId);
                if (!$scope.group) {
                    ftLoginService.getCurrentUserAsync().then(user => {
                        if (user.groups?.length === 1) {
                            ftSettingService.set(window.appSettings.group.selected, user.groups[0].id);
                            $scope.group = groups.find(x => x.id === user.groups[0].id);
                        } else {
                            $state.go('app.groups', { previous:$state.current.name });
                        }
                    });
                }
            };

            //#endregion
            $timeout(initialize);
        }
    ]);
