import { IController, IScope } from 'angular';
import { IModalInstanceService, IModalService } from 'angular-ui-bootstrap';
import { appModule, ft } from '@/definitions';
import { ChildCare } from '@/data-models';
import { Moment } from 'moment';
import { AppDataService } from '@/services/app-data-service';
import swal from 'bootstrap-sweetalert';
import { RidesViewModel, CapacityMode } from '@/travel-planner/rides-view-model';

import TransportVehicle = ChildCare.Definitions.Transport.Models.TransportVehicle;
import Employee = ChildCare.Definitions.Locations.Models.Employee;
import TransportChild = ChildCare.Definitions.Transport.Models.TransportChild;
import { TransportTableRow } from '@/travel-planner/transport-table-row';

interface Scope extends IScope {
    vm: RidesViewModel;

    isLoading: boolean;
    errors: { [key: string]: string };

    selectedEmployeeId: string;

    selectVehicle(vehicle: TransportVehicle): void;
    selectChildrenClicked(): void;
    save(): void;
    cancel(): void;
}

class EditRideController implements IController {

    private $scope: Scope;

    private $uibModal: IModalService;
    private $uibModalInstance: IModalInstanceService;

    private $translate;

    static $inject = ['$scope', '$translate', '$uibModal', '$uibModalInstance', 'AppDataService', 'sourceRows', 'date', 'vehicles', 'employees', 'onCapacity'];

    constructor($scope: Scope, $translate: any, $uibModal: IModalService, $uibModalInstance: IModalInstanceService, dataService: AppDataService, sourceRows: TransportTableRow[], date: Moment, vehicles: TransportVehicle[], employees: Employee[], onCapacity: boolean) {
        this.$scope = $scope;
        this.$translate = $translate;
        this.$uibModal = $uibModal;
        this.$uibModalInstance = $uibModalInstance;

        this.$scope.vm = new RidesViewModel(dataService, date, sourceRows, vehicles, employees);
        this.$scope.vm.setCapacityMode(onCapacity ? CapacityMode.restrict : CapacityMode.none);

        this.$scope.selectedEmployeeId = this.$scope.vm.getSelectedEmployee()?.employeeId;

        this.$scope.$watch('selectedEmployeeId', (value: string) => this.$scope.vm.selectEmployee(value));

        this.loadScopeFunctions();
    }

    private onSelectChildrenClicked() {
        this.$uibModal.open({
            animation: true,
            templateUrl: 'partials/travel/child-picker.html?v=' + ft.randomNumber,
            controller: 'ChildPickerController',
            backdrop: true,
            size: 'sm',
            keyboard: true,
            resolve: {
                children: () => this.$scope.vm.getAllChildren(),
                selectedChildren: () => this.$scope.vm.getSelectedChildren(),
                moments: () => this.$scope.vm.getMoments()
            }
        }).result.then((result: TransportChild[]) => {
            this.$scope.vm.selectChildren(result.map(x => x.childId));
        });
    }

    private async onSave(): Promise<void> {
        const errors = this.$scope.vm.validate();
        if (errors.length > 0) {
            this.$scope.errors = {};
            for (const error of errors) {
                switch (error) {
                    case 'selectedVehicle': this.$scope.errors['selectedVehicle'] = this.$translate.instant('TRANSPORT.ERRORS.VEHICLE'); break;
                    case 'selectedEmployee': this.$scope.errors['selectedEmployee'] = this.$translate.instant('TRANSPORT.ERRORS.EMPLOYEE'); break;
                    case 'selectedChildren': this.$scope.errors['selectedChildren'] = this.$translate.instant('TRANSPORT.ERRORS.SELECTED-CHILDREN'); break;
                    case 'unchanged':  this.$uibModalInstance.close(); break;
                }
            }

            return;
        }

        this.$scope.isLoading = true;

        try {
            const error = await this.$scope.vm.save();
            if (error) {
                swal({
                    title: this.$translate.instant('ERRORS.SAVE-FAILED.TITLE'),
                    text:  this.$translate.instant('ERRORS.SAVE-FAILED.MESSAGE') + '\n' + error,
                    timer: 3000,
                    type: 'error'
                });
            } else {
                this.$uibModalInstance.close();
            }
        } finally {
            this.$scope.isLoading = false;
            this.$scope.$apply();
        }

    }

    private onCancel() {
        this.$uibModalInstance.dismiss();
    }

    private loadScopeFunctions() {
        this.$scope.selectChildrenClicked = this.onSelectChildrenClicked.bind(this);
        this.$scope.save = this.onSave.bind(this);
        this.$scope.cancel = this.onCancel.bind(this);
    }
}

appModule.controller('EditRideController', EditRideController);
