import { appModule, ft } from '@/definitions';
import moment from 'moment';
import { IScope, copy } from 'angular';

import { ftSettingsService } from '@/FortyTwoFramework';
import { AppDataService } from '@/services/app-data-service';

import { Child, Diary } from '@/child-factory';
import { PresencesStatus } from '@/enums';
import { ChildCare } from '@/data-models';

import DiaryPresencesResponse = ChildCare.Definitions.Family.DiaryPresencesResponse;
import DiaryEntryType = ChildCare.Definitions.Family.DiaryEntryType;
import { RoleChecker } from '@/role-checker';

interface ILocalScope extends IScope {
    displayDatePicker: () => void;
    isLoading: boolean;
    initialized: boolean;
    group: any;
    PresencesStatus: typeof PresencesStatus;
    model: { showAll: boolean; removeMode: boolean };
    pickedDate: moment.Moment;
    selectedChildren: Child[];
    allChildren: Child[];
    children: Child[];
    navigateToGroups: () => void;
    toggleChild: (child: Child) => void;
    isToday: () => boolean;
    today: () => void;
    nextDay: () => void;
    previousDay: () => void;
    openModal: (isDiary: boolean) => void;
    isSelected: (child: Child) => boolean;
    onClick: (childId: string, diaryEntry: Diary) => void;
}

appModule.controller('kpDiaryOverviewController',
    ['$scope', '$timeout', 'AppDataService', 'ftSettingService', '$q', '$uibModal', '$location', '$state', 'LoadingBar', 'mdcDateTimeDialog', RoleChecker.serviceName,
        function ($scope: ILocalScope, $timeout, DataService: AppDataService, SettingService: ftSettingsService, $q: ng.IQService, $uibModal, $location, $state, LoadingBar, mdcDateTimeDialog, roleCheckerService: RoleChecker) {
            let selectedGroupId = null;

            $scope.isLoading = false;
            $scope.initialized = false;
            $scope.group = null;
            $scope.PresencesStatus = PresencesStatus;

            $scope.model = {
                showAll: false,
                removeMode: false
            };

            $scope.pickedDate = $location.search().date ?
                moment($location.search().date, 'DD/MM/YYYY') :
                moment();

            $scope.selectedChildren = [];
            $scope.allChildren = [];
            $scope.children = [];

            $scope.navigateToGroups = roleCheckerService.switchGroup;

            $scope.$watch('isLoading', function (newVal, oldVal) {
                LoadingBar.show = newVal;
            });

            const initialize = function () {
                if ($scope.initialized) {
                    return;
                }

                selectedGroupId = SettingService.get(window.appSettings.group.selected);
                if (!selectedGroupId) {
                    $state.go('app.groups',{previous:$state.current.name});
                }

                const promises: ng.IPromise<any>[] = [];
                promises.push(DataService.getGroupsAsync());
                promises.push(DataService.getDiaryPresence(selectedGroupId, $scope.pickedDate.format('YYYY-MM-DD')));
                const request = $q.all(promises);

                $scope.isLoading = true;
                request.then(function (results) {
                    $scope.group = results[0].groups.find(x => x.id === selectedGroupId);
                    handleGetDiaries(results[1]);
                }).finally(function () {
                    $scope.isLoading = false;
                });

                $scope.initialized = true;
            };

            $scope.onClick = (childId: string, diaryEntry: Diary) => {
                if (!$scope.model.removeMode) {
                    return;
                }

                DataService.deleteDiaryEntry(childId, diaryEntry.id).then(() => {
                    pickedDateWatch();
                });
            };

            $scope.$watch('pickedDate', function () {
                pickedDateWatch();
                $location.path('/app/diary').search({date: $scope.pickedDate.format('DD-MM-YYYY')});
            }, true);

            const pickedDateWatch = function () {
                if (!$scope.initialized) {
                    return;
                }

                $scope.isLoading = true;
                DataService.getDiaryPresence(selectedGroupId, $scope.pickedDate.format('YYYY-MM-DD'))
                .then(handleGetDiaries)
                .finally(function () {
                    $scope.selectedChildren = [];
                    $scope.isLoading = false;
                });
            };

            const handleGetDiaries = function (result: DiaryPresencesResponse) {
                $scope.children = [];

                for (const presence of result.presences) {
                    const child = new Child(presence.child);
                    child.status = presence.status as unknown as PresencesStatus;
                    (child as any).isChecked = false;

                    for (const diary of presence.diaryEntries) {
                        child.addDiary(diary);
                    }

                    $scope.children.push(child);
                }

                $scope.allChildren = copy($scope.children);
            };

            $scope.toggleChild = function (child) {
                if ($scope.selectedChildren.includes(child)) {
                    const currentIndex = $scope.selectedChildren.indexOf(child);
                    $scope.selectedChildren.splice(currentIndex, 1);
                } else {
                    $scope.selectedChildren.push(child);
                }
            };

            $scope.isToday = function () {
                return moment().startOf('day')
                    .diff($scope.pickedDate.startOf('day'), 'day') === 0;
            };

            $scope.displayDatePicker = () => {
                mdcDateTimeDialog.show({
                    currentDate: $scope.pickedDate,
                    time: false,
                    autoOk: true,
                    disableParentScroll: true
                }).then((date) => {
                    $scope.pickedDate = moment(date);
                });
            };

            $scope.today = function () {
                $scope.pickedDate = moment();
            };

            $scope.nextDay = function () {
                $scope.pickedDate = $scope.pickedDate.add(1, 'day');
            };

            $scope.previousDay = function () {
                $scope.pickedDate = $scope.pickedDate.subtract(1, 'day');
            };

            let diaryEntryType: DiaryEntryType | null = null;
            const otherDiaryTypes: DiaryEntryType[] = [];
            $scope.openModal = function (isDiary: boolean) {
                if (diaryEntryType && isDiary || otherDiaryTypes.length > 0 && !isDiary) {
                    return openDiaryModal(isDiary);
                }

                $scope.isLoading = true;
                DataService.getDiaryEntryTypes().then((result) => {
                    result.types.forEach(function (type) {
                        if (type.isDiary) {
                            diaryEntryType = type;
                        } else {
                            otherDiaryTypes.push(type);
                        }
                    });

                    openDiaryModal(isDiary);
                }).finally(function () {
                    $scope.isLoading = false;
                });
            };

            $scope.isSelected = (child: Child): boolean => {
                return $scope.selectedChildren.includes(child);
            };

            const openDiaryModal = function (isDiary: boolean) {
                const instance = $uibModal.open({
                    animation: true,
                    templateUrl: 'partials/diary/diaryModal.html?v=' + ft.randomNumber,
                    controller: 'kpDiaryModalController',
                    size: 'large',
                    backdrop: true,
                    keyboard: true,
                    resolve: {
                        isDiary: () => isDiary,
                        diaryType: () => copy(diaryEntryType),
                        otherTypes: () => copy(otherDiaryTypes),
                        selectedChildren: () => copy($scope.selectedChildren),
                        pickedDate: () => copy($scope.pickedDate)
                    }
                });

                instance.result.then(function () {
                    pickedDateWatch();
                });
            };

            $timeout(initialize);
        }
    ]);

