import { RoleChecker } from '@/role-checker';
import { appModule } from '@/definitions';
import { FortyTwoLoginService, ftSettingsService, FortyTwoRootScopeService } from '@/FortyTwoFramework';
import { AppDataService } from '@/services/app-data-service';
import { IScope, IPromise } from 'angular';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { ChildCare } from '@/data-models';

type GroupModel = ChildCare.Definitions.Account.GroupModel;

interface LocalScope extends IScope {
    isLoading: boolean;
    groups: GroupModel[];
    switchGroup(groupId: string): void;
}

class GroupListController {
    public static $inject = ['$rootScope', '$scope', '$state', '$stateParams', 'ftSettingService', 'AppDataService', 'ftLoginService', RoleChecker.serviceName];

    constructor(
        private $rootScope: FortyTwoRootScopeService,
        $scope: LocalScope,
        $state: IStateService,
        private $stateParams: IStateParamsService,
        private settingsService: ftSettingsService,
        private dataService: AppDataService,
        ftLoginService: FortyTwoLoginService,
        roleCheckerService: RoleChecker) {

        $scope.isLoading = true;
        $scope.groups = [];
        $scope.switchGroup = this.switchGroup.bind(this);

        roleCheckerService.allowGroupSwitching().then(result => {
            if (!result) {
                ftLoginService.doLogout();
                settingsService.set(window.appSettings.group.selected, undefined);
                $state.go('outside-app.login');
            }
        });

        dataService.getGroupsAsync().then(results => {
            $scope.groups = results.groups;
            $scope.isLoading = false;
        });
    }

    public switchGroup(groupId: string): IPromise<void> {
        return this.dataService.switchGroup(groupId).then(response => {

            this.settingsService.set(window.appSettings.group.selected, groupId);

            const { location: { hostname, port } } = window;
            const linkToRemove = this.$rootScope.configuration.services.groups;

            let redirectLink = response.redirectUri;
            if (response.redirectUri.includes(linkToRemove)) {
                redirectLink = `${location.protocol}//${hostname}:${port}${response.redirectUri.substr(linkToRemove.length)}`;
            }

            if (this.$stateParams.previous != null) {
                redirectLink += `&previous=${this.$stateParams.previous}`;
            }

            location.href = redirectLink;
        });
    }
}

appModule.controller('kpGroupListController', GroupListController);