export interface KeyValuePair<TKey, TValue> {
    key: TKey;
    value: TValue;
}

export class Dictionary<TValue> {

    public containsKey(key: string | number): boolean {
        return Object.prototype.hasOwnProperty.call(this, key);
    }

    public get(key: string | number): TValue {
        return this[key];
    }

    public getOrAdd(key: string | number, value: TValue): TValue {
        const existingValue = this[key];
        if (existingValue == null) {
            this[key] = value;
            return value;
        }

        return existingValue;
    }

    public set(key: string | number, value: TValue): TValue {
        return this[key] = value;
    }

    public set2(key: string | number, setter: (old: TValue) => TValue): TValue {
        return this[key] = setter(this[key]);
    }

    public add(key: string | number, value: TValue): TValue {
        this[key] = value;

        return value;
    }

    public remove(key: string | number): void {
        delete this[key];
    }

    public clear() {
        for (const key of this.keys()) {
            this.remove(key);
        }
    }

    public length(): number {
        return this.keys().length;
    }

    public keys(): string[] {
        return Object.keys(this);
    }

    public values(): TValue[] {
        const result = [];

        for (const key of this.keys()) {
            result.push(this.get(key));
        }

        return result;
    }

    public lastValue(): TValue {
        const keys = Object.keys(this);
        return this.get(keys[keys.length - 1]);
    }

    public firstValue(): TValue {
        const keys = Object.keys(this);
        return this.get(keys[0]);
    }

    public at(index: number): KeyValuePair<string, TValue> {
        const keys = this.keys();
        const key = keys[index];

        return { key, value: this.get(key) };
    }

    public filter(filterAction: (kvp: KeyValuePair<string, TValue>) => boolean): KeyValuePair<string, TValue>[] {
        const results: KeyValuePair<string, TValue>[] = [];

        for (const key of this.keys()) {
            const kvp = { key, value:this.get(key) };

            if (filterAction(kvp)) {
                results.push(kvp);
            }
        }

        return results;
    }

    static toDictionary<TValue>(values: TValue[], keySelector: (value: TValue, index: number) => string | number): Dictionary<TValue> {
        const result = new Dictionary<TValue>();

        let index = 0;
        for (const value of values) {
            result[keySelector(value, index)] = value;
            index++;
        }

        return result;
    }

    static toDictionary2<TItem, TValue>(items: TItem[], keySelector: (item: TItem, index: number) => string | number, valueSelector: (item: TItem, index: number) => TValue): Dictionary<TValue> {
        const result = new Dictionary<TValue>();

        let index = 0;
        for (const item of items) {
            result[keySelector(item, index)] = valueSelector(item, index);
            index++;
        }

        return result;
    }
}