import { IController, IScope } from 'angular';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { appModule } from '@/definitions';
import { AppDataService } from '@/services/app-data-service';

interface ILocalScope extends IScope {
    isLoading: boolean;
    type: string;
    id: string;
    name: string;
    file: File;
    imageContent: string;

    save(): void;
    cancel(): void;
    readFile(): void;
}

class AvatarUploadModalController implements IController {
    static $inject = ['$uibModalInstance', '$scope', 'AppDataService', 'type', 'id', 'name'];

    constructor(private $uibModalInstance: IModalInstanceService,
                private $scope: ILocalScope,
                private dataService: AppDataService,
                type: string,
                id: string,
                name: string) {

        $scope.isLoading = false;
        $scope.type = type;
        $scope.id = id;
        $scope.name = name;

        this.loadScopeFunctions();
    }

    loadScopeFunctions() {
        const $scope = this.$scope;

        $scope.readFile = () => {
            var reader = new FileReader();
            reader.onload = () => {
                $scope.$apply(() => {
                    $scope.imageContent = reader.result as string;
                });
            };
            reader.readAsDataURL($scope.file);
        };

        $scope.save = () => {
            $scope.isLoading = true;
            this.dataService
                .saveProfileImage({ type: $scope.type, id: $scope.id, content: $scope.imageContent })
                .then(() => {
                    this.$uibModalInstance.close('saved');
                })
                .finally(() => {
                    this.$uibModalInstance.close('failed');
                });
        };

        $scope.cancel = () => {
            this.$uibModalInstance.dismiss();
        };
    }
}

appModule.controller('kpAvatarUploadModalController', AvatarUploadModalController);
