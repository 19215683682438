import { IController, ILocationService, IScope, IAngularEvent, IPromise } from 'angular';
import { AppDataService } from '@/services/app-data-service';
import { ILoadingBar } from '@/models';
import { appModule, ng } from '@/definitions';
import { IModalService } from 'angular-ui-bootstrap';
import { ChildCare } from '@/data-models';
import moment from 'moment';
import swal from 'bootstrap-sweetalert';
import { RoleChecker } from '@/role-checker';

type Location = ChildCare.Definitions.Locations.Models.Location;
type ActivityPlanning = ChildCare.Definitions.Activity.ActivityPlanning;
type Activity = ChildCare.Definitions.Activity.Activity;


interface ILocalScope extends IScope {
    isLoading: boolean;
    activities: ChildCare.Definitions.Activity.ActivityPlanning[];
    locations: Location[];
    locationId: string;
    todayDate: moment.Moment;
    pickedDate: moment.Moment;
    currentRange: moment.Moment[];

    displayDatePicker(): void;
    getActivitiesForDate(date: moment.Moment): ActivityPlanning[];
    formatTime(date: string): string;
    today(): void;
    previousDay(): void;
    nextDay(): void;
    dateSelected(date: moment.Moment): void;
    activitySelected: ($event: IAngularEvent, activity: ActivityPlanning) => void;
    navigateToGroups(): void;
    generateClassesForDate(date: moment.Moment): string;
}

class ActivityPlannerController implements IController {
    static $inject = [
        '$scope',
        'AppDataService',
        'LoadingBar',
        '$location',
        '$uibModal',
        'mdcDateTimeDialog',
        RoleChecker.serviceName
    ];

    private locationHasKiosk: boolean;

    constructor(
        private $scope: ILocalScope,
        private dataService: AppDataService,
        private loadingBar: ILoadingBar,
        private $location: ILocationService,
        private $uibModal: IModalService,
        private dateTimeDialog: any,
        private roleCheckerService: RoleChecker
    ) {
        this.initializeScope();
        this.loadScopeWatches();
        this.loadData();

        this.$scope.today = this.selectToday.bind(this);
        this.$scope.previousDay = this.selectPreviousDay.bind(this);
        this.$scope.nextDay = this.selectNextDay.bind(this);
        this.$scope.navigateToGroups = this.roleCheckerService.switchGroup;
        this.$scope.formatTime = this.formatTime.bind(this);
        this.$scope.getActivitiesForDate = this.getActivitiesForDate.bind(this);
        this.$scope.displayDatePicker = this.displayDatePicker.bind(this);
        this.$scope.activitySelected = this.onActivitySelected.bind(this);
        this.$scope.dateSelected = this.onDateSelected.bind(this);
        this.$scope.generateClassesForDate = this.generateClassesForDate.bind(this);
    }

    private loadScopeWatches() {
        this.$scope.$watch('locationId', () => {
            if (this.$scope.isLoading === false && this.$scope.locationId) {
                return this.loadActivities(this.$scope.locationId);
            }
        });

        this.$scope.$watch('pickedDate', (newDate: moment.Moment) => {
                this.loadData();
                this.$location.path('/app/activity-planner').search({
                    date: newDate.format('DD-MM-YYYY')
                });
            }, true);
    }

    private loadData(): IPromise<void> {
        this.setIsLoading(true);

        this.$scope.currentRange = this.dateRange(0, 6);

        return this.dataService.getLocations().then(response => {
            this.$scope.locations = response.locations;
            if (this.$scope.locationId == null) {
                this.$scope.locationId = this.$scope.locations[0].locationId;
            }

            this.locationHasKiosk = this.$scope.locations[0].hasActivityKiosk;

            return this.loadActivities(this.$scope.locationId);
        }).finally(() => this.setIsLoading(false));
    }

    private setIsLoading(isLoading: boolean) {
        this.$scope.isLoading = isLoading;
        this.loadingBar.show = isLoading;
    }

    private loadActivities(locationId: string): IPromise<void> {
        this.setIsLoading(true);

        const from = this.$scope.pickedDate.clone().startOf('week');
        const to = this.$scope.pickedDate.clone().endOf('week');

        return this.dataService.getActivitiesByDates(locationId, from, to).then(response => {
            this.$scope.activities = response.activityPlannings;
        }).finally(() => this.setIsLoading(false));
    }

    private selectToday() {
        this.$scope.pickedDate = moment();
    }

    private selectPreviousDay() {
        this.$scope.pickedDate.subtract(7, 'day');
    }

    private selectNextDay() {
        this.$scope.pickedDate.add(7, 'day');
    }

    private formatTime(date: string) {
        const momentDate = moment(date);
        return momentDate.format('HH:mm');
    }

    private getActivitiesForDate(date: moment.Moment) {
        return this.$scope.activities.filter(
            x => moment(x.start).format('DD-MM-YYYY') === date.format('DD-MM-YYYY')
        );
    }

    private onActivitySelected($event: IAngularEvent, planning: ChildCare.Definitions.Activity.ActivityPlanning) {
        $event.stopPropagation();
        this.editActivity(moment(planning.start), planning.activity, planning);
    }

    private displayDatePicker() {
        this.dateTimeDialog
            .show({
                currentDate: this.$scope.pickedDate,
                time: false,
                autoOk: true,
                disableParentScroll: true
            })
            .then(date => {
                this.$scope.pickedDate = moment(date);
            });
    }

    private onDateSelected(date: moment.Moment) {
        const differenceInDays = date.diff(moment(), 'days');
        if (differenceInDays < 0) {
            swal({
                title: 'U kunt niet in het verleden plannen!',
                timer: 2000,
                type: 'warning'
            });
            return;
        }

        const instance = this.$uibModal.open({
            animation: true,
            templateUrl: 'partials/activity/activity-selector.html',
            controller: 'kpSelectActivityController',
            backdrop: true,
            size: 'lg',
            keyboard: true,
            resolve: {
                locationId: () => ng.copy(this.$scope.locationId)
            }
        });

        return instance.result.then((activity: Activity) => {
            return this.editActivity(date, activity);
        });
    }

    private generateClassesForDate(date: moment.Moment) {

        const distance = date.clone().diff(moment().startOf('day'), 'days');

        if (distance === 0) {
            return 'day selected-day';
        } else if (distance > 0) {
            return 'day future';
        } else {
            return 'day past';
        }
    }

    private dateRange(min: number, max: number) {
        const input = [];

        const startOfWeek = this.$scope.pickedDate.clone().startOf('week');

        for (let i = min; i <= max; i += 1) {
            input.push(startOfWeek.clone().add(i, 'day'));
        }

        return input;
    }

    private editActivity(date: moment.Moment, activity: Activity, planning: ActivityPlanning = undefined) {
        const instance = this.
        $uibModal.open({
            animation: true,
            templateUrl: 'partials/activity/activity-edit.html',
            controller: 'kpEditActivityController',
            backdrop: true,
            size: 'lg',
            keyboard: true,
            resolve: {
                locationId: () => ng.copy(this.$scope.locationId),
                locationHasKiosk: () => this.locationHasKiosk,
                date: () => ng.copy(date),
                planning: () => ng.copy(planning),
                activity: () => activity
            }
        });

        return instance.result.then(() => {
            swal({
                title: 'Opgeslagen',
                text: 'De aanvraag is verwerkt!',
                timer: 3000,
                type: 'success'
            });

            // Probably refresh table & data here
            this.loadData();
        });
    }

    initializeScope() {
        this.setIsLoading(false);

        this.$scope.todayDate = moment();
        this.$scope.activities = [];
        this.$scope.pickedDate = this.$location.search().date ? moment(this.$location.search().date, 'DD-MM-YYYY') : moment();
    }
}

appModule.controller('kpActivityPlannerController', ActivityPlannerController);
