import { app, appModule, ft } from '@/definitions';
import { IPromise, IQService } from 'angular';
import { FortyTwoLoginService, FortyTwoRequest, FortyTwoRequestService } from '@/FortyTwoFramework';
import { ChildCare } from '@/data-models';
import moment from 'moment';
import { ApplicationInsightsService } from './application-insights-service';
import { HttpError } from './http-error';
import qs from 'qs';
import {OnStoryDeserialized, OnStoryTemplateDeserialized, Story, StoryBlockContent, StoryTemplate} from '@/stories/types';

type TimesRequest = ChildCare.Definitions.Registration.TimesRequest;
type Gender = ChildCare.Definitions.Common.Gender;
type RequestBeforeContractCommandTimes = ChildCare.Definitions.GroupApp.RequestBeforeContractCommandTimes;
type RequestBeforeContractDayCommand = ChildCare.Definitions.GroupApp.RequestBeforeContractDayCommand;
type CreateStudyDayRegistrationCommand = ChildCare.Definitions.Planning.CreateStudyDayRegistrationCommand;
type CreateOrUpdateActivityPlanningCommand = ChildCare.Definitions.Activity.CreateOrUpdateActivityPlanningCommand;
type LocationEmployeesResponse = ChildCare.Definitions.Locations.Responses.LocationEmployeesResponse;
type LocationsResponse = ChildCare.Definitions.Locations.Responses.LocationsResponse;
type TransportRidesResponse = ChildCare.Definitions.Transport.Responses.TransportRidesResponse;
type TransportChildrenResponse = ChildCare.Definitions.Transport.Responses.TransportChildrenResponse;
type TransportVehicleResponse = ChildCare.Definitions.Transport.Responses.TransportVehicleResponse;
type GroupsResponse = ChildCare.Definitions.GroupApp.GroupsResponse;
type CreateOrUpdateRidesCommand = ChildCare.Definitions.Transport.Commands.CreateOrUpdateRidesCommand;
type PresencesCommand = ChildCare.Definitions.GroupApp.PresencesCommand;
type PresencesResponse = ChildCare.Definitions.GroupApp.PresencesResponse;
type ActivityAddEmployeeCommand = ChildCare.Definitions.Activity.ActivityAddEmployeeCommand;
type ActivityAddGroupCommand = ChildCare.Definitions.Activity.ActivityAddGroupCommand;
type CreateOrUpdateAlbumCommand = ChildCare.Definitions.Media.CreateOrUpdateAlbumCommand;
type WriteLocationsResponse = ChildCare.Definitions.GroupApp.WriteLocationsResponse;
type WriteAudienceResponse = ChildCare.Definitions.GroupApp.WriteAudienceResponse;
type GroupRequestsApprovalResponse = ChildCare.Definitions.GroupApp.GroupRequestsApprovalResponse;

interface SetProfileImageCommand {
    type: string;
    id: string;
    content: string;
}

export interface IParent {
    parentId: string;
    lastName: string;
    firstName: string;
    lastNamePrefix: string;
    initials: string;
    birthDate: moment.Moment;
    gender: Gender;
    emailAddress: string;
    phoneNumber: string;
    mobileNumber: string;
    phoneWork: string;
}

export interface IAddress {
    street: string;
    streetNumber: string;
    postalCode: string;
    city: string;
    countryId: string;
}

export interface ICountry {
    countryId: string;
    code: string;
    name: string;
}

export interface IParentDetailResponse {
    parent: IParent;
    address: IAddress;
    countries: ICountry[];
}

export interface IParentDetailUpdateRequest {
    parentId: string;
    lastName: string;
    firstName: string;
    lastNamePrefix: string;
    initials: string;
    birthDate: moment.Moment | string;
    gender: Gender;
    emailAddress: string;
    phoneNumber: string;
    mobileNumber: string;
    phoneWork: string;
    street: string;
    streetNumber: string;
    postalCode: string;
    city: string;
    countryId: string;
}

export class AppDataService {
    public static $inject = ['$q', 'ftLoginService', 'ftRequest', 'ApplicationInsightsService'];

    private $q: IQService;
    private ftLoginService: FortyTwoLoginService;
    private ftRequest: FortyTwoRequestService;
    private applicationInsights: ApplicationInsightsService;

    constructor($q: IQService, ftLoginService: FortyTwoLoginService, ftRequest: FortyTwoRequestService, applicationInsights: ApplicationInsightsService) {
        this.$q = $q;
        this.ftLoginService = ftLoginService;
        this.ftRequest = ftRequest;
        this.applicationInsights = applicationInsights;
    }

    public doLoggedInRequest(request: FortyTwoRequest) {
        return this.ftLoginService.isLoggedInAsync()
            .then(() => this.doRequest(request, true));
    }

    public doRequest(request: FortyTwoRequest, loggedIn = false): IPromise<any> {
        return this.ftRequest.requestAsync(request, loggedIn).then(
            response => response.data,
            response => this.handleErrorResponse(response)
        );
    }

    private handleErrorResponse(response: any): IPromise<HttpError> {
        const data = response?.data;
        if (data == null) {
            return this.$q.reject(new HttpError(false, undefined, response.status));
        }

        const message = data.message ?? data.exceptionMessage;
        const isReadable = message != null;

        return this.$q.reject(new HttpError(isReadable, message, response.status));
    }

    public getActivity(activityId: string) {
        return this.doLoggedInRequest({
            method: 'GET',
            path: `activities/${activityId}/library`
        });
    }

    public setActivityToPeriodic(activityPlanningId: string, repeatUntil: string) {
        return this.doLoggedInRequest({
            method: 'POST',
            path: `activities/${activityPlanningId}/periodic`,
            data: {
                activityPlanningId,
                repeatUntil: repeatUntil,
                copyAssignedEmployees: true
            }
        });
    }

    public setActivityToSeries(activityPlanningId: string, repeatUntil: string) {
        return this.doLoggedInRequest({
            method: 'POST',
            path: `activities/${activityPlanningId}/series`,
            data: {
                activityPlanningId,
                repeatUntil: repeatUntil,
                copyAssignedEmployees: true
            }
        });
    }

    public addEmployeeToActivity(activityPlanningId: string, command: ActivityAddEmployeeCommand) {
        return this.doLoggedInRequest({
            method: 'POST',
            path: `activities/${activityPlanningId}/employees`,
            data: command
        });
    }

    public removeEmployeeFromActivity(activityPlanningId: string, employeeId: string) {
        return this.doLoggedInRequest({
            method: 'DELETE',
            path: `activities/${activityPlanningId}/employees/${employeeId}`
        });
    }

    public getEmployeesForActivityPlanning(activityPlanningId: string) {
        return this.doLoggedInRequest({
            method: 'GET',
            path: `activities/${activityPlanningId}/employees`
        });
    }

    public addGroupToActivity(activityPlanningId: string, command: ActivityAddGroupCommand) {
        return this.doLoggedInRequest({
            method: 'POST',
            path: 'activities/' + activityPlanningId + '/groups',
            data: command
        });
    }

    public removeGroupFromActivity(activityPlanningId: string, groupId: string) {
        return this.doLoggedInRequest({
            method: 'DELETE',
            path: `activities/${activityPlanningId}/groups/${groupId}`
        });
    }

    public getGroupsForActivityPlanning(activityPlanningId: string) {
        return this.doLoggedInRequest({
            method: 'GET',
            path: `activities/${activityPlanningId}/groups`
        });
    }

    public getActivityChildrenForGroups(groupIds: string[], date: moment.Moment, start: moment.Duration, end: moment.Duration): IPromise<{ children: ChildCare.Definitions.Child[] }> {
        const query = qs.stringify({
            groupIds,
            date: date.toISOString(true),
            start: start.toISOString(),
            end: end.toISOString()
        });

        return this.doLoggedInRequest({
            method: 'GET',
            path: `activities/children?${query}`
        });
    }

    public getEmployeesForLocation(locationId: string, date: moment.Moment): IPromise<LocationEmployeesResponse> {
        return this.doLoggedInRequest({
            path: `transport/${locationId}/employees?date=${date.format('YYYY-MM-DD')}`
        });
    }

    public getUser() {
        return this.doLoggedInRequest({
            path: 'account/validate',
            method: 'GET'
        });
    }

    public saveProfileImage(request: SetProfileImageCommand) {
        request.content = request.content.replace(/^data:image\/(png|jpg|jpeg);base64,/gi, '');
        return this.doLoggedInRequest({
            path: 'parent/profileimage',
            method: 'POST',
            data: request
        });
    }

    public getActivitiesByDates(locationId: string, from: moment.Moment, to: moment.Moment) {
        return this.doLoggedInRequest({
            path: `locations/${locationId}/activities/${from.format('YYYY-MM-DD')}/to/${to.format('YYYY-MM-DD')}`
        });
    }

    public getExistingActivityTypes(locationId: string) {
        return this.doLoggedInRequest({
            path: `locations/${locationId}/activities/library`
        });
    }

    public saveActivity(command: CreateOrUpdateActivityPlanningCommand, activityPlanningId: string | undefined) {
        return this.doLoggedInRequest({
            path: !activityPlanningId ? 'activities' : `activities/${activityPlanningId}`,
            method: !activityPlanningId ? 'POST' : 'PUT',
            data: command
        });
    }

    public deleteActivity(activityPlanningId: string) {
        return this.doLoggedInRequest({
            path: `activities/${activityPlanningId}`,
            method: 'DELETE'
        });
    }

    public saveTransportRides(command: CreateOrUpdateRidesCommand): IPromise<void> {
        return this.doLoggedInRequest({
            path: `transport/rides/${command.employeeId}`,
            method: 'POST',
            data: command
        });
    }

    public getTransportChildren(locationId: string, moment: moment.Moment): IPromise<TransportChildrenResponse> {
        return this.doLoggedInRequest({
            path: `transport/${locationId}/children/${moment.format('YYYY-MM-DD')}`
        });
    }

    public getTransportRides(locationId: string, moment: moment.Moment): IPromise<TransportRidesResponse> {
        return this.doLoggedInRequest({
            path: `transport/${locationId}/rides/${moment.format('YYYY-MM-DD')}`
        });
    }

    public getTransportVehicles(moment: moment.Moment): IPromise<TransportVehicleResponse> {
        return this.doLoggedInRequest({
            path: `transport/vehicles/${moment.format('YYYY-MM-DD')}`
        });
    }

    public getLocations(): IPromise<LocationsResponse> {
        return this.doLoggedInRequest({
            path: 'locations'
        });
    }

    public getTransportLocations(moment: moment.Moment): IPromise<ChildCare.Definitions.Transport.Responses.TransportLocationsResponse> {
        return this.doLoggedInRequest({
            path: `transport/locations/${moment.format('YYYY-MM-DD')}`
        });
    }

    public updateParentDetails(updateRequest: IParentDetailUpdateRequest) {
        if (typeof updateRequest.birthDate !== 'string') {
            updateRequest.birthDate = updateRequest.birthDate.format('DD/MM/YYYY');
        }

        return this.doLoggedInRequest({
            path: 'groups/parentdetail',
            method: 'POST',
            data: updateRequest
        });
    }

    public getParentDetails(parentId: string) {
        return this.doLoggedInRequest({
            path: 'groups/parentdetail?parentId=' + parentId,
            method: 'GET'
        });
    }

    public getConfigurationAsync() {
        return this.doRequest({
            path: 'account/configuration'
        });
    }

    public getGroupsAsync(): IPromise<GroupsResponse> {
        return this.doLoggedInRequest({ path: 'groups/groups' });
    }

    public getGroupsByLocation(locationId: string) {
        return this.doLoggedInRequest({
            path: `locations/${locationId}/groups`,
            method: 'GET'
        });
    }

    public reportChildAbsentAsync(command: ChildCare.Planning.Commands.ReportAbsent.Command) {
        return this.doLoggedInRequest({
            path: 'groups/reportchildabsent',
            method: 'POST',
            data: command
        });
    }

    public reportChildSickAsync(command: ChildCare.Planning.Commands.ReportAbsent.Command) {
        return this.doLoggedInRequest({
            path: 'groups/reportchildsick',
            method: 'POST',
            data: command
        });
    }

    public getPossibleIncidentalDays(childId: string, date: string) {
        return this.doLoggedInRequest({
            path: 'groups/' + childId + '/requestincidentalday?date=' + date,
            method: 'GET'
        });
    }

    public requestIncidentalDay(childId: string, remarks, date: string, groupId: string, useCreditHours, selectedTimes) {
        return this.doLoggedInRequest({
            path: 'groups/requestincidentalday',
            method: 'POST',
            data: {
                childId: childId,
                remarks: remarks,
                date: date,
                groupId: groupId,
                useCreditHours: useCreditHours,
                selectedTimes: selectedTimes
            }
        });
    }

    public getPossibleBeforeContractDays(childId: string, date: moment.Moment) {
        return this.doLoggedInRequest({
            path: 'groups/' + childId + '/requestbeforecontractday?date=' + date.format('YYYY-MM-DD'),
            method: 'GET'
        });
    }

    public requestBeforeContractDay(
        childId: string,
        remarks: string,
        date: moment.Moment,
        groupId: string,
        selectedTimes: RequestBeforeContractCommandTimes[]
    ) {
        const command: RequestBeforeContractDayCommand = {
            childId: childId,
            remarks: remarks,
            date: date.format('YYYY-MM-DD'),
            groupId: groupId,
            selectedTimes: selectedTimes
        };

        return this.doLoggedInRequest({
            path: 'groups/requestbeforecontractday',
            method: 'POST',
            data: command
        });
    }

    public getPossibleSwapDays(childId: string, fromDate: string, toDate) {
        return this.doLoggedInRequest({
            path: 'groups/' + childId + '/requestswapday?fromdate=' + fromDate + '&todate=' + toDate,
            method: 'GET'
        });
    }

    public requestSwapDay(childId: string, remarks, fromDate: string, toDate, groupId: string, selectedFromTimes, selectedToTimes) {
        return this.doLoggedInRequest({
            path: 'groups/requestswapday',
            method: 'POST',
            data: {
                childId: childId,
                remarks: remarks,
                fromDate: fromDate,
                toDate: toDate,
                groupId: groupId,
                selectedFromTimes: selectedFromTimes,
                selectedToTimes: selectedToTimes
            }
        });
    }

    public getRequestStudyDays(childId: string) {
        return this.doLoggedInRequest({
            path: 'groups/' + childId + '/requeststudyday',
            method: 'GET'
        });
    }

    public requestStudyDays(studyDay, childId: string, groupId: string, useCreditHours) {
        const command: CreateStudyDayRegistrationCommand = {
            childId: childId,
            date: studyDay.date,
            planningId: studyDay.planningId,
            studydayId: studyDay.id,
            groupId: groupId,
            useCreditHours: useCreditHours
        };

        return this.doLoggedInRequest({
            path: 'groups/requeststudyday',
            method: 'POST',
            data: command
        });
    }

    public getPossibleTransferGroups(childId: string, date: string) {
        return this.doLoggedInRequest({
            path: 'groups/' + childId + '/transfertogroup?date=' + date,
            method: 'GET'
        });
    }

    public requestTransferGroup(childId: string, date: string, groupId: string, selectedTimes) {
        return this.doLoggedInRequest({
            path: 'groups/transfertogroup',
            method: 'POST',
            data: {
                childId: childId,
                date: date,
                groupId: groupId,
                selectedTimes: selectedTimes
            }
        });
    }

    public getCalendarTimeline(groupId: string, date: string) {
        return this.doLoggedInRequest({
            path: 'groups/' + groupId + '/timeline?date=' + date,
            method: 'GET'
        });
    }

    public getRequestList(groupId: string) {
        return this.doLoggedInRequest({
            path: 'groups/' + groupId + '/requests/list',
            method: 'GET'
        });
    }

    public getRequestDetails(groupId: string, registrationId: string, typeOfRegistration): IPromise<GroupRequestsApprovalResponse> {
        return this.doLoggedInRequest({
            path:
                'groups/' +
                groupId +
                '/requests/approval?registrationId=' +
                registrationId +
                '&typeOfRegistration=' +
                typeOfRegistration,
            method: 'GET'
        });
    }

    public sendRequestAction(groupId: string, registrationId: string, typeOfRegistration, action, remarks) {
        return this.doLoggedInRequest({
            path: 'groups/' + groupId + '/requests/approve',
            method: 'POST',
            data: {
                registrationId: registrationId,
                typeOfRegistration: typeOfRegistration,
                action: action,
                remarks: remarks
            }
        });
    }

    public getGroupTimelineAsync(groupId: string, startIndex?: string | number, pageSize?: string | number) {
        pageSize = pageSize || 50;
        startIndex = startIndex || 0;

        let path = 'groups/' + groupId + '/mediatimeline';
        const qs: string[] = [];

        if (ft.hasValue(startIndex)) {
            qs.push('startindex=' + startIndex);
        }

        if (ft.hasValue(pageSize)) {
            qs.push('pagesize=' + pageSize);
        }

        if (qs.length) {
            path += '?' + qs.join('&');
        }

        const obj = {
            path: path
        };

        return this.doLoggedInRequest(obj);
    }

    public getAudiencesAsync(childId: string): IPromise<WriteAudienceResponse> {
        const path = childId ? `posts/audiences?childId=${childId}` : 'posts/audiences';
        return this.doLoggedInRequest({
            path: path,
            method: 'GET'
        });
    }

    public getMediaForAlbumAsync(albumId: string): IPromise<ChildCare.Definitions.Media.MediaListResponse> {
        return this.doLoggedInRequest({
            path: `media/album/${albumId}`,
            method: 'GET'
        });
    }

    public getMediaAlbumsAsync(showAll: boolean): IPromise<ChildCare.Definitions.GroupApp.MediaAlbumsResponse> {
        return this.doLoggedInRequest({
            path: `media/albums?showAllAlbums=${showAll}`,
            method: 'GET'
        });
    }

    public addAlbumAsync(title: string, description: string): IPromise<CreateOrUpdateAlbumCommand> {
        return this.doLoggedInRequest({
            path: 'media/albums',
            method: 'POST',
            data: { title, description } as CreateOrUpdateAlbumCommand
        });
    }

    public getAlbumsUntilAsync(dateTime: moment.Moment): IPromise<ChildCare.Definitions.Media.AlbumsListResponse> {
        return this.doLoggedInRequest({
            path: 'albums/until/' + dateTime.utc().format('YYYY-MM-DDTHH-mm-ss') + '/' + app.settings.mediaAlbums.fetchRange,
            method: 'GET'
        });
    }

    public getAlbumsSinceAsync(dateTime: moment.Moment): IPromise<ChildCare.Definitions.Media.AlbumsListResponse> {
        return this.doLoggedInRequest({
            path: 'albums/since/' + dateTime.utc().format('YYYY-MM-DDTHH-mm-ss'),
            method: 'GET'
        });
    }

    public getMessagesAsync(dateTime: string, childId: string): IPromise<ChildCare.Definitions.GroupApp.PostsResponse> {
        return this.doLoggedInRequest({
            path:
                'posts/until/' +
                (childId ? childId + '/' : '') +
                dateTime +
                '/' +
                app.settings.conversations.fetchRange,
            method: 'GET'
        });
    }

    public getMessagesSinceAsync(dateTime: string, childId: string): IPromise<ChildCare.Definitions.GroupApp.PostsResponse> {
        return this.doLoggedInRequest({
            path: 'posts/since/' + (childId ? childId + '/' : '') + dateTime,
            method: 'GET'
        });
    }

    public getReminderFor(childId: string, date: string): IPromise<ChildCare.Definitions.GroupApp.PostsResponse> {
        return this.doLoggedInRequest({
            path: 'posts/reminders/' + childId + '/' + date,
            method: 'GET'
        });
    }

    public getPost(message): IPromise<ChildCare.Definitions.GroupApp.PostResponse> {
        return this.doLoggedInRequest({
            path: 'posts/post/' + message.postId,
            method: 'GET'
        });
    }

    public getDiaryPresence(groupId: string, date: string): IPromise<ChildCare.Definitions.Family.DiaryPresencesResponse> {
        return this.doLoggedInRequest({
            path: `diary/presences/${groupId}?date=${date}`,
            method: 'GET'
        });
    }

    public getDiaryEntryTypes(): IPromise<ChildCare.Definitions.Family.DiaryEntryTypesResponse> {
        return this.doLoggedInRequest({
            path: 'diary/types',
            method: 'GET'
        });
    }

    public saveDiaryEntry(childIds: string[], diaryEntryTypeId: string, text: string, time: moment.Moment, diaryScheduleId: string): IPromise<any> {

        const tasks = [];

        for (const childId of childIds) {
            tasks.push(this.doLoggedInRequest({
                path: `children/${childId}/diaries`,
                method: 'POST',
                data: {
                    diaryEntryTypeId: diaryEntryTypeId,
                    entryText: text,
                    entryMoment: time != null ? time.format('YYYY/MM/DD HH:mm:SS') : null,
                    diaryScheduleId: diaryScheduleId
                }
            }));
        }

        return this.$q.all(tasks);
    }

    public deleteDiaryEntry(childId: string, diaryEntryId: string): IPromise<any> {
        return this.doLoggedInRequest({
            path: `children/${childId}/diaries/${diaryEntryId}`,
            method: 'DELETE'
        });
    }

    public getDocumentsForChild(childId: string) {
        return this.doLoggedInRequest({
            method: 'GET',
            path: 'child/' + childId + '/documents?externalSystem=groupapp'
        });
    }

    public getGroupActivitiesAsync(groupId: string) {
        let item;
        const items = [];
        let i = 0;
        const max = 50;

        while (i < max) {
            item = {
                id: 'id-' + i,
                createdOn: '2017-08-16 11:32',
                icon: 'zwemmen',
                title: 'Activiteit ' + i,
                description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras et ipsum risus. Aliquam eu dignissim lorem. Pellentesque tincidunt, ipsum nec rhoncus feugiat, dolor tellus euismod magna, id ullamcorper eros felis eget leo.',
                developmentArea: 'Creatieve ontwikkeling',
                activityArea: 'Sport',
                minimalAge: 2,
                maximumAge: 4
            };

            items.push(item);
            i += 1;
        }

        const dfd = this.$q.defer();
        dfd.resolve(items);
        return dfd.promise;
    }

    public saveConversationAsync(message, audiences, childId: string) {
        const path = 'posts/post';

        return this.doLoggedInRequest({
            path: path,
            method: 'POST',
            data: {
                title: message.title,
                content: message.content,
                reminderDate: message.reminderDate,
                audiences: audiences,
                childId: childId
            }
        });
    }

    public answerToPostAsync(conversation: ChildCare.Definitions.GroupApp.PostResponse | ChildCare.Definitions.GroupApp.PostsResponseItem, replyComment: ChildCare.Definitions.GroupApp.PostComment, answer: string): IPromise<void> {
        return this.doLoggedInRequest({
            path: 'posts/post',
            method: 'POST',
            data: {
                postId: conversation.postId,
                content: answer,
                commentId: replyComment ? replyComment.commentId : null
            }
        });
    }

    public getAudiences(locations: ChildCare.Definitions.GroupApp.WriteLocation[], careTypes: ChildCare.Definitions.GroupApp.WriteLocationCareType[]): IPromise<ChildCare.Definitions.GroupApp.LocationsToAudiencesResponse> {
        return this.doLoggedInRequest({
            path: 'groups/audiences',
            method: 'POST',
            data: {
                locations: locations.map(x => x.id),
                careTypes: careTypes.map(x => x.careType),
                date: undefined
            } as ChildCare.Definitions.GroupApp.LocationsToAudiencesRequest
        });
    }

    public setPasswordAsync(accessKey, password) {
        return this.doRequest({
            path: 'account/setpassword' + (!password ? '?accessKey=' + accessKey : ''),
            method: !password ? 'GET' : 'POST',
            data: !password ? null : { accessKey: accessKey, password: password }
        });
    }

    public resetPasswordAsync(userName) {
        return this.doRequest({
            path: 'account/resetpassword',
            method: 'POST',
            data: { userName: userName }
        });
    }

    public oauthLoginAsync(token: string) {
        const request: FortyTwoRequest = {
            path: 'account/token',
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        return this.doRequest(request);
    }

    public changePasswordAsync(originalPassword, newPassword) {
        return this.doLoggedInRequest({
            path: 'account/changepassword',
            method: 'POST',
            data: {
                originalPassword: originalPassword,
                newPassword: newPassword
            }
        });
    }

    public setLanguageAsync(languageId: string) {
        return this.doLoggedInRequest({
            path: 'account/setlanguage',
            method: 'POST',
            data: {
                languageId: languageId
            }
        });
    }

    public getEmergencyList(groupId: string = null) {
        return this.doLoggedInRequest({
            path: 'groups/childrenonlocation' + (groupId === null ? '' : '?groupId=' + groupId),
            method: 'GET'
        });
    }

    public getChildInfo(childId: string): IPromise<ChildCare.Definitions.GroupApp.PresencesChildInfoResponse> {
        return this.doLoggedInRequest({
            path: 'groups/' + childId + '/presenceschildinfo',
            method: 'GET'
        });
    }

    public getPresenceOverviewData(groupId: string, date: moment.Moment, fetchAllChildren?: boolean): IPromise<PresencesResponse> {
        return this.doLoggedInRequest({
            path:
                'groups/' +
                groupId +
                '/presences?date=' +
                date.format('YYYY-MM-DD') +
                '&fetchallchildren=' +
                fetchAllChildren,
            method: 'GET'
        });
    }

    public getPlanning(childId: string, month, year) {
        return this.doLoggedInRequest({
            path: 'child/' + childId + '/planning',
            method: 'POST',
            data: {
                childId: childId,
                month: month,
                year: year
            }
        });
    }

    public savePresence(command: PresencesCommand): IPromise<ChildCare.Definitions.GroupApp.PresencesCommandResult> {
        this.applicationInsights.trackEvent('Creating presences', command);

        return this.doLoggedInRequest({
            path: 'groups/presences',
            method: 'POST',
            data: command
        });
    }

    public saveChildInfo(command: ChildCare.Definitions.GroupApp.PresencesUpdateChildInfoCommand): IPromise<void> {
        return this.doLoggedInRequest({
            path: 'groups/presencesupdatechildinfo',
            method: 'POST',
            data: command
        });
    }

    public saveMedicalInfo(command: ChildCare.Definitions.GroupApp.PresencesUpdateMedicalInfoCommand): IPromise<void> {
        return this.doLoggedInRequest({
            path: 'groups/presencesupdatemedicalinfo',
            method: 'POST',
            data: command
        });
    }

    public switchGroup(groupId: string) {
        return this.doLoggedInRequest({
            path: 'groups/' + groupId + '/switch',
            method: 'GET'
        });
    }

    public getAvailableTimes(childId: string, timesRequest: TimesRequest) {
        const httpRequest: FortyTwoRequest = {
            path: `child/${childId}/times`,
            method: 'POST',
            data: timesRequest
        };

        return this.doLoggedInRequest(httpRequest);
    }

    public getChildLabels(childId: string): IPromise<ChildCare.Definitions.GroupApp.ChildLabelResponse> {
        return this.doLoggedInRequest({
            path: `child/${childId}/labels`,
            method: 'GET'
        });
    }

    public getAllChildLabels(): IPromise<ChildCare.Definitions.GroupApp.ChildLabelTypesResponse> {
        return this.doLoggedInRequest({
            path: 'child/labels',
            method: 'GET'
        });
    }

    public addChildLabel(childId: string, labelId: string, description: string = null): IPromise<void> {
        const command: ChildCare.Definitions.GroupApp.AddChildLabelCommand = {
            childId,
            labelId,
            description
        };

        return this.doLoggedInRequest({
            path: `child/${childId}/labels`,
            method: 'POST',
            data: command
        });
    }

    public removeChildLabel(childId: string, labelId: string): IPromise<void> {
        return this.doLoggedInRequest({
            path: `child/${childId}/labels/${labelId}`,
            method: 'DELETE'
        });
    }

    public getWriteLocations(): IPromise<WriteLocationsResponse> {
        return this.doLoggedInRequest({
            path: 'groups/writelocations',
            method: 'GET'
        });
    }

    public saveLocationConversation(message, audiences: string[], caretypes: string[]) {
        const command = {
            title: message.title,
            content: message.content,
            audiences: audiences,
            caretypes: caretypes
        };

        return this.doLoggedInRequest({
            path: 'groups/locationpost',
            method: 'POST',
            data: command
        });
    }

    public getStories(groupId: string): IPromise<Story[]> {
        return this.doLoggedInRequest({
            path: `stories/${groupId}`,
            method: 'GET'
        }).then(stories => {
            for (const story of stories) {
                OnStoryDeserialized(story);
            }

            return stories;
        });
    }

    public getStoryTemplatesForGroup(groupId: string): IPromise<StoryTemplate[]> {
        return this.doLoggedInRequest({
            path: `storytemplates/${groupId}`,
            method: 'GET'
        }).then(storyTemplates => {
            for (const storyTemplate of storyTemplates) {
                OnStoryTemplateDeserialized(storyTemplate);
            }
            return storyTemplates;
        });
    }

    public createStory(storyTemplateId: string, groupId: string): IPromise<string> {
        const story = {
            storyTemplateId: storyTemplateId,
            groupId: groupId
        };
        return this.doLoggedInRequest({
            path: 'story',
            method: 'POST',
            data: story
        });
    }

    public updateStory(story: Story): IPromise<void> {
        return this.doLoggedInRequest({
            path: `story/${story.id}`,
            method: 'PUT',
            data: story
        });
    }

    public getStory(storyId: string): IPromise<Story> {
        return this.doLoggedInRequest({
            path: `story/${storyId}`,
            method: 'GET'
        }).then(story => {
            OnStoryDeserialized(story);
            return story;
        });
    }

    public getStoryPdf(storyId: string): IPromise<string> {
        return this.doLoggedInRequest({
            path: `storypreview/${storyId}?childId=00000000-0000-0000-0000-000000000000`,
            method: 'GET'
        }).then(story => {
            return story;
        });
    }

    public saveContentBlock(storyId: string, storyBlockId: string, content: StoryBlockContent): IPromise<void> {
        return this.doLoggedInRequest({
            path: `story/${storyId}/${storyBlockId}`,
            method: 'POST',
            data: content
        });
    }
}

appModule.service('AppDataService', AppDataService);
