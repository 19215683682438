import { appModule } from '@/definitions';
import swal from 'bootstrap-sweetalert';

import { Child } from '@/child-factory';
import { PresencesStatus } from '@/enums';
import moment from 'moment';
import { AppDataService } from '@/services/app-data-service';
import { ChildCare } from '@/data-models';

type PresencesCommand = ChildCare.Definitions.GroupApp.PresencesCommand;

appModule.controller('kpPresenceChildModalController',
    ['$scope', '$timeout', 'AppDataService', '$uibModalInstance', 'child', 'bulk', 'selectedGroup', 'date', '$translate', 'mdcDateTimeDialog',
        function ($scope, $timeout, DataService: AppDataService, $uibModalInstance, child: Child | Child[], bulk, selectedGroup, date, $translate, mdcDateTimeDialog) {
            var initialized = false;

            $scope.bulk = bulk;
            $scope.child = bulk ? child[0] : child;

            $scope.isLoading = false;
            $scope.isPresent = $scope.child.status === PresencesStatus.Present;

            $scope.PresencesStatus = PresencesStatus;

            $scope.model = {};
            $scope.model.isValid = true;
            $scope.model.pickedTime = moment();
            $scope.model.pickedStatus = $scope.child.status === PresencesStatus.Present
                ? PresencesStatus.PickedUp : PresencesStatus.Present;

            $scope.model.comments = null;
            $scope.statuses = [];
            $scope.isLoading = false;

            var initialize = function () {
                if (initialized) {
                    return;
                }

                $scope.statuses = getAvailableStatussen();

                if (Array.isArray(child)) {
                    child.forEach(function (kid) {
                        setArrivedAt(kid);
                    });
                } else {
                    setArrivedAt(child);
                }

                $scope.isLoading = false;
                initialized = true;
            };

            const getAvailableStatussen = () => {
                switch ($scope.child.status) {
                    case PresencesStatus.Expected:
                        return [PresencesStatus.Present, PresencesStatus.Sick, PresencesStatus.NotExpected];

                    case PresencesStatus.Present:
                        return [PresencesStatus.Sick, PresencesStatus.PickedUp];

                    case PresencesStatus.Absent:
                    case PresencesStatus.PickedUp:
                        return [PresencesStatus.Present];

                    case PresencesStatus.NotExpected:
                    case PresencesStatus.Sick:
                        return [PresencesStatus.Present];
                }
            };

            $scope.isToday = function () {
                return moment().startOf('day').diff(date.startOf('day'), 'day') === 0;
            };

            $scope.showStatusChoices = () => $scope.statuses.length > 0;

            $scope.formatStatusText = function (status) {
                switch (status) {
                    case PresencesStatus.Present:
                        return 'Aanwezig';
                    case PresencesStatus.Sick:
                        return 'Ziek';
                    case PresencesStatus.PickedUp:
                        return 'Opgehaald';
                    case PresencesStatus.Absent:
                    case PresencesStatus.NotExpected:
                        return 'Afwezig';
                    case PresencesStatus.Expected:
                        return 'Verwacht';
                }
            };

            var setArrivedAt = function (child: Child) {

                for (const presence of child.presences) {
                    if (presence.startTime && !presence.endTime) {
                        child.arrivedAt = moment(presence.startTime);
                    }
                }
            };

            var validatePickedTime = function (kid: Child) {
                if (kid.arrivedAt && $scope.model.pickedTime.format('HH:mm:SS') < kid.arrivedAt.format('HH:mm:SS')) {
                    console.log('the picked time was under the start time of this event, impossible!');
                    return false;
                }

                return true;
            };

            $scope.hasDate = (time: moment.Duration | string) => {
                return time != null && $scope.showTime(time) !== '00:00';
            };

            function isMoment(time: moment.Duration | string): time is moment.Duration {
                return (time as moment.Duration).abs !== undefined;
            }

            $scope.showTime = function (time: moment.Duration | string): string {
                if (isMoment(time)) {
                    return moment.utc(time.asMilliseconds()).format('HH:mm');
                } else {
                    return moment.utc(moment.duration(time).asMilliseconds()).format('HH:mm');
                }
            };

            $scope.displayDialog = () => {
                mdcDateTimeDialog.show({
                    date: false,
                    currentDate: $scope.model.pickedTime,
                    minuteSteps: 1,
                    autoOk: true,
                    disableParentScroll: true
                }).then((date) => {
                    $scope.model.pickedTime = moment(date);
                });
            };

            $scope.save = function () {
                const childIds = new Array<string>();

                $scope.model.isValid = true;

                // TODO: UI Error maken als tijd < dan picked tijd
                if (Array.isArray(child)) {
                    child.forEach(function (kid) {
                        if (!validatePickedTime(kid)) {
                            $scope.model.isValid = false;
                        }
                    });
                } else {
                    if (!validatePickedTime(child)) {
                        $scope.model.isValid = false;
                    }
                }

                if ($scope.model.pickedStatus == null || !$scope.model.isValid) {
                    return;
                }

                $scope.child.arrivedAt = $scope.model.pickedTime;

                if (Array.isArray(child)) {
                    child.forEach(function (kid) {
                        kid.status = $scope.model.pickedStatus;
                        childIds.push(kid.id);
                    });
                } else {
                    childIds.push(child.id);
                }

                const command = {
                    groupId: selectedGroup,
                    childIds: childIds,
                    action: $scope.model.pickedStatus,
                    date: date.format('YYYY-MM-DD'),
                    time: $scope.model.pickedTime.format('HH:mm'),
                    remarks: $scope.model.comments
                } as PresencesCommand;

                $scope.isLoading = true;
                DataService.savePresence(command)
                    .then((result) => {

                        if (!result.isSuccess) {
                            swal({
                                title: $translate.instant('ERRORS.SAVE-FAILED.TITLE'),
                                text: $translate.instant('PRESENCE.SAVE.FAILED'),
                                timer: 2500,
                                type: 'error'
                            });
                        }

                        $scope.isLoading = false;
                        $uibModalInstance.close('saved');
                    });
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss();
            };

            $timeout(initialize);
        }
    ]);
