import { layoutSettings, appModule, frameworkEvents, app, layoutEvents, componentsModels } from '@/definitions';
import { RoleChecker } from '@/role-checker';
import { FeatureService, Feature } from '@/services/feature-service';
import { FortyTwoLoginService, FortyTwoRootScopeService } from '@/FortyTwoFramework';
import { IStateService } from 'angular-ui-router';
import { IScope, ITimeoutService, element } from 'angular';

interface LocalScope extends IScope {
    currentUser: any;    showCurrentUser: boolean;
    isLoading: boolean;
    hasScroll: boolean;
    layout: any;
    layoutSettings: any;
    sidebarToggle: { left: boolean; right: boolean };
    menuItems: any[];
    assistent: any;
    raiseStateRefresh: () => void;
    sidebarStat: (event: any) => void;
}

appModule.controller('ftAppContentController', ['$rootScope', '$scope', '$timeout', '$state', 'ftLoginService', '$filter', 'FeatureService',
    function ($rootScope: FortyTwoRootScopeService, $scope: LocalScope, $timeout: ITimeoutService, $state: IStateService, ftLoginService: FortyTwoLoginService, $filter, featureService: FeatureService) {

            var initialized = false;
            var offNavigationBack, offAssistentOpen, offAssistentClose, offStateChangeSuccess, offDestroy;

            var previousState = {};

            /////////////////////////////////
            //////  Framework voodoo  ///////
            /////////////////////////////////

            $scope.currentUser = null; // This is the user shown in the side menu top left corner, the framework retrieves this value
            $scope.showCurrentUser = true; // This is the flag to indicate if the user must be shown in the side menu top left corner, the framework retrieves this value

            /////////////////////////////////
            ///  End of framework voodoo  ///
            /////////////////////////////////

            $scope.isLoading = false;
            $scope.hasScroll = true;
            $scope.layout = layoutSettings.layout.page;
            $scope.layoutSettings = layoutSettings.layout;
            $scope.sidebarToggle = { left: false, right: false };
            $scope.menuItems = [];
            $scope.assistent;

            $scope.raiseStateRefresh = function () {
                $rootScope.$broadcast(frameworkEvents.state.refresh);
            };

            $scope.sidebarStat = function (event) {
                if (!element(event.target).parent().hasClass('active')) {
                    $scope.sidebarToggle.left = false;
                }
            };

            const initialize = function () {
                if (initialized) {
                    return;
                }

                $scope.isLoading = true;

                setLayoutProperties();
                initializeEvents();
                initializeMenu().finally(() => {
                    $scope.isLoading = false;
                });

                initialized = true;
            };

            const initializeEvents = function () {
                offNavigationBack = $rootScope.$on(app.navigation.back, handleNavigationBack);
                offAssistentOpen = $rootScope.$on(layoutEvents.assistent.open, handleAppAssistentOpen);
                offAssistentClose = $rootScope.$on(layoutEvents.assistent.close, handleAppAssistentClose);
                offStateChangeSuccess = $rootScope.$on('$stateChangeSuccess', handleStateChangeSuccess);
                offDestroy = $scope.$on('$destroy', handleDestroy);
            };

            const initializeMenu = (): ng.IPromise<void> => {

                // add menu items based on role of the user
                const items = [];

                const groupMedia = new componentsModels.MenuItem({
                    icon: 'zmdi-collection-image',
                    text: $filter('translate')('NAVIGATION.MESSAGING-AND-MEDIA'),
                    ref: 'app.groupmedia'
                });

                const presenceOverview = new componentsModels.MenuItem({
                    icon: 'zmdi-accounts-list',
                    text: $filter('translate')('NAVIGATION.PRESENCE'),
                    ref: 'app.presence'
                });

                const calendar = new componentsModels.MenuItem({
                    icon: 'zmdi-calendar',
                    text: $filter('translate')('NAVIGATION.CALENDAR'),
                    ref: 'app.calendar'
                });

                const requests = new componentsModels.MenuItem({
                    icon: 'zmdi-receipt',
                    text: $filter('translate')('NAVIGATION.REQUESTS'),
                    ref: 'app.requests'
                });

                const diaryOverview = new componentsModels.MenuItem({
                    icon: 'zmdi-book',
                    text: $filter('translate')('NAVIGATION.DIARY'),
                    ref: 'app.diary'
                });

                const travelPlanning = new componentsModels.MenuItem({
                    icon: 'zmdi-car-taxi',
                    text: $filter('translate')('NAVIGATION.TRAVEL-PLANNING'),
                    ref: 'app.planner'
                });

                const activityPlanner = new componentsModels.MenuItem({
                    icon: 'zmdi-run',
                    text: $filter('translate')('NAVIGATION.ACTIVITY-PLANNING'),
                    ref: 'app.activity'
                });

                const locationManager = new componentsModels.MenuItem({
                    icon: 'zmdi-local-store',
                    text: $filter('translate')('NAVIGATION.LOCATIONMANAGER'),
                    ref: 'app.locationmanager'
                });

                const stories = new componentsModels.MenuItem({
                    icon: 'zmdi-collection-bookmark',
                    text: $filter('translate')('NAVIGATION.STORIES'),
                    ref: 'app.stories'
                });

                const childrenList = new componentsModels.MenuItem({
                    icon: 'zmdi-accounts',
                    text: $filter('translate')('NAVIGATION.CHILDREN'),
                    ref: 'app.emergency'
                });

                return ftLoginService.getCurrentUserAsync().then(user => {

                    // This is the user shown in the side menu top left corner
                    $scope.currentUser = user;

                    if (RoleChecker.hasGroupRole(user) || RoleChecker.hasAdminRole(user)) {
                        items.push(presenceOverview);
                        items.push(groupMedia);
                        items.push(calendar);
                        items.push(diaryOverview);
                        items.push(requests);
                        items.push(childrenList);
                    }

                    if (RoleChecker.hasPlannerRole(user) || RoleChecker.hasAdminRole(user)) {
                        featureService.isEnabled(Feature.TravelPlanner).then(isEnabled => {
                            if (isEnabled) {
                                items.push(travelPlanning);
                            }
                        });

                        featureService.isEnabled(Feature.ActivityPlanner).then(isEnabled => {
                            if (isEnabled) {
                                items.push(activityPlanner);
                            }
                        });
                    }

                    if (RoleChecker.hasLocationManager(user) || RoleChecker.hasAdminRole(user)) {
                        featureService.isEnabled(Feature.LocationMessages).then(isEnabled => {
                            if (isEnabled) {
                                items.push(locationManager);
                            }
                        });
                    }

                    featureService.isEnabled(Feature.Stories).then(isEnabled => {
                        if (isEnabled) {
                            items.push(stories);
                        }
                    });

                    $scope.menuItems = items;
                });
            };

            const handleNavigationBack = function () {

                if (!previousState || !(previousState as any).state) {
                    return;
                }

                $state.go((previousState as any).state, (previousState as any).params);
            };

            const handleAppAssistentOpen = function (_evt: any, obj: any) {
                if (!obj) {
                    return;
                }

                $scope.assistent = obj;

                $timeout(function () {
                    $scope.sidebarToggle.right = true;
                }, 50);
            };

            const handleAppAssistentClose = function () {
                $scope.sidebarToggle.right = false;

                $timeout(function () {
                    $scope.assistent = null;
                }, 50);
            };

            const handleStateChangeSuccess = function (_evt, _toState, _toStateParams, fromState, fromStateParams) {
                $scope.sidebarToggle.left = false;
                setLayoutProperties();

                previousState = {
                    state: fromState,
                    params: fromStateParams
                };
            };

            const handleDestroy = function () {
                if (offNavigationBack) {
                    offNavigationBack();
                }

                if (offAssistentOpen) {
                    offAssistentOpen();
                }
                if (offAssistentClose) {
                    offAssistentClose();
                }
                if (offStateChangeSuccess) {
                    offStateChangeSuccess();
                }
                if (offDestroy) {
                    offDestroy();
                }
            };

            const setLayoutProperties = function () {
                const current = $state.current as any;
                $scope.layout = current && current.layout || layoutSettings.layout.page;
                $scope.hasScroll = current && current.hasScroll;
            };

            $timeout(initialize);
        }
    ]);
