import { IController, IScope, ITimeoutService, IFilterService } from 'angular';
import { appModule, ft } from '@/definitions';
import { AppDataService } from '@/services/app-data-service';
import { ftSettingsService, FortyTwoLoginService } from '@/FortyTwoFramework';
import { ILoadingBar } from '@/models';
import { RoleChecker } from '@/role-checker';
import {IStateService} from 'angular-ui-router';
import {Story} from '@/stories/types';
import { loadOptions } from '@babel/core';

interface Scope extends IScope {
    navigateToGroups: any;
    group: string;
    stories: Story[];
    tableSorting: any;

    openStory(story: Story);
    previewStory(story: Story);
    newStoryModal();
    cancel: () => void;

    previewMode: boolean;
    previewUrl: string;

    endPreview: () => void;
    selectedText: string;
    searchText: any;
}

class StoriesListController implements IController {
    private $scope: Scope;
    private dataService: AppDataService;
    private settingsService: ftSettingsService;
    private loadingBar: ILoadingBar;
    private $state: IStateService;
    private ngTableParams: any;
    private $filter: IFilterService;
    private $uibModal: any;

    public static $inject = ['$scope', '$timeout', '$state', 'AppDataService', '$uibModal', 'ftSettingService', 'LoadingBar', 'ngTableParams', '$filter', RoleChecker.serviceName, 'ftLoginService', '$document'];
    constructor($scope: Scope, $timeout: ITimeoutService, $state: IStateService, dataService: AppDataService, $uibModal, settingsService: ftSettingsService, loadingBar: ILoadingBar, ngTableParams: any, $filter: IFilterService, roleCheckerService: RoleChecker, loginService: FortyTwoLoginService, $document) {
        this.$scope = $scope;
        this.dataService = dataService;
        this.settingsService = settingsService;
        this.loadingBar = loadingBar;
        this.$state = $state;
        this.ngTableParams = ngTableParams;
        this.$filter = $filter;
        this.$uibModal = $uibModal;
        this.$scope.previewMode = false;
        this.$scope.previewUrl = '';
        this.$scope.previewStory = this.previewStory.bind(this);
        this.$scope.endPreview = this.endPreview.bind(this);
        this.$scope.selectedText = '';
        this.$scope.searchText = {};

        loginService.getCurrentUserAsync().then(user => {
            this.$scope.group = user.name;
        });

        this.$scope.navigateToGroups = roleCheckerService.switchGroup;
        this.$scope.openStory = this.openStory.bind(this);
        this.$scope.newStoryModal = this.newStoryModal.bind(this);

        $timeout(this.load.bind(this), 200);
    }

    private async load(): Promise<void> {
        const groupId = this.settingsService.get(window.appSettings.group.selected);
        this.loadingBar.show = true;

        try {
            this.$scope.stories = await this.dataService.getStories(groupId);
        } catch (error) {
            console.error('fetching stories failed');
            console.error(error);
        } finally {
            this.loadingBar.show = false;
        }

        this.$scope.tableSorting = new this.ngTableParams({ count: 10 }, {
            total: this.$scope.stories.length,
            getData: ($defer, params) => {
                const orderedData = params.sorting() ? this.$filter('orderBy')(this.$scope.stories, params.orderBy()) : this.$scope.stories;
                $defer.resolve(orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));

                // set total for recalc pagination
                params.total(this.$scope.stories.length);
            }
        });
    }

    private async openStory(story: Story) {
        this.$state.go('app.story-details', { id: story.id });
    }

    private async newStoryModal() {
        const instance = this.$uibModal.open({
            animation: true,
            templateUrl: 'partials/stories/storyNewModal.html?v=' + ft.randomNumber,
            controller: 'kpStoryNewModalController',
            size: 'large',
            backdrop: true,
            keyboard: true,
            resolve: {
                group: () => this.$scope.group
            }
        });
        instance.result.then(async () => {
            await this.load();
        });

        
    }

    private endPreview(): void {
        this.$scope.previewMode = false;
    }

    private async previewStory(story: Story) {
        this.dataService.getStoryPdf(story.id).then(url => {
            this.$scope.previewUrl = url;
            this.$scope.previewMode = true;
            this.$scope.selectedText = story.name;            
        });
    }
}

appModule.controller('StoriesListController', StoriesListController);