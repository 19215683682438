import { appModule } from '@/definitions';
import { ILocationService, IScope } from 'angular';
import { IStateService } from 'angular-ui-router';
import { UrlHelper } from '@/urlHelper';
import { IFortyTwoDropzoneRootScopeService } from '@/directives/dropzone';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { AppDataService } from '@/services/app-data-service';
import { ChildCare } from '@/data-models';

type MediaListResponse = ChildCare.Definitions.Media.MediaListResponse;

interface ILocalScope extends IScope {
    media: MediaItem[];
    close: () => void;
    playVideo: (mediaItem: MediaItem) => void;
    baseUrl: string;
}

interface MediaItem {
    url: string;
    videoUrl: string;
    albumId: string;
    mediaId: string;
    thumbUrl: string;
    caption: string;
    type: ChildCare.Definitions.Common.MediaType;
}

appModule.controller('kpAlbumOverviewModalController',
    ['$rootScope', '$scope', '$state', '$uibModalInstance', 'album', '$localStorage', 'AppDataService', '$location',
        function ($rootScope: IFortyTwoDropzoneRootScopeService, $scope: ILocalScope, $state: IStateService, $uibModalInstance: IModalInstanceService, album: ChildCare.Definitions.Media.AlbumsListResponseItem, $localStorage, DataService: AppDataService, $location: ILocationService) {
            const token = $localStorage.refreshToken;

            function initialize() {
                DataService.getMediaForAlbumAsync(album.id)
                    .then(handleResponse);

                $scope.baseUrl = $location.protocol() + '://' + $location.host() + ':' + $location.port();
            }

            function handleResponse(response: MediaListResponse) {
                $scope.media = response.media.map(m => ({
                    url: createUrl(m.media.url),
                    videoUrl: createVideoUrl(m.media.url),
                    albumId: m.albumId,
                    mediaId: m.mediaId,
                    thumbUrl: createUrl(m.media.poster),
                    caption: m.media.title,
                    type: m.media.type
                }) as MediaItem);
            }

            function createUrl(url: string): string {

                const encodedToken = encodeURIComponent(token);

                let absoluteUrl = url;

                if (!UrlHelper.isUrlAbsolute(absoluteUrl)) {
                    absoluteUrl = UrlHelper.createAbsoluteMediaUrl($rootScope.configuration.services.media, url);
                }

                return `${absoluteUrl}?access_token=${encodedToken}`;
            }

            function createVideoUrl(url: string): string {
                if (!UrlHelper.isUrlAbsolute(url)) {
                    url = UrlHelper.createAbsoluteMediaUrl($rootScope.configuration.services.media, url);
                }

                return `${$scope.baseUrl}/component/video-player.html?src=${encodeURIComponent(url)}&access_token=${encodeURIComponent(token)}`;
            }

            $scope.close = function () {
                $uibModalInstance.dismiss();
            };

            $scope.playVideo = (mediaItem) => {
                $state.go('app.video', { mediaId: mediaItem.mediaId });
                $uibModalInstance.close();
            };

            initialize();
        }
    ]
);
