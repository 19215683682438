import { appModule } from '@/definitions';
import { FortyTwoRootScopeService, FortyTwoLoginService, ftSettingsService } from '@/FortyTwoFramework';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { AppDataService } from '@/services/app-data-service';
import { RoleChecker } from '@/role-checker';
import { NetworkUtils } from '@/network-utils';

class ExternalLoginController {
    public static $inject = ['$rootScope', '$stateParams', 'ftLoginService', '$localStorage', 'AppDataService', 'ftConfiguration', 'ftSettingService', '$state', 'RoleCheckerService'];

    public constructor(
        private $rootScope: FortyTwoRootScopeService,
        private $stateParams: IStateParamsService,
        ftLoginService: FortyTwoLoginService,
        private $localStorage,
        private dataService: AppDataService,
        private ftConfiguration,
        private ftSettingService: ftSettingsService,
        private $state: IStateService,
        private roleCheckerService: RoleChecker) {

        if (!$stateParams.api) {
            return;
        }

        ftLoginService.doLogout();
        localStorage.clear();

        ftConfiguration.config.baseApiUrl = $stateParams.api;
        appModule.baseApiUrl = $stateParams.api;
        $localStorage.apiUrl = appModule.baseApiUrl;

        this.doExternalLogin();
    }

    private doExternalLogin() {
        const { token, group } = this.$stateParams;

        if (token == null) {
            return;
        }

        const decodedToken = decodeURIComponent(token);

        this.dataService.oauthLoginAsync(decodedToken).then(() => {
            this.$localStorage.refreshToken = decodedToken;
            this.refreshConfiguration();

            this.ftSettingService.set('app.presence', {});
            this.$rootScope.showSplash = false;

            return this.dataService.getUser().then(user => {

                if (group != null) {
                    this.ftSettingService.set(window.appSettings.group.selected, group);
                } else if (user.groups && user.groups.length === 1) {
                    this.ftSettingService.set(window.appSettings.group.selected, user.groups[0].id);
                } else {
                    this.ftSettingService.set(window.appSettings.group.selected, null);
                }

                const previous = NetworkUtils.getQueryParam('previous');
                if (previous != null) {
                    this.$state.go(previous);
                } else {
                    return this.roleCheckerService.navigateToRoleStartPage();
                }
            });

        });
    }

    private refreshConfiguration() {
        return this.dataService.getConfigurationAsync().then((results) => {
            this.$rootScope.configuration = results;
            this.ftConfiguration.configure(results);
        });
    }
}

appModule.controller('kpExternalLoginController', ExternalLoginController);