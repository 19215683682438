import { IComponentOptions, IScope, IController, IOnChanges, IOnChangesObject } from 'angular';
import { appModule } from '@/definitions';

interface Scope extends IScope {
    image: string;
    initials: string;
    selected: boolean;
}

class AvatarController implements IController, IOnChanges {
    public static $inject = ['$scope'];

    public image: string;
    public name: string;
    public selected: boolean;

    private readonly $scope: Scope;

    public constructor($scope: Scope) {
        this.$scope = $scope;
    }

    public $onInit() {
        this.$scope.initials = this.getAvatarInitials(this.name);
        this.$scope.image = this.image;
        this.$scope.selected = this.selected;
    }

    public $onChanges(changes: IOnChangesObject) {
        if (changes['name'] != null && changes['name'].currentValue != null) {
            this.$scope.initials = this.getAvatarInitials(changes['name'].currentValue);
        }

        if (changes['image'] != null && changes['image'].currentValue != null) {
            this.$scope.image = changes['image'].currentValue;
        }

        if (changes['selected'] != null && changes['selected'].currentValue != null) {
            this.$scope.selected = changes['selected'].currentValue;
        }
    }

    private getAvatarInitials(name: string): string {
        if (name == null) {
            return 'NB';
        }

        let matches = name.match(/\b(\w)/g);
        if (matches.length < 2) {
            return name.slice(0, 2).toUpperCase();
        }

        matches = matches.slice(0, 2);
        return matches.join('').toUpperCase();
    }
}

class AvatarComponent implements IComponentOptions {
    public controller: any;
    public template: string;
    public bindings: any;

    constructor() {
        this.bindings = {
            image: '@?',
            name: '@',
            selected: '<'
        };

        this.controller = AvatarController;
        this.template = require('./template.html');
    }
}

appModule.component('avatar', new AvatarComponent());
