import { IController, IScope, IQService, IPromise } from 'angular';
import { IModalInstanceService, IModalService } from 'angular-ui-bootstrap';
import { appModule, ng, ft } from '@/definitions';
import { AppDataService } from '@/services/app-data-service';
import { ChildCare } from '@/data-models';
import moment, { duration } from 'moment';
import { Dictionary } from '@/dictionary';

type ActivityPlanning = ChildCare.Definitions.Activity.ActivityPlanning;
type ActivityGroupTypeModel = ChildCare.Definitions.Activity.ActivityGroupTypeModel;
type ActivityGroupListResponse = ChildCare.Definitions.Activity.ActivityGroupListResponse;
type ActivityEmployeeListResponse = ChildCare.Definitions.Activity.ActivityEmployeeListResponse;
type ActivityGroupModel = ChildCare.Definitions.Activity.ActivityGroupModel;
type Activity = ChildCare.Definitions.Activity.Activity;
type Employee = ChildCare.Definitions.Locations.Models.Employee;
type CreateOrUpdateActivityPlanningCommand = ChildCare.Definitions.Activity.CreateOrUpdateActivityPlanningCommand;
type Child = ChildCare.Definitions.Child;

interface IForm {
    name: string;
    description: string;
    isIntern: boolean;
    selectedGroups: Dictionary<boolean>;
    selectedEmployees: Dictionary<boolean>;
    startTime: string;
    endTime: string;
    maximumGroupSize: number;
    periodicOrSeries: string;
    place: string;
    repeatUntil?: string;
}

interface ILocalScope extends IScope {
    isLoading: boolean;
    isNew: boolean;
    date: moment.Moment;
    availableEmployees: Employee[];
    availableGroups: ActivityGroupModel[];
    availableGroupTypes: ActivityGroupTypeModel[];
    selectedGroupTypes: Dictionary<boolean>;
    form: IForm;
    errors: Dictionary<string>;

    filteredGroups: () => ActivityGroupModel[];
    cancel: () => void;
    save: () => void;
    delete: () => void;

    selectChildrenClicked();
    numberOfSelectedChildren(): number;
}

class EditActivityController implements IController {
    private $q: IQService;
    private $uibModal: IModalService;
    private $uibModalInstance: IModalInstanceService;
    private $scope: ILocalScope;
    private $translate;
    private dataService: AppDataService;
    private locationId: string;
    private planning: ActivityPlanning;
    private activity: Activity;
    private locationHasKiosk: boolean;
    private selectedChildren: Child[];

    static $inject = ['$q', '$uibModal', '$uibModalInstance', '$scope', '$translate', 'AppDataService', 'date', 'locationId', 'locationHasKiosk', 'planning', 'activity'];

    constructor($q: IQService, $uibModal: IModalService, $uibModalInstance: IModalInstanceService, $scope: ILocalScope, $translate, dataService: AppDataService, date: moment.Moment, locationId: string, locationHasKiosk: boolean, planning: ActivityPlanning, activity: Activity) {
        this.$q = $q;
        this.$uibModal = $uibModal;
        this.$uibModalInstance = $uibModalInstance;
        this.$scope = $scope;
        this.dataService = dataService;
        this.$scope.date = date;
        this.locationId = locationId;
        this.planning = planning;
        this.activity = activity;
        this.$translate = $translate;
        this.locationHasKiosk = locationHasKiosk;

        this.selectedChildren = [];

        this.$scope.filteredGroups = this.filteredGroups.bind(this);
        this.$scope.save = this.save.bind(this);
        this.$scope.delete = this.delete.bind(this);
        this.$scope.cancel = this.cancel.bind(this);
        this.$scope.selectChildrenClicked = this.onSelectChildrenClicked.bind(this);
        this.$scope.numberOfSelectedChildren = () => this.selectedChildren.length;
        this.$scope.isNew = this.planning?.activityPlanningId == null;

        this.initializeData();
    }

    private initializeData(): IPromise<void> {
        this.$scope.isLoading = true;

        this.$scope.selectedGroupTypes = new Dictionary<boolean>();

        const allGroupsTask = this.dataService.getGroupsByLocation(this.locationId);
        const employeesTask = this.dataService.getEmployeesForLocation(this.locationId, this.$scope.date);

        const planningId = this.planning ? this.planning.activityPlanningId : undefined;
        const activityGroupsTask = planningId ? this.dataService.getGroupsForActivityPlanning(planningId) : this.$q.resolve(null);
        const activityEmployeesTask = planningId ? this.dataService.getEmployeesForActivityPlanning(planningId) : this.$q.resolve(null);

        return this.$q.all([allGroupsTask, employeesTask, activityGroupsTask, activityEmployeesTask])
        .then(responses => {
            const groupsResponse = responses[0];
            const employeesResponse = responses[1];
            const activityGroupsResponse = responses[2] as ActivityGroupListResponse;
            const activityEmployeesResponse = responses[3] as ActivityEmployeeListResponse;

            this.$scope.availableGroups = groupsResponse.groups;
            this.$scope.availableGroupTypes = groupsResponse.groupTypes;

            this.$scope.form = this.createForm(this.planning, this.activity);

            if (activityGroupsResponse) {
                this.$scope.form.selectedGroups = Dictionary.toDictionary2(activityGroupsResponse.groups, x => x.groupId, x => true);
                this.$scope.selectedGroupTypes = Dictionary.toDictionary2(activityGroupsResponse.groups, x => x.groupTypeId, x => true);
            }

            if (activityEmployeesResponse) {
                this.$scope.form.selectedEmployees = Dictionary.toDictionary2(activityEmployeesResponse.employees, x => x.employeeId, x => true);
            }

            this.$scope.availableEmployees = employeesResponse.employees;
        }).finally(() => this.$scope.isLoading = false);
    }

    private createForm(planning: ActivityPlanning, activity: Activity): IForm {

        let name = activity?.name;
        let description = activity?.description;
        let maximumGroupSize = activity?.groupSize;
        let startTime = moment().format('HH:00');
        let endTime = moment().add(1, 'hour').format('HH:00');
        let place = '';

        if (planning) {
            name = planning.name;
            startTime = moment(planning.start).format('HH:mm');
            endTime = moment(planning.end).format('HH:mm');
            place = planning.place;
            maximumGroupSize = planning.maximumGroupSize;
            description = planning.description;
        }

        return {
            name,
            description,
            maximumGroupSize,
            startTime: startTime,
            endTime: endTime,
            place,
            isIntern: true,
            periodicOrSeries: 'periodic',
            selectedGroups: new Dictionary<boolean>(),
            selectedEmployees: new Dictionary<boolean>(),
        };
    }

    private async onSelectChildrenClicked(): Promise<void> {
        const date = this.$scope.date;
        const selectedGroups = this.$scope.form.selectedGroups.filter(kvp => kvp.value).map(x => x.key);
        const startTime = moment.duration(moment(this.$scope.form.startTime).format('HH:mm'));
        const endTime = moment.duration(moment(this.$scope.form.endTime).format('HH:mm'));

        const { children } = await this.dataService.getActivityChildrenForGroups(selectedGroups, date, startTime, endTime);
        if (this.planning?.enrolledChildren != null) {
            this.selectedChildren = children.filter(x => this.planning.enrolledChildren.includes(x.childId));
        }

        this.$uibModal.open({
            animation: true,
            templateUrl: 'partials/travel/child-picker.html?v=' + ft.randomNumber,
            controller: 'ChildPickerController',
            backdrop: true,
            size: 'sm',
            keyboard: true,
            resolve: {
                children: () => children,
                selectedChildren: () => this.selectedChildren,
                moments: () => []
            }
        }).result.then((selected: Child[]) => {
            this.selectedChildren = selected;
        });
    }

    private validate(form: IForm): Dictionary<string> {
        const errors = new Dictionary<string>();

        if (!form.name) {
            errors.add('name', this.$translate.instant('ACTIVITY.ERRORS.NAME'));
        }
        if (this.locationHasKiosk && (!form.selectedGroups || form.selectedGroups.length() === 0)) {
            errors.add('selectedGroups', this.$translate.instant('ACTIVITY.ERRORS.GROUPS'));
        }
        if (!form.selectedEmployees || form.selectedEmployees.length() === 0) {
            errors.add('selectedEmployees', this.$translate.instant('ACTIVITY.ERRORS.EMPLOYEES'));
        }
        if (!form.startTime || moment(form.startTime).diff(moment(), 'day') < 0) {
            errors.add('startTime', this.$translate.instant('ACTIVITY.ERRORS.STARTTIME'));
        }
        if (!form.endTime || moment(form.endTime).diff(moment(form.startTime), 'day') < 0) {
            errors.add('endTime', this.$translate.instant('ACTIVITY.ERRORS.ENDTIME'));
        }
        if (!form.maximumGroupSize || form.maximumGroupSize <= 0) {
            errors.add('maximumGroupSize', this.$translate.instant('ACTIVITY.ERRORS.GROUPSIZE'));
        }
        if (form.repeatUntil && moment(form.repeatUntil).diff(moment(), 'day') < 0) {
            errors.add('repeatUntil', this.$translate.instant('ACTIVITY.ERRORS.REPEATUNTIL'));
        }

        return errors;
    }

    private createCommand(): CreateOrUpdateActivityPlanningCommand {

        const selectedGroups = this.$scope.form.selectedGroups.filter(kvp => kvp.value).map(x => x.key);
        const selectedEmployees = this.$scope.form.selectedEmployees.filter(kvp => kvp.value).map(x => x.key);

        return {
            activityPlanningId: this.planning ? this.planning.activityPlanningId : undefined,
            activityId: this.activity?.activityId,
            groupIds: selectedGroups,
            employeeIds: selectedEmployees,
            childIds: this.selectedChildren?.map(x => x.childId),
            name: this.$scope.form.name,
            description: this.$scope.form.description,
            date: this.$scope.date.format('DD/MM/YYYY'),
            isIntern: this.$scope.form.isIntern,
            locationId: this.locationId,
            maximumGroupSize: this.$scope.form.maximumGroupSize,
            place: this.$scope.form.place,
            from: moment(this.$scope.form.startTime).format('HH:mm'),
            till: moment(this.$scope.form.endTime).format('HH:mm')
        };
    }

    private async delete() {
        if (this.planning?.activityPlanningId == null) {
            return;
        }

        this.$scope.isLoading = true;
        await this.dataService.deleteActivity(this.planning.activityPlanningId);

        this.$uibModalInstance.close();
    }

    private async save() {

        this.$scope.errors = this.validate(this.$scope.form);
        if (this.$scope.errors.length() > 0) {
            return;
        }

        this.$scope.isLoading = true;
        const command = this.createCommand();
        const newPlanningId = (await this.dataService.saveActivity(command, command.activityPlanningId)) || command.activityPlanningId;

        if (this.$scope.form.repeatUntil) {
            if (this.$scope.form.periodicOrSeries === 'periodic') {
                await this.dataService.setActivityToPeriodic(newPlanningId, this.$scope.form.repeatUntil);
            } else if (this.$scope.form.periodicOrSeries === 'serie') {
                await this.dataService.setActivityToSeries(newPlanningId, this.$scope.form.repeatUntil);
            }
        }

        this.$uibModalInstance.close();
    }

    private cancel() {
        this.$uibModalInstance.dismiss();
    }

    private filteredGroups() {

        const selectedGroupTypes = this.$scope.selectedGroupTypes;
        const selected = Object.keys(selectedGroupTypes);

        if (!selected.some(x => selectedGroupTypes[x])) {
            return this.$scope.availableGroups;
        }

        return this.$scope.availableGroups.filter(x => selectedGroupTypes[x.groupTypeId]);
    }
}

appModule.controller('kpEditActivityController', EditActivityController);
