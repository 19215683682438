import { ChildCare } from '@/data-models';
import { Dictionary } from '@/dictionary';
import { AppDataService } from '@/services/app-data-service';
import * as moment from 'moment';
import { HttpError } from '@/services/http-error';
import { PresencesStatus } from '@/enums';
import { TransportTableRow } from './transport-table-row';
import { getChildStatus } from './get-child-status';


import TransportVehicle = ChildCare.Definitions.Transport.Models.TransportVehicle;
import Employee = ChildCare.Definitions.Locations.Models.Employee;
import TransportRide = ChildCare.Definitions.Transport.Models.TransportRide;
import TransportChild = ChildCare.Definitions.Transport.Models.TransportChild;

type ChildRow = { row: TransportTableRow; child: TransportChild };

export enum CapacityMode {
    none,
    restrict,
    manual
}

export class RidesViewModel {
    private dataService: AppDataService;

    public date: moment.Moment;

    public sourceRows: TransportTableRow[];
    public vehicles: TransportVehicle[];
    public employees: Employee[];

    private selectedChildren: Dictionary<TransportChild[]>;
    private selectedEmployee: Employee;
    private selectedVehicle: TransportVehicle;
    public remarks: string;

    private capacityMode: CapacityMode;

    public constructor(dataService: AppDataService, date: moment.Moment, sourceRows: TransportTableRow[], vehicles: TransportVehicle[], employees: Employee[]) {
        this.dataService = dataService;

        this.date = date;

        this.sourceRows = sourceRows;
        this.vehicles = vehicles;
        this.employees = employees;

        this.selectedChildren = new Dictionary();

        this.capacityMode = CapacityMode.restrict;

        this.createNewRides();

        this.initializeSelectedVehicle();
        this.initializeSelectedEmployee();
        this.initializeRemarks();

        this.selectVehicle = this.selectVehicle.bind(this);
        this.selectEmployee = this.selectEmployee.bind(this);
        this.selectChild = this.selectChild.bind(this);
        this.selectChildren = this.selectChildren.bind(this);
        this.numberOfSelectedChildren = this.numberOfSelectedChildren.bind(this);
        this.getSelectedEmployee = this.getSelectedEmployee.bind(this);
        this.getSelectedVehicle = this.getSelectedVehicle.bind(this);
        this.getSelectedChildren = this.getSelectedChildren.bind(this);
        this.getAllChildren = this.getAllChildren.bind(this);
        this.validate = this.validate.bind(this);
        this.save = this.save.bind(this);
        this.setCapacityMode = this.setCapacityMode.bind(this);
    }

    private createNewRides(): void {
        for (let i = 0; i < this.sourceRows.length; i++) {
            const row = this.sourceRows[i];

            if (row.ride?.rideId == null) {
                row.ride = {
                    ...row.ride,
                    rideId: this.generateNewId(i),
                    locationId: row.time.locationId,
                    schoolId: row.school?.schoolId,
                    moment: this.date.clone().startOf('day').add(moment.duration(row.time.time)).toISOString(true)
                } as TransportRide;
            }
        }
    }

    private generateNewId(index: number) {
        return `new-${index}`;
    }

    private isNewId(id: string) {
        if (id == null) {
            return false;
        }
        return id.startsWith('new');
    }

    private initializeSelectedVehicle(): void {
        let vehicleId = this.sourceRows.find(x => x.ride?.vehicleId != null)?.ride?.vehicleId;
        if (vehicleId == null) {
            vehicleId = this.vehicles[0]?.vehicleId;
        }

        this.selectVehicle(vehicleId);
    }

    private initializeSelectedEmployee(): void {
        let employeeId = this.sourceRows.find(x => x.ride?.employeeId != null)?.ride?.employeeId;
        if (employeeId == null) {
            employeeId = this.employees[0]?.employeeId;
        }

        this.selectEmployee(employeeId);
    }

    private initializeRemarks(): void {
        const comment = this.sourceRows.find(x => x.ride?.comment != null)?.ride?.comment;
        if (comment != null) {
            this.remarks = comment;
        }
    }

    private selectAllChildren(): void {
        const containsExisting = this.sourceRows.some(x => !this.isNewId(x.ride.rideId));

        for (const row of this.sourceRows) {
            if (row.selectedChildren != null) {
                for (const child of row.selectedChildren) {
                    this.addChild(row.ride.rideId, child, true);
                }
            }

            if (!containsExisting && row.availableChildren != null) {
                for (const child of row.availableChildren.sort((a, b) => a.childName.localeCompare(b.childName))) {
                    if (getChildStatus(this.getMoments(), child) === PresencesStatus.Expected && !this.addChild(row.ride.rideId, child)) {
                        if (this.capacityMode === CapacityMode.restrict) {
                            break;
                        }
                    }
                }
            }
        }
    }

    private clearChildSelection(): void {
        this.selectedChildren.clear();
    }

    private addChild(rideId: string, child: TransportChild, ignoreCapacityMode = false): boolean {
        if (ignoreCapacityMode === false && this.capacityMode === CapacityMode.restrict) {
            const capacity = this.getVehicleCapacity();
            if (this.numberOfSelectedChildren() >= capacity) {
                return false;
            }
        }

        const children = this.selectedChildren.getOrAdd(rideId, []);
        if (children.some(x => x.childId === child.childId)) {
            return true;
        }

        children.push(child);

        return true;
    }

    private getVehicleCapacity(): number {
        const capacity = (this.selectedVehicle?.capacity ?? 0);

        return capacity === 0 ? 9999 : capacity;
    }

    public getSelectedChildren(): TransportChild[] {
        const children = [];

        for (const selectedPerRide of this.selectedChildren.values()) {
            children.push(...selectedPerRide);
        }

        return children;
    }

    private findRowsForChild(childId: string): ChildRow[] {
        const rows = new Array<ChildRow>();
        for (const row of this.sourceRows) {
            if (row.selectedChildren != null) {
                for (const child of row.selectedChildren) {
                    if (child.childId === childId) {
                        rows.push({
                            row,
                            child
                        });
                    }
                }
            }

            if (row.availableChildren != null) {
                for (const child of row.availableChildren) {
                    if (child.childId === childId) {
                        rows.push({
                            row,
                            child
                        });
                    }
                }
            }
        }

        return rows;
    }

    public getAllChildren(): TransportChild[] {
        const children = new Array<TransportChild>();

        for (const row of this.sourceRows) {
            if (row.selectedChildren != null) {
                for (const child of row.selectedChildren) {
                    if (children.every(x => x.childId !== child.childId)) {
                        children.push(child);
                    }
                }
            }

            if (row.availableChildren != null) {
                for (const child of row.availableChildren) {
                    if (children.every(x => x.childId !== child.childId)) {
                        children.push(child);
                    }
                }
            }
        }

        return children;
    }

    public selectChild(childId: string): boolean {
        const rows = this.findRowsForChild(childId);
        if (rows.length === 0) {
            return false;
        }

        this.capacityMode = CapacityMode.manual;

        const existingRow = rows.find(x => !this.isNewId(x.row.ride.rideId));
        if (existingRow != null) {
            return this.addChild(existingRow.row.ride.rideId, existingRow.child, true);
        } else {
            return this.addChild(rows[0].row.ride.rideId, rows[0].child, true);
        }
    }

    public selectChildren(childIds: string[]): boolean {
        if (childIds == null) {
            return false;
        }

        this.clearChildSelection();
        for (const childId of childIds) {
            if (!this.selectChild(childId)) {
                return false;
            }
        }

        return true;
    }

    public selectVehicle(vehicleId: string): void {
        const vehicle = this.vehicles.find(x => x.vehicleId === vehicleId);
        if (vehicle == null) {
            return;
        }

        this.selectedVehicle = vehicle;
        if (this.capacityMode === CapacityMode.manual) {
            return;
        }

        this.clearChildSelection();
        this.selectAllChildren();
    }

    public selectEmployee(employeeId: string): void {
        const employee = this.employees.find(x => x.employeeId === employeeId);
        if (employee == null) {
            return;
        }

        this.selectedEmployee = employee;
    }

    public setCapacityMode(mode: CapacityMode): void {
        this.capacityMode = mode;
        this.selectAllChildren();
    }

    public getSelectedEmployee(): Employee {
        return this.selectedEmployee;
    }

    public getSelectedVehicle(): TransportVehicle {
        return this.selectedVehicle;
    }

    public numberOfSelectedChildren(): number {
        return this.selectedChildren.values().reduce((prev, cur) => prev + cur.length, 0);
    }

    private hasChanges(ride: TransportRide): boolean {
        if (this.isNewId(ride.rideId)) {
            return true;
        }

        if (this.selectedEmployee?.employeeId !== ride.employeeId) {
            return true;
        }

        if (this.selectedVehicle?.vehicleId !== ride.vehicleId) {
            return true;
        }

        if (this.remarks !== ride.comment) {
            return true;
        }

        const newSelection = this.selectedChildren.get(ride.rideId) ?? [];
        const newChildren = new Array<string>();
        const removedChildren = new Array<string>();

        for (const selected of ride.children ?? []) {
            const index = newSelection.findIndex(x => x.childId === selected);
            if (index === -1) {
                removedChildren.push(selected);
            }
        }

        for (const selected of newSelection) {
            if (!ride.children.includes(selected.childId)) {
                newChildren.push(selected.childId);
            }
        }

        if (newChildren.length > 0 || removedChildren.length > 0) {
            return true;
        }

        return false;
    }

    public validate(): string[] {
        const errors = [];
        if (this.selectedVehicle == null) {
            errors.push('selectedVehicle');
        }

        if (this.selectedEmployee == null) {
            errors.push('selectedEmployee');
        }

        if (this.sourceRows.every(x => this.isNewId(x.ride.rideId)) && this.selectedChildren.length() === 0) {
            errors.push('selectedChildren');
        } else {
            if (this.sourceRows.every(x => !this.hasChanges(x.ride))) {
                errors.push('unchanged');
            }
        }

        return errors;
    }

    public getMoments(): string[] {
        return this.sourceRows.reduce((prev, cur) => {
            prev.push(cur.time.time);
            return prev;
        }, new Array<string>());
    }

    private getRides(): TransportRide[] {
        const rides = new Array<TransportRide>();

        for (const row of this.sourceRows) {
            const selectedChildren = this.selectedChildren.get(row.ride.rideId)?.map(x => x.childId) ?? [];

            if (selectedChildren.length === 0 && this.isNewId(row.ride.rideId)) {
                continue;
            }

            const ride = {
                ...row.ride,
                children: selectedChildren
            };

            if (this.isNewId(ride.rideId)) {
                ride.rideId = undefined;
            }

            rides.push(ride);
        }

        return rides;
    }

    public async save(): Promise<string> {
        const errors = this.validate();
        if (errors.length > 0) {
            return 'validation failed for the following fields ' + errors.join(', ');
        }

        const ridesToSave = this.getRides();

        try {
            await this.dataService.saveTransportRides({
                vehicleId: this.selectedVehicle?.vehicleId,
                employeeId: this.selectedEmployee.employeeId,
                rides: ridesToSave,
                comment: this.remarks
            });
        } catch (reason) {
            if (reason instanceof HttpError) {
                return reason.message;
            }
        }

        return null;
    }
}