appModule.directive('lightbox', ['$timeout', function ($timeout) {
    return function (_scope, element) {
        $timeout(function () {
            element.lightGallery({
                enableTouch: true,
                selector: '.lightbox-image',
                videojs: true
            });
        });
    };
}]);

appModule.directive('fgLine', function () {
    return {
        restrict: 'C',
        link: function () {
            if ($('.fg-line')[0]) {
                $('body').on('focus', '.form-control', function () {
                    $(this).closest('.fg-line').addClass('fg-toggled');
                });

                $('body').on('blur', '.form-control', function () {
                    var p = $(this).closest('.form-group');
                    var i = p.find('.form-control').val();

                    if (p.hasClass('fg-float')) {
                        if (i.length == 0) {
                            $(this).closest('.fg-line').removeClass('fg-toggled');
                        }
                    }
                    else {
                        $(this).closest('.fg-line').removeClass('fg-toggled');
                    }
                });
            }

        }
    };

})

    // =========================================================================
    // PLACEHOLDER FOR IE 9 (on .form-control class)
    // =========================================================================

    .directive('formControl', function () {
        return {
            restrict: 'C',
            link: function (_scope, _element, _attrs) {
                if (angular.element('html').hasClass('ie9')) {
                    $('input, textarea').placeholder({
                        customClass: 'ie9-placeholder'
                    });
                }
            }

        };
    })

    .directive('dateTimePicker', function () {
        return {
            restrict: 'A',
            scope: {
                date: '=',
                dialog: '=',
                momentFormat: '@',
                renderAs: '@'
            },
            link: function (scope, element, _attrs) {
                scope.$watch('date', function () {
                    var defaultDialog = {
                        type: 'date',
                        mode: true,
                        init: moment(scope.date),
                        trigger: element[0],
                        ok: 'Kies',
                        cancel: 'Annuleren',
                        future: moment().add(200, 'year'),
                        past: moment().subtract(200, 'year')
                    };

                    if (!scope.renderAs)
                        {scope.renderAs = '%date%';}

                    var dialog = Object.assign(defaultDialog, scope.dialog);
                    var format = scope.momentFormat ? scope.momentFormat : (dialog.type === 'date' ? 'DD-MM-YYYY' : 'HH:mm');
                    element.html(scope.renderAs.replace('%date%', moment(scope.date).format(format)));
                    element.val(moment(scope.date).format(format));

                    dialog = new mdDateTimePicker.default(dialog);

                    element.unbind('onOk');
                    element.unbind('focusin');
                    element.bind('onOk', function () {
                        scope.date = dialog.time;
                        element.val(dialog.time.format(format));
                        element.html(scope.renderAs.replace('%date%', moment(scope.date).format(format)));
                        scope.$apply();
                    });

                    element.bind('focusin', function () {
                        dialog.show();
                    });

                    element.click('click', function () {
                        dialog.show();
                    });
                }, true);
            }
        };
    });
