import { ChildCare } from '@/data-models';
import { FortyTwoLoginService, ftSettingsService } from '@/FortyTwoFramework';
import { IStateService } from 'angular-ui-router';
import { IPromise, IQService } from 'angular';

type UserModel = ChildCare.Definitions.Account.UserModel;

export class RoleChecker {
    public static serviceName = 'RoleCheckerService';
    public static $inject = ['ftLoginService', '$state', '$q', 'ftSettingService'];

    private loginService: FortyTwoLoginService;
    private $state: IStateService;
    private $q: IQService;
    private settingsService: ftSettingsService;

    constructor(loginService: FortyTwoLoginService, $state: IStateService, $q: IQService, settingsService: ftSettingsService) {
        this.loginService = loginService;
        this.$state = $state;
        this.$q = $q;
        this.settingsService = settingsService;

        this.navigateToRoleStartPage = this.navigateToRoleStartPage.bind(this);
        this.allowGroupSwitching = this.allowGroupSwitching.bind(this);
        this.switchGroup = this.switchGroup.bind(this);
        this.checkLogin = this.checkLogin.bind(this);
    }

    public navigateToRoleStartPage(): IPromise<void> {
        return this.loginService.getCurrentUserAsync().then(
            user => {
                const selectedGroup = this.settingsService.get(window.appSettings.group.selected);

                if (RoleChecker.groupSwitchingAllowed(user) && selectedGroup == null) {
                    this.$state.go('app.groups', { previous: this.$state.current.name });
                } else if (RoleChecker.hasGroupRole(user) || RoleChecker.hasGroup(user)) {
                    this.$state.go('app.presence');
                } else if (RoleChecker.hasPlannerRole(user)) {
                    this.$state.go('app.planner');
                } else if (RoleChecker.hasLocationManager(user)) {
                    this.$state.go('app.locationmanager');
                }  else {
                    this.loginService.doLogout();
                    this.settingsService.set(window.appSettings.group.selected, undefined);
                    this.$state.go('outside-app.login');
                }
            },
            error => {
                console.log(`Received error while checking identity: ${error}`);
                return this.logout();
            }
        );
    }

    public allowGroupSwitching(): IPromise<boolean> {
        return this.loginService.getCurrentUserAsync().then(x => RoleChecker.groupSwitchingAllowed(x));
    }

    public switchGroup(): IPromise<void> {
        const currentLocation = this.$state.current.name;
        return this.allowGroupSwitching().then(result => {
            if (result) {
                this.$state.go('app.groups', { previous: currentLocation });
            }
        });
    }

    public checkLogin(): IPromise<void> {
        return this.loginService.getCurrentUserAsync().then(user => {
                if (!RoleChecker.allowLogin(user)) {
                    console.log('Role check failed, logging out now');
                    return this.logout();
                } else {
                    return this.$q.resolve();
                }
            },
            error => {
                console.log(`Received error while checking identity: ${error}`);
                return this.logout();
            }
        );
    }

    private logout(): IPromise<void> {
        this.loginService.doLogout();
        this.settingsService.set(window.appSettings.group.selected, undefined);
        this.$state.go('outside-app.login');

        return this.$q.reject('Role check failed, logging out now');
    }

    public static allowLogin(user: UserModel): boolean {
        if (user == null || user.roles == null || user.roles.length == 0) {
            return false;
        }

        return RoleChecker.hasGroupRole(user) || RoleChecker.hasAdminRole(user) || RoleChecker.hasPlannerRole(user);
    }

    public static allowGroupSwitching(user: UserModel): boolean {
        if (user == null || user.roles == null || user.roles.length == 0) {
            return false;
        }

        return RoleChecker.hasGroupRole(user) || RoleChecker.hasAdminRole(user);
    }

    public static allowPlannerEditing(user: UserModel): boolean {
        return RoleChecker.hasAdminRole(user) || RoleChecker.hasPlannerRole(user);
    }

    public static hasGroupRole(user: UserModel): boolean {
        return user.roles.some(r => r.toLowerCase() === 'group');
    }

    public static hasGroup(user: UserModel): boolean {
        return user.groups?.length > 0;
    }

    public static hasAdminRole(user: UserModel): boolean {
        return user.roles.some(r => r.toLowerCase() === 'admin');
    }

    public static hasPlannerRole(user: UserModel): boolean {
        return user.roles.some(r => r.toLowerCase() === 'planning');
    }

    public static hasLocationManager(user: UserModel): boolean {
        return user.roles.some(r => r.toLowerCase() == 'locatiemanager');
    }

    public static groupSwitchingAllowed(user: UserModel): boolean {
        return RoleChecker.hasAdminRole(user) || RoleChecker.hasGroupRole(user);
    }
}

window.appModule.service(RoleChecker.serviceName, RoleChecker);
