import {NetworkUtils} from '@/network-utils';

export enum EnvironmentMode {
    development = 'development',
    production = 'production'
}

export interface Environment {
    environment: EnvironmentMode;
    buildnumber: string;
    commit: string;
    apiUrl: string;
    tenant?: string;
}

const environments: { [key: string]: Environment } = {
    development: {
        environment: EnvironmentMode.development,
        buildnumber: env.buildnumber,
        commit: env.commit,
        apiUrl: 'http://localhost:5000/api/'
    },
    production: {
        environment: EnvironmentMode.production,
        buildnumber: env.buildnumber,
        commit: env.commit,
        apiUrl: `https://backoffice-${NetworkUtils.getTenantFromUrl(window.location.host)}.kindplanner.nl/api/`
    }
};

export function getEnvironment(): Environment {
    const tenant = NetworkUtils.getTenantFromUrl(window.location.host);

    if (env.mode === EnvironmentMode.development) {
        return { ...environments.development, tenant };
    }

    return { ...environments.production, tenant };
}