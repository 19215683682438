import { IComponentOptions, IOnChanges, IOnChangesObject } from 'angular';
import { ChildCare } from '@/data-models';
import { Color } from '@/HelperClasses';
import './styles.less';

type Label = ChildCare.Definitions.GroupApp.ChildLabel | ChildCare.Definitions.GroupApp.ChildLabelType;

enum Mode {
    none = 'none',
    add = 'add',
    remove = 'remove'
}

interface ChildLabelBindings {
    label: Label;
    mode: keyof typeof Mode;
    click: () => void;
}

class ChildLabelController implements ChildLabelBindings, IOnChanges {
    public static $inject = [];

    public label: ChildCare.Definitions.GroupApp.ChildLabel | ChildCare.Definitions.GroupApp.ChildLabelType;
    public mode: keyof typeof Mode;
    public click: () => void;

    public backgroundColor: string;
    public foregroundColor: string;

    public constructor() {
        this.mode = Mode.none;
    }

    public onClick() {
        if (this.mode !== Mode.none) {
            this.click();
        }
    }

    public $onChanges(changes: IOnChangesObject): void {
        if (changes['label'] != null && changes['label'].currentValue != null) {
            const label = changes['label'].currentValue as Label;

            this.backgroundColor = label.colorCode == null ? 'transparent' : Color.hexToRgba(label.colorCode, this.mode === Mode.add ? 0.5 : 1);
            this.foregroundColor = label.colorCode == null ? 'black' : Color.contrast(label.colorCode);
        }
    }
}

class ChildLabelComponent implements IComponentOptions {
    public controller: any;
    public template: string;
    public bindings: any;

    constructor() {
        this.bindings = {
            label: '<',
            mode: '@?',

            click: '&',
        };

        this.controller = ChildLabelController;
        this.template = require('./template.html');
    }


}

window.appModule.component('childLabel', new ChildLabelComponent());
