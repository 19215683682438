console.log(`🚀 Version ${env.buildnumber || '<no-build>'} build from ${env.commit || '<no-commit>'} and currently running in ${env.mode} mode`);

import '@/update';

import '@public/css/app.less';

import '@/FortyTwoFramework';

import 'angularjs-toaster';
import 'angularjs-toaster/toaster.min.css';
import 'angular-animate';

import '@/00.ft.app';
import '@/01.ft.app.definitions';
import '@/02.ft.app.models';
import '@/03.ft.app.module';

import '@/template';

import '@/dashboard';
import '@/ft.app.loading-bar';

import '@/component/avatar';
import '@/component/child-label';
import '@/component/group-switcher';
import '@/component/notifications';

import '@/directives/index';
import '@/filters/index';
import '@/providers/index';
import '@/services/index';
import '@/controllers/index';
import '@/stories/index';

// Other code commented out because we do not wanna base localization based on user agent and it wasn't working properly
import moment from 'moment';

moment.locale('nl');
