import { Form } from '@/HelperClasses';

appModule.controller('kpActionSwapModalController',
    ['$scope', '$uibModalInstance', 'AppDataService', 'childId', '$timeout', 'LoadingBar',
        function ($scope, $uibModalInstance, DataService, childId, $timeout, LoadingBar) {
            var initialized = false;
            $scope.isLoading = false;
            $scope.timesEditable = false;

        $scope.availableFromTimes = [];
        $scope.availableToTimes = [];

        $scope.groups = [];

        $scope.firstForm = {
            fromDate: null,
            toDate: null,
            group: null,
            remarks: null,
            valid: null
        };

        $scope.secondForm = {
            selectedFromTimes: null,
            selectedToTimes: null,
            valid: null
        };

        $scope.showDate = function(date) {
            return moment(date).format('DD-MM-YYYY');
        };

        $scope.$watch('isLoading', function(newVal, oldVal) {
            LoadingBar.show = newVal;
        });

        var initialize = function() {
            if (initialized) {
                return;
            }

            $scope.isLoading = true;
            DataService.getGroupsAsync()
                .then(function(res) {
                    $scope.groups = res.groups;
                    $scope.firstForm.group = $scope.groups[0].id;
                })
                .finally(function() {
                    $scope.isLoading = false;
                });
        };

        $scope.close = function() {
            $uibModalInstance.dismiss();
        };

        $scope.previous = function() {
            $scope.firstForm.valid = null;
            $scope.secondForm.valid = null;
            $scope.secondForm.selectedFromTimes = null;
            $scope.secondForm.selectedToTimes = null;
        };

        var validateTimes = function(collection) {
            collection.forEach(function(item) {
                if (item.startTime.length === 5) {
                    item.startTime += ':00';
                }

                if (item.endTime.length === 5) {
                    item.endTime += ':00';
                }

                if (
                    item.startTime > item.endTime ||
                    item.startTime < item.windowStartTime ||
                    item.endTime > item.windowEndTime
                ) {
                    item.startTime = item.startTime.substring(0, 5);
                    item.endTime = item.endTime.substring(0, 5);

                    item.valid = false;
                    $scope.secondForm.valid = false;
                }
            });
        };

        $scope.save = function() {
            if ($scope.firstForm.valid) {
                $scope.secondForm.valid = Form.formNullCheck($scope.secondForm, ['valid']);
            }

            $scope.firstForm.valid = Form.formNullCheck($scope.firstForm, ['valid', 'remarks']);

            if ($scope.firstForm.valid && $scope.secondForm.valid) {
                validateTimes($scope.secondForm.selectedFromTimes);
                validateTimes($scope.secondForm.selectedToTimes);

                if ($scope.secondForm.valid === false) {
                    return;
                }

                const from = moment($scope.firstForm.fromDate).format('DD/MM/YYYY');
                const to = moment($scope.firstForm.toDate).format('DD/MM/YYYY');

                $scope.isLoading = true;
                DataService.requestSwapDay(
                    childId,
                    $scope.firstForm.remarks,
                    from,
                    to,
                    $scope.firstForm.group,
                    $scope.secondForm.selectedFromTimes,
                    $scope.secondForm.selectedToTimes
                )
                    .then()
                    .finally(function() {
                        $scope.isLoading = false;
                        $uibModalInstance.dismiss();
                    });
            }

            if ($scope.firstForm.valid && !$scope.isLoading && $scope.secondForm.valid === null) {
                $scope.isLoading = true;

                const from = moment($scope.firstForm.fromDate).format('YYYY-MM-DD');
                const to = moment($scope.firstForm.toDate).format('YYYY-MM-DD');

                DataService.getPossibleSwapDays(childId, from, to).then(function(result) {
                    $scope.timesEditable = result.areTimesEditable;
                    $scope.availableFromTimes = result.fromTimes;
                    $scope.availableToTimes = result.toTimes;

                    if (result.fromErrorMessage) {
                        $scope.firstForm.valid = false;
                        $scope.fromErrorMessage = result.fromErrorMessage;
                    }
                    $scope.fromHasRegistrationRuleError = result.fromHasRegistrationRuleError;

                    if (result.toErrorMessage) {
                        $scope.firstForm.valid = false;
                        $scope.toErrorMessage = result.toErrorMessage;
                    }
                    $scope.toHasRegistrationRuleError = result.toHasRegistrationRuleError;

                    if ($scope.availableFromTimes.length === 0 || $scope.availableToTimes.length === 0) {
                        $scope.firstForm.valid = false;
                    }

                    $scope.isLoading = false;
                });
            }
        };

        $timeout(initialize());
    }
]);
