import { app, appModule, ft, ng } from '@/definitions';
import moment from 'moment';
import { IPromise, IQService, IScope, IIntervalService, IController } from 'angular';
import { ILoadingBar } from '@/models';
import { UrlHelper } from '@/urlHelper';
import { IFortyTwoDropzoneRootScopeService } from '@/directives/dropzone';
import { IModalService } from 'angular-ui-bootstrap';
import { AppDataService } from '@/services/app-data-service';
import { ChildCare } from '@/data-models';

type AlbumsListResponse = ChildCare.Definitions.Media.AlbumsListResponse;
type AlbumsListResponseItem = ChildCare.Definitions.Media.AlbumsListResponseItem;
type WriteAudienceGroup = ChildCare.Definitions.GroupApp.WriteAudienceGroup;

interface Scope extends IScope {
    albums: AlbumsListResponseItem[];
    audiences: WriteAudienceGroup[];
    mediaAlbumLimit: number;
    isLoading: boolean;
    fetchingAlbums: boolean;

    openUploadMediaModal: () => void;
    openAlbumModal: (album: AlbumsListResponseItem) => void;
    showMoreAlbums: () => void;
    createUrl: (album: AlbumsListResponseItem) => string;
}

export class AlbumsController implements IController {
    public static readonly $inject = ['$rootScope', '$scope', '$q', 'AppDataService', '$uibModal', '$localStorage', '$interval', 'LoadingBar'];

    private readonly $rootScope: IFortyTwoDropzoneRootScopeService;
    protected readonly $scope: Scope;
    private readonly $q: IQService;
    protected readonly dataService: AppDataService;
    protected readonly $uibModal: IModalService;
    private readonly $interval: IIntervalService;
    private readonly LoadingBar: ILoadingBar;
    private readonly $localStorage: any;

    private lastFetchDateMedia: moment.Moment;

    constructor($rootScope: IFortyTwoDropzoneRootScopeService, $scope: Scope, $q: IQService, dataService: AppDataService, $uibModal: IModalService, $localStorage, $interval: IIntervalService, LoadingBar: ILoadingBar) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$q = $q;
        this.dataService = dataService;
        this.$uibModal = $uibModal;
        this.$interval = $interval;
        this.LoadingBar = LoadingBar;
        this.$localStorage = $localStorage;

        this.$scope.fetchingAlbums = false;
        this.$scope.isLoading = false;
        this.$scope.mediaAlbumLimit = app.settings.mediaAlbums.fetchRange;
        this.$scope.audiences = null;

        this.$scope.albums = [];

        this.$scope.$watch('isLoading', (newVal: boolean) => this.LoadingBar.show = newVal);

        this.$scope.openUploadMediaModal = this.openUploadMediaModal.bind(this);
        this.$scope.openAlbumModal = this.openAlbumModal.bind(this);
        this.$scope.showMoreAlbums = this.showMoreAlbums.bind(this);
        this.$scope.createUrl = this.createUrl.bind(this);

        this.handleGetAlbums = this.handleGetAlbums.bind(this);

        this.initialize();
    }

    private initialize() {
        const intervalHandle = this.$interval(() => {
            this.refreshMediaAlbums();
        }, 1000 * 30);

        this.$scope.$on('$destroy', () => {
            this.$interval.cancel(intervalHandle);
        });

        this.$scope.isLoading = true;

        const tasks = [
            this.getAlbumsAsync(false),
            this.dataService.getAudiencesAsync(null)
        ];

        this.$q.all(tasks).then((results) => {
            this.handleGetAlbums(results[0] as ChildCare.Definitions.Media.AlbumsListResponse);
            this.$scope.audiences = (results[1] as ChildCare.Definitions.GroupApp.WriteAudienceResponse).groups;
        }).finally(() => this.$scope.isLoading = false);
    }

    private createUrl(album: AlbumsListResponseItem) {

        const encodedToken = encodeURIComponent(this.$localStorage.refreshToken);

        let absoluteUrl = album.posterUrl;

        if (!UrlHelper.isUrlAbsolute(absoluteUrl)) {
            absoluteUrl = UrlHelper.createAbsoluteMediaUrl(this.$rootScope.configuration.services.media, album.posterUrl);
        }

        return `${absoluteUrl}?access_token=${encodedToken}`;
    }

    private showMoreAlbums() {
        // Don't do the call if it's already fetching, or there could not possibly be a new message because it didn't
        // fetch the settings amount of items from the last call
        if (this.$scope.fetchingAlbums || this.$scope.mediaAlbumLimit >= (this.$scope.albums.length + app.settings.mediaAlbums.fetchRange)) {
            return;
        }

        this.$scope.isLoading = true;
        this.$scope.fetchingAlbums = true;
        this.getAlbumsAsync(true)
            .then(this.handleGetAlbums)
            .finally(() => {
                this.$scope.mediaAlbumLimit += app.settings.mediaAlbums.fetchRange;
                this.$scope.isLoading = false;
                this.$scope.fetchingAlbums = false;
            });
    }

    private openAlbumModal(album: AlbumsListResponseItem) {
        this.$uibModal.open({
            animation: true,
            templateUrl: 'partials/media/albumOverview.html?v=' + ft.randomNumber,
            controller: 'kpAlbumOverviewModalController',
            backdrop: true,
            keyboard: true,
            resolve: {
                album: () => ng.copy(album)
            }
        });
    }

    protected openUploadMediaModal() {
        const instance = this.$uibModal.open({
            animation: true,
            templateUrl: 'partials/media/modal.html?v=' + ft.randomNumber,
            controller: 'kpMediaModalController',
            size: 'lg',
            backdrop: true,
            keyboard: true,
            resolve: {
                audiences: () => ng.copy(this.$scope.audiences)
            }
        });

        instance.result.then(() => {
            this.refreshMediaAlbums();
        });
    }

    protected refreshMediaAlbums(): void {
        this.$scope.isLoading = true;

        this.getAlbumsAsync(false)
            .then(response => this.handleGetAlbums(response))
            .finally(() => this.$scope.isLoading = false);
    }

    private getAlbumsAsync(loadMore: boolean): IPromise<AlbumsListResponse> {
        const dateTime = moment();
        if (!this.lastFetchDateMedia) {
            this.lastFetchDateMedia = dateTime;
            return this.dataService.getAlbumsUntilAsync(this.lastFetchDateMedia);
        }

        if (loadMore) {
            const lastAlbum = this.$scope.albums[this.$scope.albums.length - 1];
            const date = moment(lastAlbum.modifiedOn);
            return this.dataService.getAlbumsUntilAsync(date);
        }

        const returnValue = this.dataService.getAlbumsSinceAsync(this.lastFetchDateMedia);
        this.lastFetchDateMedia = dateTime;
        return returnValue;
    }

    private handleGetAlbums(results: AlbumsListResponse) {
        this.$scope.albums = this.$scope.albums.concat(results.items).filter(
        (item, i, arr) => arr.findIndex(t => t.id === item.id) === i
        );
    }
}

appModule.controller('kpAlbumsController', AlbumsController);
