import moment from 'moment';

export interface Story {
    id: string;
    name: string;
    date: moment.Moment;
    isDaily: boolean;
    publishOn: moment.Moment;
    blocks: StoryBlock[];
    storyTemplateId: string;
}

export enum StoryBlockType {
    CardLeft = 'cardLeft',
    CardRight = 'cardRight',
    Images = 'images',
    Text = 'text',
    Presences  = 'presences'
}

export interface StoryTemplate {
    id: string;
    name: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
    location: string;
}

export interface StoryBlock {
    id: string;
    name: string;
    optional: boolean;
    blockType: StoryBlockType;
    content: StoryBlockContent;
}

export interface TextBlock extends StoryBlock {
    title: string;
    text: string;
}

export interface CardLeftBlock extends StoryBlock {
    title: string;
    text: string;
    imageUrl: string;
}

export interface CardRightBlock extends StoryBlock {
    title: string;
    text: string;
    imageUrl: string;
}

export interface ImagesBlock extends StoryBlock {
    imageUrls: string[];
}

export const isTextBlock = (block: StoryBlock): block is TextBlock => block.blockType === StoryBlockType.Text;
export const isCardLeftBlock = (block: StoryBlock): block is CardLeftBlock => block.blockType === StoryBlockType.CardLeft;
export const isCardRightBlock = (block: StoryBlock): block is CardRightBlock => block.blockType === StoryBlockType.CardRight;
export const isImagesBlock = (block: StoryBlock): block is ImagesBlock => block.blockType === StoryBlockType.Images;

export interface StoryBlockContent {
    id: string;
    name: string;
    blockType: StoryBlockType;
    optionalVisible: boolean;
}

export interface TextContentBlock extends StoryBlockContent {
    title: string;
    text: string;
}

export interface CardContentBlock extends StoryBlockContent {
    title: string;
    text: string;
    imageUrl: string;
    image?: Image;
}

export interface ImagesContentBlock extends StoryBlockContent {
    imageUrls: string[];
    images?: Image[];
}

export interface Image {
    url?: string;
    content?: string;
}

export const isTextContentBlock = (block: StoryBlockContent): block is TextContentBlock => block.blockType === StoryBlockType.Text;
export const isCardContentBlock = (block: StoryBlockContent): block is CardContentBlock => block.blockType === StoryBlockType.CardLeft || block.blockType === StoryBlockType.CardRight;
export const isImagesContentBlock = (block: StoryBlockContent): block is ImagesContentBlock => block.blockType === StoryBlockType.Images;


export const OnStoryDeserialized = (story: Story) => {
    story.date = moment(story.date);
    story.publishOn = moment(story.publishOn);
};

export const OnStoryTemplateDeserialized = (storyTemplate: StoryTemplate) => {
    storyTemplate.startDate = moment(storyTemplate.startDate);
    storyTemplate.endDate = moment(storyTemplate.endDate);
};