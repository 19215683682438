import { appModule } from '@/definitions';
import moment from 'moment';
import { IQService, IScope, IPromise } from 'angular';
import { AppDataService } from '@/services/app-data-service';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { Child } from '@/child-factory';
import { ChildCare } from '@/data-models';

import DiaryEntryType = ChildCare.Definitions.Family.DiaryEntryType;

interface IDiary {
    type: DiaryEntryType;
    text?: string;
    pickedTime: moment.Moment;
}

interface ILocalScope extends IScope {
    displayDatePicker: (index: any) => void;
    isDiary: boolean;
    diaryType: DiaryEntryType;
    otherTypes: DiaryEntryType[];
    types: DiaryEntryType[];
    diaries: IDiary[];
    isLoading: boolean;
    saveDiaries: () => void;
    cancel: () => void;
}

appModule.controller('kpDiaryModalController',
                ['$scope', '$q', '$uibModalInstance', 'isDiary', 'diaryType', 'otherTypes', 'pickedDate', 'selectedChildren', 'AppDataService', 'mdcDateTimeDialog',
        function ($scope: ILocalScope, $q: IQService, $uibModalInstance: IModalInstanceService, isDiary: boolean, diaryType: DiaryEntryType, otherTypes: DiaryEntryType[], pickedDate: moment.Moment, selectedChildren: Child[], DataService: AppDataService, mdcDateTimeDialog) {
            $scope.isDiary = isDiary;
            $scope.diaryType = diaryType;
            $scope.otherTypes = otherTypes;

            $scope.types = isDiary ? [diaryType] : otherTypes;

            var childrenIds: string[] = [];
            $scope.diaries = [];

            $scope.isLoading = false;

            const now = pickedDate.milliseconds() !== 0 ? pickedDate : moment();

            const initialize = () => {

                for (const type of $scope.types) {
                    $scope.diaries.push({
                        type: type,
                        pickedTime: now
                    });
                }

                for (const child of selectedChildren) {
                    childrenIds.push(child.id);
                }
            };

            $scope.saveDiaries = function () {
                var requests: IPromise<any>[] = [];
                for (const diary of $scope.diaries) {
                    if (diary.text == null) {
                        continue;
                    }

                    const typeId = diary.type && diary.type.diaryEntryTypeId;

                    requests.push(
                        DataService.saveDiaryEntry(childrenIds, typeId, diary.text, diary.pickedTime, null)
                    );
                }

                $scope.isLoading = true;
                $q.all(requests).finally(() => {
                    $scope.isLoading = false;
                    $uibModalInstance.close('Refetch');
                });
            };

            $scope.displayDatePicker = (index) => {
                mdcDateTimeDialog.show({
                    currentDate: $scope.diaries[index].pickedTime,
                    date: false,
                    minuteSteps: 1,
                    autoOk: true,
                    disableParentScroll: true
                }).then((date) => {
                    $scope.diaries[index].pickedTime = moment(date);
                });
            };


            $scope.cancel = function () {
                $uibModalInstance.dismiss();
            };

            initialize();
        }
    ]
);
