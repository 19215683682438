import swal from 'bootstrap-sweetalert';
import { appModule } from '@/definitions';
import { IScope, IController } from 'angular';
import Dropzone, { DropzoneFile } from 'dropzone';
import { IFortyTwoDropzoneRootScopeService } from '@/directives/dropzone';
import { AlbumType } from '@/models';
import { AppDataService } from '@/services/app-data-service';
import { ChildCare } from '@/data-models';
import { IModalInstanceService } from 'angular-ui-bootstrap';

type CreateOrUpdateAlbumCommand = ChildCare.Definitions.Media.CreateOrUpdateAlbumCommand;
type WriteAudienceGroup = ChildCare.Definitions.GroupApp.WriteAudienceGroup;
type WriteAudience = ChildCare.Definitions.GroupApp.WriteAudience;

declare module '@/data-models' {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace ChildCare.Definitions.GroupApp {
        interface WriteAudience {
            type: ChildCare.Definitions.GroupApp.TypeOfAudience;
            checked: boolean;
        }
    }
}


interface Scope extends IScope {
    numberOfSelectedAudiences: () => number;
    hasAudiencesSelected: () => boolean;
    selectedAudienceGroup: WriteAudienceGroup;
    audienceGroups: WriteAudienceGroup[];
    selectedAudiences: WriteAudience[];
    uploadMedia: () => void;
    selectAudience: (index: number) => void;
    toggleSelectionAudiences: (type: string) => void;
    form: {
        albumTypeOrId: AlbumType | string;
        newAlbumName: string;
        newAlbumDescription: string;
    };
    isLoading: boolean;
    allAudiencesSelected: boolean;
    mediaAlbums: Array<any>;
    cancel: () => void;
    valid: boolean;
    showAllAlbums: boolean;
    canUpload: () => boolean;
    dropzoneConfig: {
        options: Dropzone.DropzoneOptions;
        eventHandlers: any;
    };
    toggleSelectAudience(audience: WriteAudience): void;
}

class MediaUploadController implements IController {
    public static readonly $inject = ['$rootScope', '$scope', '$uibModalInstance', 'audiences', 'AppDataService', '$translate', '$localStorage'];

    private readonly $rootScope: IFortyTwoDropzoneRootScopeService;
    private readonly $scope: Scope;
    private readonly $uibModalInstance: any;
    private audienceGroups: WriteAudienceGroup[];
    private readonly dataService: AppDataService;
    private readonly $translate: any;
    private readonly $localStorage: any;

    private uploadSucceeded: boolean;

    constructor($rootScope: IFortyTwoDropzoneRootScopeService, $scope: Scope, $uibModalInstance: IModalInstanceService, audiences: WriteAudienceGroup[], dataService: AppDataService, $translate, $localStorage) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.audienceGroups = audiences;
        this.dataService = dataService;
        this.$translate = $translate;
        this.$localStorage = $localStorage;

        this.queuecomplete = this.queuecomplete.bind(this);
        this.error = this.error.bind(this);

        this.$scope.cancel = this.cancel.bind(this);
        this.$scope.toggleSelectionAudiences = this.toggleSelectionAudiences.bind(this);
        this.$scope.toggleSelectAudience = this.toggleSelectAudience.bind(this);
        this.$scope.hasAudiencesSelected = this.hasAudiencesSelected.bind(this);
        this.$scope.selectAudience = this.selectAudience.bind(this);
        this.$scope.canUpload = this.canUpload.bind(this);

        this.initialize();
    }

    private initialize(): void {
        this.$scope.mediaAlbums = [];

        this.audienceGroups = this.audienceGroups.filter(a => a.audienceType !== 'group');

        const selectedAudiences = [];
        this.audienceGroups.forEach((audienceGroup, groupIndex) => {
            audienceGroup.audiences.forEach((audienceItem, index) => {
                audienceItem.type = audienceGroup.audienceType;
                audienceItem.checked = false;
                this.audienceGroups[groupIndex].audiences[index] = audienceItem;

                selectedAudiences.push(audienceItem);
            });
        });

        this.$scope.audienceGroups = this.audienceGroups;
        this.$scope.selectedAudienceGroup = this.audienceGroups[0];
        this.$scope.selectedAudiences = selectedAudiences;

        this.$scope.uploadMedia = this.uploadMedia.bind(this);

        this.$scope.valid = true;
        this.$scope.form = {
            albumTypeOrId: AlbumType.none,
            newAlbumName: null,
            newAlbumDescription: null
        };

        this.$scope.isLoading = true;
        this.$scope.$watch('showAllAlbums', (showAllAlbums: boolean) => {
            this.dataService.getMediaAlbumsAsync(showAllAlbums)
                .then(response => this.$scope.mediaAlbums = response.items)
                .finally(() => this.$scope.isLoading = false);
        });

        this.$scope.dropzoneConfig = this.createDropzoneConfig();
    }

    private createDropzoneConfig(): Scope['dropzoneConfig'] {
        return {
            options: {
                url: (files: ReadonlyArray<DropzoneFile>) => {
                    const mimeType = files[0].type;
                    if (mimeType.startsWith('video')) {
                        return this.$rootScope.configuration.services.media + '/videos';
                    } else {
                        return this.$rootScope.configuration.services.media + '/images';
                    }
                },
                parallelUploads: 4,
                timeout: 1000 * 60 * 20,
                acceptedFiles: 'image/*',
                addRemoveLinks: true,
                autoProcessQueue: false,
                headers: {
                    'Authorization': `Bearer ${this.$localStorage.refreshToken}`
                }
            },
            eventHandlers: {
                queuecomplete: this.queuecomplete,
                success: () => {
                    if (this.$rootScope.dropzone.getQueuedFiles().length > 0) {
                        this.$rootScope.dropzone.processQueue();
                    }
                    this.uploadSucceeded = this.$rootScope.dropzone.getQueuedFiles().length === 0;
                },
                error: this.error,
                addedfile: () => this.$scope.$apply(),
                addedfiles: () => this.$scope.$apply(),
                removedfile: () => this.$scope.$apply()
            }
        };
    }

    private queuecomplete(): void {
        if (this.uploadSucceeded) {
            this.$uibModalInstance.dismiss();

            swal({
                title: 'Opgeslagen!',
                text: 'Alle media is succesvol opgeslagen!',
                timer: 2000,
                type: 'success'
            });
        }
    }

    private error(): void {
        this.$uibModalInstance.dismiss();

        swal({
            title: this.$translate.instant('ERRORS.SAVE-FAILED.TITLE'),
            text: this.$translate.instant('MEDIA.ERROR.UPLOAD-FAILED'),
            timer: 2500,
            type: 'error'
        });
    }

    private canUpload(): boolean {
        if (this.$scope.isLoading || this.$scope.selectedAudiences == null || this.$scope.form.albumTypeOrId === AlbumType.none) {
            return false;
        }

        const audiences = this.$scope.selectedAudiences;

        const hasSelectedAudiences = audiences.filter(a => a.checked).length > 0;
        const hasFiles = this.$rootScope.dropzone.files.length > 0;
        let hasSelectedAlbum = true;

        if (this.$scope.form.albumTypeOrId === AlbumType.new) {
            hasSelectedAlbum = this.$scope.form.newAlbumName != '' && this.$scope.form.newAlbumName != null;
        }

        return hasFiles && hasSelectedAudiences && hasSelectedAlbum;
    }

    private isValid(): boolean {
        const audiences = this.$scope.selectedAudiences.filter(a => a.checked);

        if (audiences.length === 0) {
            return false;
        }

        if (this.$scope.form.albumTypeOrId === AlbumType.none) {
            return false;
        }

        if (this.$scope.form.albumTypeOrId === AlbumType.new && !this.$scope.form.newAlbumName) {
            return false;
        }

        return true;
    }

    private async uploadMedia(): Promise<void> {
        this.$scope.valid = this.isValid();
        const audiences = this.$scope.selectedAudiences.filter(a => a.checked);

        this.$scope.isLoading = true;

        const hasAllAudience = audiences.filter(x => x.isAllAudience == true);
        const otherAudiences = audiences.filter(x => x.isAllAudience == false);

        const expectedAudienceIds: string[] = [];
        const hasAllAudienceTypes = [];
        hasAllAudience.forEach((allAudience) => {
            hasAllAudienceTypes.push(allAudience.type);
            expectedAudienceIds.push(allAudience.id);
        });

        otherAudiences.forEach((audience) => {
            if (!hasAllAudienceTypes.includes(audience.type)) {
                expectedAudienceIds.push(audience.id);
            }
        });

        const { albumTypeOrId, newAlbumName, newAlbumDescription } = this.$scope.form;

        const data = {
            audiences: expectedAudienceIds,
            albumId: albumTypeOrId
        };

        if (albumTypeOrId === AlbumType.new) {
            const result = await this.dataService.addAlbumAsync(newAlbumName, newAlbumDescription);
            data.albumId = result.id;
        }

        (this.$rootScope.dropzone as any).options.params = data;

        this.$rootScope.dropzone.processQueue();
    }

    private cancel() {
        this.$uibModalInstance.dismiss();
    }

    private toggleSelectionAudiences(type: string) {
        this.$scope.allAudiencesSelected = !this.$scope.allAudiencesSelected;
        this.$scope.selectedAudiences.forEach(audience => {
            if (audience.type === type) {
                audience.checked = this.$scope.allAudiencesSelected;
            }
        });
    }

    private toggleSelectAudience(audience: WriteAudience): void {
        audience.checked = !audience.checked;

        if (!audience.checked) {
            this.$scope.allAudiencesSelected = false;
            const allAudience = this.$scope.selectedAudiences.find(x => x.type == audience.type && x.isAllAudience);
            allAudience.checked = false;
        }

        const amountOfAudiences = this.$scope.selectedAudiences.filter(x => x.isAllAudience == false && x.type == audience.type).length;

        const checkedAmount = this.$scope.selectedAudiences.filter(x => x.checked == true && x.type == audience.type)
            .length;

        if (amountOfAudiences === checkedAmount) {
            this.$scope.allAudiencesSelected = true;
            const allAudience = this.$scope.selectedAudiences.find(x => x.type == audience.type && x.isAllAudience);
            allAudience.checked = true;
        }
    }

    private hasAudiencesSelected(): boolean {
        if (this.$scope.selectedAudiences) {
            return this.$scope.selectedAudiences.filter(x => x.checked == true).length > 0;
        }
    }

    private selectAudience(index: number): void {
        this.$scope.selectedAudienceGroup = this.audienceGroups[index];
        const allAudience = this.$scope.selectedAudiences.find(
            x => x.isAllAudience && x.type === this.audienceGroups[index].audienceType
        );

        if (allAudience) {
            this.$scope.allAudiencesSelected = allAudience.checked;
            return;
        }

        const checkedAudiencesInType = this.$scope.selectedAudiences.filter(
            x => x.type === this.audienceGroups[index].audienceType && x.checked
        ).length;

        this.$scope.allAudiencesSelected = checkedAudiencesInType === this.audienceGroups[index].audiences.length;
    }
}

appModule.controller('kpMediaModalController', MediaUploadController);
