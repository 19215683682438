export function resizeImage(dataUrl: string, max: number): Promise<string> {
    return new Promise<string>(resolve => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const img = new Image();
        img.onload = () => {
            ctx.drawImage(img, 0, 0);

            // To preserve the aspect ratio
            const ratioX = max / img.width;
            const ratioY = max / img.height;
            const ratio = Math.min(ratioX, ratioY);

            // New width and height based on aspect ratio
            const newWidth = img.width * ratio;
            const newHeight = img.height * ratio;

            canvas.width = newWidth;
            canvas.height = newHeight;
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            const dataUrl = canvas.toDataURL('image/jpeg');
            resolve(dataUrl);
        };
        img.src = dataUrl;
    });
}

export function toBase64(dataUrl: string): string {
    return dataUrl.replace(/^data:.*?;base64,/, '');
}