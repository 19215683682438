ft.defineNamespace('FortyTwo.app');
window.app = ft.app;

app.planning = {
    days: {
        normal: 'normal',
        closed: 'closed',
        holiday: 'holiday'
    },
    states: {
        approved: 'approved',
        requested: 'requested',
        requestable: 'requestable',
        rejected: 'rejected'
    },
    types: {
        planning: 'chiPlanning',
        absent: 'chiAfwezig',
        vacation: 'chiVakantieperiode',
        study: 'chiStudiedag',
        incidental: 'chiIncidenteleOpvang',
        swap: 'chiRuildag',
        registration: 'chiRegistration'
    },
    presence: {
        absent: 'absent',
        present: 'present',
        sick: 'sick',
        holiday: 'holiday',
        other: 'other',
        traded: 'traded'
    }
};
